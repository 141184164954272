import React from 'react';
import { prepareFieldForApiRHF } from 'src/components/form/molecules/formObjectDataApiCreator/prepareFieldForApiRHF';
import { MassBillBehaviorData } from 'src/data/types';
import { MassBillFormState } from 'src/hooks/src/massBill/useFormMassBill';
import { MassBillState } from 'src/store/src/massBill/massBill/types';
import { createFormData } from 'src/utils/src/shared/createFormData';

type MassBillApiFormDataProps = {
    dataState: MassBillFormState;
    massBill: MassBillState;
    actionKind: React.MutableRefObject<MassBillBehaviorData | null>;
};

export class MassBillApiFormDataCreator {
    dataState: MassBillFormState;
    massBill: MassBillState;
    data: { [key: string]: any } = {};
    actionKind: React.MutableRefObject<MassBillBehaviorData | null>;
    constructor(props: MassBillApiFormDataProps) {
        this.dataState = props.dataState;
        this.massBill = props.massBill;
        this.actionKind = props.actionKind;
        this.data = {};
    }

    execute() {
        this.prepareDataFromField();
        this.prepareDataFromParams();
        this.prepareDataFromActionKind();
        console.log(this.data, 'DATA PREPARED');
        return {
            formData: createFormData({ type: 'object', data: this.data })
        };
    }

    private prepareDataFromActionKind() {
        if (this.actionKind?.current?.kind === 'one') {
            Object.assign(this.data, {
                pojedyncza: this.actionKind?.current?.positionId
            });
        }
    }

    private prepareDataFromParams() {
        for (const key in this.massBill.params) {
            if (key !== 'ekrany') {
                Object.assign(this.data, {
                    [key]: this.massBill.params[key]
                });
            }
        }
    }

    private prepareDataFromField() {
        for (const key in this.dataState) {
            const element = prepareFieldForApiRHF(this.dataState[key], key);

            if (typeof element === 'object') {
                switch (true) {
                    case key.includes('faktura_laczna_grupa'):
                    case key.includes('wystaw_'):
                        if (element && element?.[key] === 1) {
                            this.data[key] = 'on';
                        }
                        break;
                    case key.includes('grupowanie_tylko_po_kontraktach'):
                    case key.includes('id_klienta'):
                        if (element && element?.[key] !== null) {
                            Object.assign(this.data, element);
                        }
                        break;
                    case key.includes('_koszty_check'):
                        if (element && element?.[key] !== undefined) {
                            Object.assign(this.data, element);
                        }
                        break;
                    default:
                        Object.assign(this.data, element);
                        break;
                }
            }
        }
    }
}
