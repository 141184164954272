import React from 'react';
import styles from './Logo.module.css';
import { useNavigate } from 'react-router-dom';
import { useTypedSelector } from 'src/store';
import { routesPath } from 'src/data/routesPath';

export const LogoProduction = () => {
    const system = useTypedSelector((state) => state.system);
    const navigate = useNavigate();
    let url: string | undefined | null = system?.systemUrl;
    if (system?.systemUrl?.endsWith('/')) {
        url = url?.slice(0, -1);
    }

    const handleOnClick = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        window.open(window.location.origin + routesPath.start.path, '_blank');
    };

    return (
        <div className={styles.wrapper} onClick={() => navigate('/')} onContextMenu={handleOnClick}>
            <img
                src={url + system.systemLogoMini}
                alt="Logo"
                style={{ width: '100%', height: 'auto', objectFit: 'contain' }}
            />
        </div>
    );
};
