import {
    MassBillCalcFnProps,
    MassBillOperationalState
} from 'src/hooks/src/massBill/useFormMassBill';
import { MassBillState } from 'src/store/src/massBill/massBill/types';
import { MassBillSetterOperationalState } from 'src/utils/src/massBill/MassBillSetterOperationalState';
import { getValueBooleanRHF } from 'src/utils/src/shared/getValueBooleanRHF';
import { getValueDateRHF } from 'src/utils/src/shared/getValueDateRHF';

export type MassBillHandlerFieldProps = {
    massBill: MassBillState;
    operationalState: MassBillOperationalState;
};

export class MassBillHandlerField extends MassBillSetterOperationalState {
    constructor({
        massBill,
        operationalState = {
            fieldTemp: {}
        }
    }: MassBillHandlerFieldProps) {
        super({ massBill, operationalState });
    }

    setSigning(data: MassBillCalcFnProps) {
        const field = this.getFieldStateFromOSByKeyField(data.keyField);
        const value = getValueBooleanRHF(field);
        if (field?.keyId) {
            for (const key in this.operationalState.fieldTemp) {
                if (this.operationalState.fieldTemp[key]?.keyId?.includes(field.keyId)) {
                    this.updateOSByKeyField({
                        newState: {
                            checkbox: value
                        },
                        keyField: key,
                        updateReset: true
                    });
                }
            }
        }
    }
    setDate(data: MassBillCalcFnProps) {
        const field = this.getFieldStateFromOSByKeyField(data.keyField);
        const value = getValueDateRHF(field);
        if (field?.keyId) {
            for (const key in this.operationalState.fieldTemp) {
                if (this.operationalState.fieldTemp[key]?.keyId?.includes(field.keyId)) {
                    this.updateOSByKeyField({
                        newState: {
                            data: value
                        },
                        keyField: key,
                        updateReset: true
                    });
                }
            }
        }
    }
}
