import React from 'react';

import { dispatch, useTypedSelector } from 'src/store';
import { fetchAndLoadInitialMassBillData } from 'src/store/src/massBill/sharedAcions';

export const useGetMassBillInitData = () => {
    const isLoading = useTypedSelector((state) => state.massBill.isLoading);
    React.useEffect(() => {
        if (isLoading) {
            const loadData = async () => {
                await dispatch(fetchAndLoadInitialMassBillData());
            };

            loadData();
        }
    }, [isLoading]);
    return isLoading;
};
