import { UseFormGetValues, UseFormReset } from 'react-hook-form';
import { fetchMassBillData } from 'src/api/src/massBill/fetchMassBillData';
import { FieldsStateRHF } from 'src/data/fieldsReactHookForm';
import { MassBillCalcFnProps } from 'src/hooks/src/massBill/useFormMassBill';
import { AppThunk } from 'src/store';
import { setInitBudgetData } from 'src/store/src/massBill/massBill/massBillSlice';
import {
    ApiError,
    createAdditionalParamsMassBill,
    createParamsForFetchMassBillData
} from 'src/utils';
import { MassBillHandlerStateGlobal } from 'src/utils/src/massBill/MassBillHandlerStateGlobal';

export const fetchAndLoadInitialMassBillData = (): AppThunk => async (dispatch, getState) => {
    const apiResponse = await fetchMassBillData(
        createParamsForFetchMassBillData({
            massBill: getState().massBill
        })
    );
    if (!apiResponse.success) {
        ApiError.handleWithCloseSpinner({ dispatch, err: apiResponse });
        throw new Error('fetchAndLoadInitialObjectData: not get InitialObjectScreenData ');
    }
    console.log(apiResponse.data);
    dispatch(setInitBudgetData(createAdditionalParamsMassBill(apiResponse.data)));
};

export const doMassBillCalc =
    (
        getValues: UseFormGetValues<FieldsStateRHF>,
        reset: UseFormReset<FieldsStateRHF>,
        data: MassBillCalcFnProps
    ): AppThunk =>
    async (dispatch, getState) =>
        new MassBillHandlerStateGlobal({ getValues, reset, massBill: getState().massBill }).execute(
            data
        );
