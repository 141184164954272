import { MassBillClientSearch } from 'src/api/src/massBill/types';
import { useGetMassBillParams } from 'src/hooks/src/massBill/useGetMassBillParams';
import { useAppDispatch, useTypedSelector } from 'src/store';
import { setUpdateClient } from 'src/store/src/massBill';

export type UseUpdateStateFunctionsMassBill = {
    clientSearch: MassBillClientSearch;
    updateClient: (id: string) => void;
    id_klienta?: string | null;
};

export const useHandleClient = (): UseUpdateStateFunctionsMassBill => {
    const clientSearch = useTypedSelector((state) => state.massBill.clientSearch);
    const dispatch = useAppDispatch();
    const { id_klienta } = useGetMassBillParams();
    function updateClient(id: string) {
        dispatch(setUpdateClient(id));
    }

    return {
        updateClient,
        clientSearch,
        id_klienta
    };
};
