import React from 'react';
import { positionField, positionNotGroupField } from 'src/constants/massBill/massBillFields';
import { MassBillField } from 'src/hooks/src/massBill/useFormMassBill';
import { useGetMassBillParams } from 'src/hooks/src/massBill/useGetMassBillParams';

export function useGetFieldsMassBill(
    kind: 'global' | 'client' | 'contract' | 'group' | 'position' | 'positionNotGroup'
): MassBillField[] {
    const { aktualizacja } = useGetMassBillParams();

    return React.useMemo(() => {
        switch (kind) {
            case 'position':
                if (aktualizacja) {
                    return positionField.filter((item) => !item.hidden);
                } else {
                    return positionField.filter((item) => !item.hidden && !item.isActual);
                }
            case 'positionNotGroup':
                if (aktualizacja) {
                    return positionNotGroupField.filter((item) => !item.hidden);
                } else {
                    return positionNotGroupField.filter((item) => !item.hidden && !item.isActual);
                }

            default:
                return [];
        }
    }, [aktualizacja, kind]);
}
