import { BudgetOperationalState } from 'src/hooks/src/budget/useFormBudget';
import { BudgetSetterOperationalState } from 'src/utils/src/budget/BudgetSetterOperationalState';
import { BudgetSetterStateGlobalProps } from 'src/utils/src/budget/BudgetSetterStateGlobal';
import { createKeyFieldBudget } from 'src/utils/src/budget/createKeyFieldBudget';
import { createTimestampByAddThreeZero } from 'src/utils/src/budget/createTimestampByAddThreeZero';

type BudgetHandlerTemplateMonthLimitProps = BudgetSetterStateGlobalProps & {
    operationalState: BudgetOperationalState;
};

export class BudgetHandlerTemplateMonthLimit extends BudgetSetterOperationalState {
    constructor({ budget, operationalState }: BudgetHandlerTemplateMonthLimitProps) {
        super({ budget, operationalState });
    }

    execute() {
        let temp: string[] = [];
        const data: { [key: number]: string[] } = {};
        this.budget.settlementMethods.forEach((method) => {
            if (method.rozliczanielimitgodzin) {
                method.years.forEach((year) => {
                    year?.months?.forEach((month) => {
                        if (month.hoursLimit) {
                            temp = [];
                            this.budget.settledObjectsStructures['limit_godzin']?.fields?.forEach(
                                (field) => {
                                    if (!field?.onlyText && field.code === '0') {
                                        const { keyField } = createKeyFieldBudget({
                                            name: field?.name,
                                            objectId: month?.hoursLimit?.id ?? '',
                                            monthDate: month?.data_rozp,
                                            methodId: method.id
                                        });
                                        temp.push(keyField);
                                    }
                                }
                            );
                            data[createTimestampByAddThreeZero(month.id)] = temp;
                        } else {
                            data[createTimestampByAddThreeZero(month.id)] = temp;
                        }
                    });
                });
                if (this.operationalState.templateMonthLimit[method.id]) {
                    this.operationalState.templateMonthLimit[method.id] = {
                        ...this.operationalState.templateMonthLimit[method.id],
                        ...data
                    };
                } else {
                    this.operationalState.templateMonthLimit[method.id] = {
                        ...data
                    };
                }
            }
        });
    }
}
