import { BugdetSettlementMethodLevel, RateCategory } from 'src/store/src/budget/budget/types';
import {
    BudgetHandlerStateGlobalFnExecuteProps,
    BudgetHandlerStateGlobalFnExecuteSharedProps
} from 'src/utils/src/budget/BudgetHandlerStateGlobal';
import { BudgetSetterOperationalState } from 'src/utils/src/budget/BudgetSetterOperationalState';
import { getVat } from 'src/utils/src/shared/getVat';

// Class working on only onChange

type BudgetHandlerCalcNewStakeWorkerProps = BudgetHandlerStateGlobalFnExecuteSharedProps & {
    method?: BugdetSettlementMethodLevel;
};

export class BudgetHandlerCalcNewStakeWorker extends BudgetSetterOperationalState {
    stakeId: string;
    rateCategory?: RateCategory;
    method?: BugdetSettlementMethodLevel;
    constructor(props: BudgetHandlerCalcNewStakeWorkerProps) {
        super(props);
        this.method = props.method;
        this.stakeId = '';
    }

    execute(data: BudgetHandlerStateGlobalFnExecuteProps) {
        this.setFromWorkerStakeId(data);
        this.setStakeData();
        this.setToOSStructure('zadania');
        this.setToOSFieldsStructure({
            settled: false,
            code: 'zadania'
        });
        this.updateData(data);
    }

    private setFromWorkerStakeId(data: BudgetHandlerStateGlobalFnExecuteProps) {
        const idWorker = this.getValueNumberFromOSByKeyField(data.keyField);
        this.stakeId = this.budget.employeesRateCategories[String(idWorker)];
    }

    private setStakeData() {
        if (this.method) {
            this.rateCategory = this.method.rateCategories?.[this.stakeId];
        }
    }

    private updateData(data: BudgetHandlerStateGlobalFnExecuteProps) {
        let hours = 0;
        let vat = 1;
        this.operationalState.fieldsStructure.forEach((field) => {
            if (!field?.onlyText && this.rateCategory) {
                if (field.id === '7') {
                    hours = this.getValueNumberFromStatePrimary({
                        ...data,
                        fieldId: field.id
                    });
                } else if (field.id === '9') {
                    this.updateOSBothTempStateField({
                        ...data,
                        name: field.name,
                        newState: {
                            liczba: String(this.rateCategory?.stawka)
                        },
                        updateReset: true
                    });
                } else if (field.id === '10') {
                    this.updateOSBothTempStateField({
                        ...data,
                        name: field.name,
                        newState: {
                            liczba: String(this.rateCategory?.kurs)
                        },
                        updateReset: true
                    });
                } else if (field.id === '12') {
                    const value = this.rateCategory?.stawka * hours * this.rateCategory?.kurs;
                    this.updateOSBothTempStateField({
                        ...data,
                        name: field.name,
                        newState: {
                            liczba: String(value)
                        },
                        updateReset: true
                    });
                } else if (field.id === '13') {
                    vat = getVat(
                        this.getValueNumberFromStatePrimary({
                            ...data,
                            fieldId: field.id
                        })
                    );
                } else if (field.id === '15') {
                    const value = this.rateCategory?.stawka * hours * this.rateCategory?.kurs * vat;
                    this.updateOSBothTempStateField({
                        ...data,
                        name: field.name,
                        newState: {
                            liczba: String(value)
                        },
                        updateReset: true
                    });
                }
            }
        });
        console.log(this.operationalState);
    }
}
