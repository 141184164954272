import { MassBillField } from 'src/hooks/src/massBill/useFormMassBill';

export const globalField: MassBillField[] = [
    {
        id: 12,
        type: 'checkbox',
        name: 'wystaw_wszystko',
        hidden: false,
        callPlacement: 'tick'
    },
    {
        id: 13,
        type: 'data',
        name: 'data_wystawienia',
        hidden: false,
        callPlacement: 'dateGlobal'
    },
    {
        id: 14,
        type: 'data',
        name: 'data_sprzedazy',
        hidden: false,
        callPlacement: 'dateGlobal'
    }
];

export const clientField: MassBillField[] = [
    {
        id: 12,
        type: 'checkbox',
        name: 'wystaw_wszystko_z_klienta_{$it.id}',
        hidden: false,
        callPlacement: 'tick'
    }
];

export const contractField: MassBillField[] = [
    {
        id: 10.5,
        type: 'checkbox',
        name: 'faktura_laczna_grupa_{$it.id}',
        hidden: false,
        isOnlyGroupContract: true
    },
    {
        id: 12,
        type: 'checkbox',
        name: 'wystaw_wszystko_z_kontraktu_{$it.id}',
        hidden: false,
        callPlacement: 'tick'
    }
];

export const groupField: MassBillField[] = [
    {
        id: 10.5,
        type: 'checkbox',
        name: 'faktura_laczna_grupa_{$it.id}',
        hidden: false,
        isOnlyGroupContract: true
    },
    {
        id: 12,
        type: 'checkbox',
        name: 'wystaw_wszystko_z_grupy_{$it.id}',
        hidden: false,
        callPlacement: 'tick'
    }
];

export const positionField: MassBillField[] = [
    {
        id: 1,
        type: 'tekst',
        name: 'element_{$it.id}_id_grupy',
        code: 'id_grupy',
        hidden: true
    },
    {
        id: 2,
        type: 'tekst',
        name: 'element_{$it.id}_typ_klienta',
        code: 'klient_tab',
        hidden: true
    },
    {
        id: 3,
        type: 'tekst',
        name: 'element_{$it.id}_id_klienta',
        code: 'klient_id',
        hidden: true
    },
    {
        id: 4,
        type: 'tekst',
        name: 'element_{$it.id}_id_kontraktu',
        code: 'id_kontraktu',
        hidden: true
    },
    {
        id: 5,
        type: 'tekst',
        name: 'element_{$it.id}_elementy_fakturowane',
        code: 'elementy_fakturowane',
        hidden: true
    },
    {
        id: 6,
        type: 'tekst',
        name: 'element_{$it.id}_kwoty_walut_fakturowane',
        code: 'kwoty_walut_fakturowane',
        hidden: true
    },
    {
        id: 7,
        type: 'tekst',
        name: 'element_{$it.id}_cena_pierwotna',
        code: 'pole_ceny',
        hidden: true
    },
    {
        id: 8,
        type: 'tekst',
        name: 'element_{$it.id}_koszty_check',
        code: 'koszty_check',
        hidden: true
    },
    {
        id: 9,
        type: 'tekst_bez_ograniczen',
        name: 'element_{$it.id}_{$it.pole_pozycji}',
        code: 'pole_pozycji',
        hidden: false
    },
    {
        id: 10,
        type: 'cena',
        name: 'element_{$it.id}_{$it.pole_ceny}',
        code: 'pole_ceny',
        hidden: false
    },
    {
        id: 11,
        type: 'checkbox',
        name: 'element_{$it.id}_aktualizuj',
        hidden: false,
        isActual: true
    },
    {
        id: 12,
        type: 'checkbox',
        name: 'wystaw_{$it.id}',
        hidden: false,
        isSign: true,
        callPlacement: 'tick'
    },
    {
        id: 13,
        type: 'data',
        name: 'element_{$it.id}_{$it.pole_daty_wystawienia}',
        code: 'pole_daty_wystawienia',
        hidden: false
    },
    {
        id: 14,
        type: 'data',
        name: 'element_{$it.id}_{$it.pole_daty_sprzedazy}',
        code: 'pole_daty_sprzedazy',
        hidden: false
    }
];

export const positionNotGroupField: MassBillField[] = [
    {
        id: 9,
        type: 'tekst_bez_ograniczen',
        name: 'element_{$it.id}_{$it.pole_pozycji}',
        code: 'pole_pozycji',
        hidden: false
    },
    {
        id: 10,
        type: 'cena',
        name: 'element_{$it.id}_{$it.pole_ceny}',
        code: 'pole_ceny',
        hidden: false
    },
    {
        id: 11,
        type: 'checkbox',
        name: 'element_{$it.id}_aktualizuj',
        hidden: false,
        isActual: true
    },
    {
        id: 13,
        type: 'data',
        name: 'element_{$it.id}_{$it.pole_daty_wystawienia}',
        code: 'pole_daty_wystawienia',
        hidden: false
    },
    {
        id: 14,
        type: 'data',
        name: 'element_{$it.id}_{$it.pole_daty_sprzedazy}',
        code: 'pole_daty_sprzedazy',
        hidden: false
    }
];
