import { BudgetOperationalState } from 'src/hooks/src/budget/useFormBudget';
import {
    BudgetScreenState,
    BugdetSettlementMethodLevel,
    SettleObject,
    SettledObjectsCodes
} from 'src/store/src/budget/budget/types';
import { BudgetHandlerCalcGlobalSummaryDataPrepareItem } from 'src/utils/src/budget/BudgetHandlerCalcGlobalSummaryDataPrepareItem';
import {
    BudgetHandlerStateGlobalFnExecuteProps,
    BudgetHandlerStateGlobalFnExecuteSharedProps
} from 'src/utils/src/budget/BudgetHandlerStateGlobal';

// Class working on only onChange

type BudgetHandlerCalcGlobalSummaryDataPrepareAllProps =
    BudgetHandlerStateGlobalFnExecuteSharedProps & {
        method?: BugdetSettlementMethodLevel;
    };

type BudgetCalcGlobalSummaryExecFnProps = BudgetHandlerStateGlobalFnExecuteProps;

export class BudgetHandlerCalcGlobalSummaryDataPrepareAll {
    budget: BudgetScreenState;
    method?: BugdetSettlementMethodLevel;
    operationalState: BudgetOperationalState;
    constructor(data: BudgetHandlerCalcGlobalSummaryDataPrepareAllProps) {
        this.budget = data.budget;
        this.method = data.method;
        this.operationalState = data.operationalState;
    }

    executeOne(data: BudgetCalcGlobalSummaryExecFnProps) {
        this.getGlobalSummaryDataMethod(data.methodId);
        new BudgetHandlerCalcGlobalSummaryDataPrepareItem({
            budget: this.budget,
            method: this.method,
            operationalState: this.operationalState
        }).execute(data);
        this.setGlobalSummaryDataMethod(data.methodId);
        console.log(structuredClone(this.operationalState));
    }

    executeMany(data: BudgetCalcGlobalSummaryExecFnProps) {
        if (this.operationalState.monthWhereWasChanged) {
            this.getGlobalSummaryDataMethod(data.methodId);
            this.operationalState.monthWhereWasChanged.cases.forEach((caseItem) => {
                caseItem.settledElements.forEach((settledElement) => {
                    settledElement.objects.forEach((object) => {
                        new BudgetHandlerCalcGlobalSummaryDataPrepareItem({
                            budget: this.budget,
                            method: this.method,
                            operationalState: this.operationalState
                        }).execute({
                            callPlacement: data.callPlacement,
                            methodId: data.methodId,
                            monthDate: data.monthDate,
                            monthId: this.operationalState.monthWhereWasChanged?.id,
                            settleObjectCode: settledElement.code,
                            objectId: object.id
                        });
                    });
                });
            });
            const settledSingleElementsArr: [SettledObjectsCodes, SettleObject][] = Object.entries(
                this.operationalState.monthWhereWasChanged?.settledSingleElements
            ) as [SettledObjectsCodes, SettleObject][];
            settledSingleElementsArr.forEach(([code, object]) => {
                new BudgetHandlerCalcGlobalSummaryDataPrepareItem({
                    budget: this.budget,
                    operationalState: this.operationalState
                }).execute({
                    callPlacement: data.callPlacement,
                    methodId: data.methodId,
                    monthDate: data.monthDate,
                    monthId: this.operationalState.monthWhereWasChanged?.id,
                    settleObjectCode: code,
                    objectId: object.id
                });
            });
            if (this.operationalState.monthWhereWasChanged.hoursLimit) {
                new BudgetHandlerCalcGlobalSummaryDataPrepareItem({
                    budget: this.budget,
                    operationalState: this.operationalState
                }).execute({
                    callPlacement: data.callPlacement,
                    methodId: data.methodId,
                    monthDate: data.monthDate,
                    monthId: this.operationalState.monthWhereWasChanged?.id,
                    settleObjectCode: 'limit_godzin',
                    objectId: this.operationalState.monthWhereWasChanged.hoursLimit?.id
                });
            }
            this.setGlobalSummaryDataMethod(data.methodId);
        }
        console.log(structuredClone(this.operationalState));
    }

    executeAll() {
        if (this.method) {
            this.method?.years.forEach((year) => {
                this.getGlobalSummaryDataMethod(this.method?.id ?? '');
                year?.months?.forEach((month) => {
                    [...month.cases, ...month.projects].forEach((caseItem) => {
                        caseItem.settledElements.forEach((settledElement) => {
                            settledElement.objects.forEach((object) => {
                                new BudgetHandlerCalcGlobalSummaryDataPrepareItem({
                                    budget: this.budget,
                                    method: this.method,
                                    operationalState: this.operationalState
                                }).execute({
                                    methodId: this.method?.id ?? '',
                                    monthId: month.id,
                                    monthDate: month.data_rozp,
                                    settleObjectCode: settledElement.code,
                                    objectId: object.id
                                });
                            });
                        });
                    });
                    month.settledElements.forEach((settledElement) => {
                        settledElement.objects.forEach((object) => {
                            new BudgetHandlerCalcGlobalSummaryDataPrepareItem({
                                budget: this.budget,
                                method: this.method,
                                operationalState: this.operationalState
                            }).execute({
                                methodId: this.method?.id ?? '',
                                monthId: month.id,
                                monthDate: month.data_rozp,
                                settleObjectCode: settledElement.code,
                                objectId: object.id
                            });
                        });
                    });
                    const settledSingleElementsArr: [SettledObjectsCodes, SettleObject][] =
                        Object.entries(month.settledSingleElements) as [
                            SettledObjectsCodes,
                            SettleObject
                        ][];
                    settledSingleElementsArr.forEach(([code, object]) => {
                        new BudgetHandlerCalcGlobalSummaryDataPrepareItem({
                            budget: this.budget,
                            method: this.method,
                            operationalState: this.operationalState
                        }).execute({
                            methodId: this.method?.id ?? '',
                            monthId: month.id,
                            monthDate: month.data_rozp,
                            settleObjectCode: code,
                            objectId: object.id
                        });
                    });
                    if (month.hoursLimit) {
                        new BudgetHandlerCalcGlobalSummaryDataPrepareItem({
                            budget: this.budget,
                            operationalState: this.operationalState
                        }).execute({
                            methodId: this.method?.id ?? '',
                            monthId: month.id,
                            monthDate: month.data_rozp,
                            settleObjectCode: 'limit_godzin',
                            objectId: month.hoursLimit?.id
                        });
                    }
                });
                this.setGlobalSummaryDataMethod(this.method?.id ?? '');
            });
            this.method.settledElements.forEach((settledElement) => {
                settledElement.objects.forEach((object) => {
                    new BudgetHandlerCalcGlobalSummaryDataPrepareItem({
                        budget: this.budget,
                        method: this.method,
                        operationalState: this.operationalState
                    }).execute({
                        methodId: this.method?.id ?? '',
                        settleObjectCode: settledElement.code,
                        objectId: object.id
                    });
                });
            });
            const settledSingleElementsArr: [SettledObjectsCodes, SettleObject][] = Object.entries(
                this.method.settledSingleElements
            ) as [SettledObjectsCodes, SettleObject][];
            settledSingleElementsArr.forEach(([code, object]) => {
                new BudgetHandlerCalcGlobalSummaryDataPrepareItem({
                    budget: this.budget,
                    method: this.method,
                    operationalState: this.operationalState
                }).execute({
                    methodId: this.method?.id ?? '',
                    settleObjectCode: code,
                    objectId: object.id
                });
            });
        }
    }

    private getGlobalSummaryDataMethod(methodId: string) {
        this.operationalState.globalSummaryDataLimit = {
            ...this.operationalState.globalSummaryData[methodId]?.limit
        };
        this.operationalState.globalSummaryDataLimitToCalc = {
            ...this.operationalState.globalSummaryData[methodId]?.limit
        };
        this.operationalState.globalSummaryDataObject = {
            ...this.operationalState.globalSummaryData[methodId]?.object
        };
    }

    private setGlobalSummaryDataMethod(methodId: string) {
        this.operationalState.globalSummaryData[methodId] = {
            ...this.operationalState.globalSummaryData[methodId],
            limit: this.operationalState.globalSummaryDataLimit,
            object: this.operationalState.globalSummaryDataObject
        };
    }
}
