import {
    BugdetMonthLevel,
    BugdetSettlementMethodLevel,
    MonthLevelType,
    MonthSubLevelItem,
    SettledObjectsCodes,
    SettleObject
} from 'src/store/src/budget/budget/types';
import { CreateKeySumSharedProps } from './createKeySumBudget';
import { BudgetStableFieldSumType } from 'src/constants/budget/budgetStableFieldSum';
import { CalcSumFn } from './BudgetHandlerStateSum';

type BaseFunctionProps = CreateKeySumSharedProps & {
    object: SettleObject;
    code: SettledObjectsCodes;
    method: BugdetSettlementMethodLevel;
    month?: BugdetMonthLevel;
};

export class BudgetHandlerUtils {
    protected runForMonthSublevelForEveryObject(
        functionToRun: (props: BaseFunctionProps) => void,
        method: BugdetSettlementMethodLevel,
        month: BugdetMonthLevel,
        monthSubLevelItems: MonthSubLevelItem[],
        monthLevelType: MonthLevelType
    ) {
        monthSubLevelItems.forEach((caseItem) => {
            caseItem.settledElements.forEach((settledElement) => {
                settledElement.objects.forEach((object) => {
                    functionToRun({
                        code: settledElement.code,
                        object,
                        method,
                        month,
                        monthLevelTypeId: caseItem.id,
                        monthLevelType: monthLevelType,
                        methodLevelType: 'miesiac'
                    });
                });
            });
        });
    }

    protected runForMonthSublevelForCaseItem(
        baseFunctionToRun: (props: CalcSumFn) => void,
        method: BugdetSettlementMethodLevel,
        month: BugdetMonthLevel,
        monthSubLevelItems: MonthSubLevelItem[],
        monthLevelType: 'sprawy' | 'projekty',
        stableFieldsSumType: BudgetStableFieldSumType,
        IfFunctionToRun?: (props: CalcSumFn) => void
    ) {
        monthSubLevelItems.forEach((caseItem) => {
            baseFunctionToRun({
                type: stableFieldsSumType,
                month,
                method,
                monthLevelTypeId: caseItem.id,
                monthLevelType: monthLevelType,
                methodLevelType: 'miesiac'
            });
        });
        if (IfFunctionToRun && monthSubLevelItems?.length) {
            IfFunctionToRun({
                type: monthLevelType,
                month,
                method,
                monthLevelType: monthLevelType,
                methodLevelType: 'miesiac'
            });
        }
    }
}
