import { MassBillOperationalState } from 'src/hooks/src/massBill/useFormMassBill';
import { MassBillState } from 'src/store/src/massBill/massBill/types';
import { MassBillSetterOtherField } from 'src/utils/src/massBill/MassBillSetterOtherField';
import { MassBillSetterPositionField } from 'src/utils/src/massBill/MassBillSetterPositionFIeld';

export type MassBillSetterStateGlobalProps = {
    massBill: MassBillState;
    operationalState?: MassBillOperationalState;
};

export class MassBillSetterStateGlobal {
    massBill: MassBillState;
    operationalState: MassBillOperationalState;
    massBillSetterOtherField: MassBillSetterOtherField;
    massBillSetterPositionField: MassBillSetterPositionField;

    constructor({
        massBill,
        operationalState = {
            fieldTemp: {}
        }
    }: MassBillSetterStateGlobalProps) {
        this.massBill = massBill;
        this.operationalState = operationalState;
        this.massBillSetterOtherField = new MassBillSetterOtherField({
            massBill,
            operationalState
        });
        this.massBillSetterPositionField = new MassBillSetterPositionField({
            massBill,
            operationalState
        });
    }

    execute() {
        this.massBillSetterPositionField.execute();
        this.massBillSetterOtherField.execute();

        return this.operationalState.fieldTemp;
    }
}
