import { FieldCheckboxStateRHF } from 'src/data/fieldsReactHookForm';
import {
    BudgetOperationalState,
    BudgetOperationalStateFormFieldsStateKeys
} from 'src/hooks/src/budget/useFormBudget';
import {
    BudgetFieldKeyPartsAll,
    BudgetScreenState,
    BugdetFormFieldStructure,
    SettledObjectsCodes
} from 'src/store/src/budget/budget/types';
import { getValueBooleanRHF, getValueNumberRHF } from 'src/utils';
import { BudgetHandlerStateGlobalFnExecuteSharedProps } from 'src/utils/src/budget/BudgetHandlerStateGlobal';
import { createKeyFieldBudget } from 'src/utils/src/budget/createKeyFieldBudget';
import { BudgetHandlerUtils } from './BudgetHandlerUtils';

// Class working on only onChange

type BudgetGetterOperationalStateProps = BudgetHandlerStateGlobalFnExecuteSharedProps & {};

type GetFieldStateFromOSPros = BudgetFieldKeyPartsAll;

type GetValueFromOSProps = BudgetFieldKeyPartsAll & {
    operationalStateKey?: BudgetOperationalStateFormFieldsStateKeys;
};

export class BudgetGetterOperationalState extends BudgetHandlerUtils {
    budget: BudgetScreenState;
    operationalState: BudgetOperationalState;
    constructor(props: BudgetGetterOperationalStateProps) {
        super();
        this.budget = props.budget;
        this.operationalState = props.operationalState;
    }

    protected getFieldFromBugdetObjectStructure({
        settleObjectCode,
        fieldId,
        code,
        column
    }: {
        settleObjectCode: SettledObjectsCodes;
        fieldId?: string;
        code?: string;
        column?: string;
    }): BugdetFormFieldStructure | undefined {
        const objectStrucuture = this.budget.settledObjectsStructures[settleObjectCode];
        if (objectStrucuture) {
            if (fieldId) {
                const field = objectStrucuture.fields.find((field) => field.id === fieldId);
                if (field && !field.onlyText) {
                    return field;
                }
            }
            if (code) {
                const field = objectStrucuture.fields.find((field) => field.code === code);
                if (field && !field.onlyText) {
                    return field;
                }
            }
            if (column) {
                const field = objectStrucuture.fields.find((field) => field.column === column);
                if (field && !field.onlyText) {
                    return field;
                }
            }
        }
    }

    protected getFieldStateFromOSByKeyField(
        keyField: string,
        operationalStateKey?: BudgetOperationalStateFormFieldsStateKeys
    ) {
        if (operationalStateKey) {
            return this.operationalState?.[operationalStateKey]?.[keyField];
        }
        if (this.operationalState.callPlacement === 'budgetHandlerStateGlobal') {
            if (this.operationalState.fieldsTempFromRHF[keyField]) {
                return this.operationalState.fieldsTempFromRHF[keyField];
            }
            return this.operationalState.fieldsTemp[keyField];
        }
        return this.operationalState.fieldsTemp[keyField];
    }

    protected getValueNumberFromOSByKeyField(
        keyField: string,
        operationalStateKey?: BudgetOperationalStateFormFieldsStateKeys
    ): number {
        const field = this.getFieldStateFromOSByKeyField(keyField, operationalStateKey);
        return getValueNumberRHF(field);
    }

    protected getFieldStateFromOS(data: GetFieldStateFromOSPros) {
        const { keyField } = createKeyFieldBudget(data);
        return this.getFieldStateFromOSByKeyField(keyField);
    }

    protected getValueNumberFromOS(data: GetValueFromOSProps) {
        const { keyField } = createKeyFieldBudget(data);
        const field = this.getFieldStateFromOSByKeyField(keyField, data.operationalStateKey);
        return getValueNumberRHF(field);
    }

    protected getValueBooleanFromOS(data: GetValueFromOSProps) {
        const { keyField } = createKeyFieldBudget(data);
        const field = this.getFieldStateFromOSByKeyField(keyField, data.operationalStateKey);
        return getValueBooleanRHF(field);
    }

    protected getValueNumberFromStatePrimary(data: {
        settleObjectCode: SettledObjectsCodes;
        fieldId?: string;
        code?: string;
        column?: string;
        monthId?: string;
        objectId: string;
        methodId: string;
    }): number {
        let value = 0;
        const field = this.getFieldFromBugdetObjectStructure(data);
        if (field) {
            const fieldState = this.getFieldStateFromOS({
                ...data,
                name: field.name
            });
            value = getValueNumberRHF(fieldState);
        } else {
            console.error(
                'ERROR - BudgetCalcHelper 123',
                data.settleObjectCode,
                data.fieldId,
                data.code,
                data.column
            );
        }
        return value;
    }

    protected getValueBooleanFromStatePrimary(data: {
        settleObjectCode: SettledObjectsCodes;
        fieldId?: string;
        code?: string;
        column?: string;
        monthId?: string;
        objectId: string;
        methodId: string;
        getSpecialRateOptionCode?: boolean;
    }): boolean {
        let bool = false;
        const field = this.getFieldFromBugdetObjectStructure(data);
        if (field) {
            const fieldState = this.getFieldStateFromOS({
                ...data,
                name:
                    data.getSpecialRateOptionCode && field.specialRateOptionCode
                        ? field.specialRateOptionCode
                        : field.name
            });
            if (fieldState) {
                switch (true) {
                    case 'checkbox' in fieldState: {
                        const oneObj: Required<FieldCheckboxStateRHF> =
                            fieldState as Required<FieldCheckboxStateRHF>;
                        bool = oneObj?.checkbox;
                        break;
                    }
                }
            }
        }
        return bool;
    }
}
