import React, { FC } from 'react';
import { useTheme } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import { DatePicker } from 'src/components/shared/atoms/datePicker/DatePicker';
import { DynamicSearchInputProps } from 'src/components/listScreen/molecules/dynamicSearchInputs/type';
import { sxInputV1Default } from '../uiInputs/sxDefault';
import { SelectChangeEvent, Select } from '@mui/material';
import { sxInputSelect, useMenuPropsPaperClass } from 'src/styles/dynamicSelectStyles';
import { SelectIcon } from '../uiInputs/uiInputSelect/SelectIcon';
import { DYNAMIC_DATE_SEARCH_DATE_PRESETS_OPTIONS } from 'src/constants/list';

export const DynamicDateSearch: FC<DynamicSearchInputProps> = ({
    searchField,
    listSearchFilters,
    dispatchFilter
}) => {
    const value = listSearchFilters[searchField.searchCode]?.fieldFilter?.value;
    const theme = useTheme();

    const sxinputselect = {
        ...sxInputV1Default,
        ...sxInputSelect(theme.palette.primary.main),
        color: theme.palette.text.input
    };

    const classes = useMenuPropsPaperClass(theme.palette.primary.main);

    return (
        <div style={{ display: 'flex', gap: '10px' }}>
            <div
                style={{
                    maxWidth: '180px',
                    display: value?.preset && value?.preset !== 0 ? 'none' : 'block'
                }}>
                <DatePicker
                    dateInitial={value?.dates?.[0]?.data || undefined}
                    isCheckboxToday={true}
                    numberPanels="three"
                    getDate={({ dateSelectedFormatted, isToday }) => {
                        dispatchFilter({
                            type: 'SET_VALUE_FIVE',
                            payload: {
                                code: searchField.searchCode,
                                index: 0,
                                value: dateSelectedFormatted
                                    ? {
                                          data: dateSelectedFormatted,
                                          loadToday: isToday
                                      }
                                    : null
                            }
                        });
                    }}
                    portalEnabled={true}
                    sxContainerPanels={{ position: 'absolute' }}
                    clearButton={true}
                />
            </div>
            <div
                style={{
                    maxWidth: '180px',
                    display: value?.preset && value?.preset !== 0 ? 'none' : 'block'
                }}>
                <DatePicker
                    dateInitial={value?.dates?.[1]?.data || undefined}
                    isCheckboxToday={true}
                    numberPanels="three"
                    getDate={({ dateSelectedFormatted, isToday }) => {
                        dispatchFilter({
                            type: 'SET_VALUE_FIVE',
                            payload: {
                                code: searchField.searchCode,
                                index: 1,
                                value: dateSelectedFormatted
                                    ? {
                                          data: dateSelectedFormatted,
                                          loadToday: isToday
                                      }
                                    : null
                            }
                        });
                    }}
                    portalEnabled={true}
                    sxContainerPanels={{ position: 'absolute' }}
                    clearButton={true}
                />
            </div>
            <div style={{ width: 'auto', flex: '50% 1 1' }}>
                <Select
                    sx={sxinputselect}
                    MenuProps={{ classes: { paper: classes.paper } }}
                    onChange={(e: SelectChangeEvent) => {
                        dispatchFilter({
                            type: 'SET_VALUE_FIVE_PRESET',
                            payload: {
                                code: searchField.searchCode,
                                value: Number(e.target.value)
                            }
                        });
                    }}
                    value={value?.preset}
                    IconComponent={SelectIcon}>
                    {DYNAMIC_DATE_SEARCH_DATE_PRESETS_OPTIONS.map((option) => {
                        return (
                            <MenuItem key={option.id} value={option.id}>
                                {option.text}
                            </MenuItem>
                        );
                    })}
                </Select>
            </div>
        </div>
    );
};
