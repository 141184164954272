import { FieldsStateUnionRHF } from 'src/data/fieldsReactHookForm';
import { MassBillOperationalState } from 'src/hooks/src/massBill/useFormMassBill';
import { MassBillState } from 'src/store/src/massBill/massBill/types';
import { MassBillGetterOperationalState } from 'src/utils/src/massBill/MassBillGetterOperationalState';
import { updateKeyReset } from 'src/utils/src/shared/updateKeyReset';

export type MassBillSetterOperationalStateProps = {
    massBill: MassBillState;
    operationalState: MassBillOperationalState;
};

export class MassBillSetterOperationalState extends MassBillGetterOperationalState {
    constructor({ massBill, operationalState }: MassBillSetterOperationalStateProps) {
        super({ massBill, operationalState });
    }

    protected updateOSByKeyField({
        newState,
        keyField,
        updateReset
    }: {
        newState: FieldsStateUnionRHF;
        keyField: string;
        updateReset?: boolean;
    }) {
        const fieldState: FieldsStateUnionRHF = this.getFieldStateFromOSByKeyField(keyField) ?? {};

        const stateToUpdate: FieldsStateUnionRHF = {
            keyReset: 1,
            ...fieldState,
            ...newState
        };
        if (updateReset) {
            stateToUpdate.keyReset = updateKeyReset(fieldState.keyReset);
        }
        Object.assign(this.operationalState.fieldTemp, { [keyField]: stateToUpdate });
    }
}
