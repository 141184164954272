import {
    FieldCheckboxStateRHF,
    FieldDateStateRHF,
    FieldPriceStateRHF,
    FieldTekstStateRHF,
    FieldTekstWithoutRestrictStateRHF
} from 'src/data/fieldsReactHookForm';

import {
    MassBillFieldTypes,
    MassBillFormStateFields,
    MassBillGetValuesFn
} from 'src/hooks/src/massBill/useFormMassBill';

type GetValuesBudgetPropsRHF = {
    getValues: MassBillGetValuesFn;
};

export type GetValuesFnMassBillPropsRHF = {
    keyField: string;
};

type GetValuesFnSpecMassBillPropsRHF = {
    fieldType: MassBillFieldTypes;
};

export class MassBillGetValuesHandlerRHF {
    getValues: MassBillGetValuesFn;
    field: MassBillFormStateFields;
    constructor(data: GetValuesBudgetPropsRHF) {
        this.getValues = data.getValues;
        this.field = {};
    }
    run({ keyField }: GetValuesFnMassBillPropsRHF) {
        this.field = this.getValues(keyField);
        return this;
    }

    getFieldState() {
        return this.field;
    }

    getBoolean({ fieldType }: GetValuesFnSpecMassBillPropsRHF) {
        let value = false;
        switch (fieldType) {
            case 'checkbox': {
                const oneObj: Required<FieldCheckboxStateRHF> = this
                    .field as Required<FieldCheckboxStateRHF>;
                value = oneObj?.checkbox;
                break;
            }
        }
        return value;
    }

    getPrice({ fieldType }: GetValuesFnSpecMassBillPropsRHF) {
        let value = {
            netto: '',
            brutto: '',
            vat: '',
            waluta: ''
        };
        switch (fieldType) {
            case 'cena': {
                const oneObj: Required<FieldPriceStateRHF> = this
                    .field as Required<FieldPriceStateRHF>;
                value = oneObj?.cena;
                break;
            }
        }
        return value;
    }

    getString({ fieldType }: GetValuesFnSpecMassBillPropsRHF) {
        let value: string | undefined | null = '';

        switch (fieldType) {
            case 'tekst_bez_ograniczen': {
                const oneObj: Required<FieldTekstWithoutRestrictStateRHF> = this
                    .field as Required<FieldTekstWithoutRestrictStateRHF>;
                value = oneObj?.tekst_bez_ograniczen;
                break;
            }
            case 'tekst': {
                const oneObj: Required<FieldTekstStateRHF> = this
                    .field as Required<FieldTekstStateRHF>;
                value = oneObj?.tekst;
                break;
            }
            case 'data': {
                const oneObj: Required<FieldDateStateRHF> = this
                    .field as Required<FieldDateStateRHF>;
                if (oneObj?.data) {
                    value = new Date(oneObj?.data).toLocaleDateString().replaceAll('.', '-');
                }
                break;
            }
        }
        return value;
    }

    getDate({ fieldType }: GetValuesFnSpecMassBillPropsRHF) {
        let value: Date | undefined | null;

        switch (fieldType) {
            case 'data': {
                const oneObj: Required<FieldDateStateRHF> = this
                    .field as Required<FieldDateStateRHF>;
                value = oneObj?.data;
                break;
            }
        }
        return value;
    }

    getKeyReset() {
        return this.field?.keyReset;
    }
}
