import { MassBillPosition } from 'src/api/src/massBill/types';
import { positionField } from 'src/constants/massBill/massBillFields';
import {
    MassBillField,
    MassBillFormStateFields,
    MassBillOperationalState
} from 'src/hooks/src/massBill/useFormMassBill';
import { MassBillState } from 'src/store/src/massBill/massBill/types';
import { createKeyFieldMassBill } from 'src/utils/src/massBill/createKeyFieldMassBill';
import { createKeyIdMassBill } from 'src/utils/src/massBill/createKeyIdMassBill';
import { DateManager } from 'src/utils/src/shared/DateManager';
import { getVat } from 'src/utils/src/shared/getVat';

export type MassBillSetterPositionFieldProps = {
    massBill: MassBillState;
    operationalState: MassBillOperationalState;
};

export class MassBillSetterPositionField {
    massBill: MassBillState;
    operationalState: MassBillOperationalState;
    constructor({ massBill, operationalState }: MassBillSetterPositionFieldProps) {
        this.massBill = massBill;
        this.operationalState = operationalState;
    }

    execute() {
        this.massBill.position.forEach((element) => {
            positionField.forEach((field) => {
                this.createField({ position: element, field });
            });
        });
    }

    private createField({ position, field }: { position: MassBillPosition; field: MassBillField }) {
        const keyId = createKeyIdMassBill({
            fieldId: field.id,
            clientId: position.klient_id,
            contractId: position.id_kontraktu,
            groupId: position.id_grupy,
            positionId: position.id
        });
        const name = createKeyFieldMassBill({
            name: field.name,
            id: position.id,
            pole_pozycji: position.pole_pozycji.kod,
            pole_ceny: position.pole_ceny.kod,
            pole_daty_wystawienia: position.pole_daty_wystawienia.kod,
            pole_daty_sprzedazy: position.pole_daty_sprzedazy.kod
        });
        if (field.code) {
            this.operationalState.fieldTemp[name] = {
                ...this.setFieldValue({ field, position, code: field.code }),
                keyId,
                keyReset: 1
            };
        }
        if (field.isActual && this.massBill.params.aktualizacja === '1') {
            this.operationalState.fieldTemp[name] = {
                checkbox: true,
                keyId,
                keyReset: 1
            };
        }
        if (field.isSign && this.massBill.params.domyslnie_wybrane === '1') {
            this.operationalState.fieldTemp[name] = {
                checkbox: true,
                keyId,
                keyReset: 1
            };
        }
    }
    private setFieldValue({
        field,
        position,
        code
    }: {
        code: keyof MassBillPosition;
        field: MassBillField;
        position: MassBillPosition;
    }): MassBillFormStateFields {
        const value: string = this.getFieldValue(code, position);
        switch (field.type) {
            case 'tekst':
                return { tekst: value };
            case 'tekst_bez_ograniczen':
                return { tekst_bez_ograniczen: value };
            case 'data': {
                return { data: DateManager.setDateFromStrDDMMYYYY(value) };
            }
            case 'cena': {
                return {
                    cena: this.setFieldValuePrice(position, code)
                };
            }
            default: {
                return { tekst: '' };
            }
        }
    }

    private setFieldValuePrice(position: MassBillPosition, code: keyof MassBillPosition) {
        const value = {
            netto: '0',
            brutto: '0',
            vat: '23',
            waluta: 'PLN'
        };
        if (code === 'pole_ceny') {
            const vat = getVat(position[code]?.domyslna_wartosc_vat);
            const netto = Number(position[code]?.domyslna_wartosc);
            value.netto = position[code]?.domyslna_wartosc;
            value.brutto = String(netto * vat);
            value.vat = position[code]?.domyslna_wartosc_vat;
            value.waluta = position[code]?.domyslna_wartosc_waluta;
        }
        return value;
    }

    private getFieldValue(code: keyof MassBillPosition, position: MassBillPosition) {
        if (
            code === 'pole_ceny' ||
            code === 'pole_pozycji' ||
            code === 'pole_daty_sprzedazy' ||
            code === 'pole_daty_wystawienia'
        ) {
            return position[code].domyslna_wartosc;
        } else {
            return position[code];
        }
    }
}
