import {
    BudgetScreenState,
    BugdetSettlementMethodLevel,
    SettleObject,
    SettledElement,
    SettledObjectsCodes,
    SettledSingleElements
} from 'src/store/src/budget/budget/types';
import { createFormData } from '../shared/createFormData';
import { BudgetFormStateRHF } from 'src/hooks/src/budget/useFormBudget';
import {
    FieldCheckboxStateRHF,
    FieldDateStateRHF,
    FieldListStateRHF,
    FieldNumberStateRHF,
    FieldTekstStateRHF,
    FieldTekstWithoutRestrictStateRHF,
    FieldsStateUnionRHF
} from 'src/data/fieldsReactHookForm';
import { DateManager } from '../shared/DateManager';

export class BudgetApiFormDataCreator {
    budgetState: BudgetScreenState;
    dataState: BudgetFormStateRHF;

    constructor(budgetState: BudgetScreenState, dataState: BudgetFormStateRHF) {
        this.budgetState = budgetState;
        this.dataState = dataState;
    }

    createApiData() {
        const { data, cost } = this.prepareDataForFormData();
        this.appendObjectIdentifiersToData(data, this.budgetState.settlementMethods);
        this.appendInvoiceParamsToData(data);

        return { formData: createFormData({ type: 'object', data }), cost };
    }

    // related to prepareDataForFormData

    private prepareDataForFormData() {
        const data: { [key: string]: any } = {};
        let costExists = false;
        let cost: string | undefined = '';
        let objId: string | number | null = '';
        for (const methodId in this.dataState.main) {
            if (typeof this.dataState.main[methodId] === 'object') {
                for (const keyOfField in this.dataState.main[methodId]) {
                    if (!keyOfField.includes('#') && !keyOfField.includes('@')) {
                        const element = this.prepareDataForFormDataItem(
                            this.dataState.main[methodId][keyOfField],
                            keyOfField
                        );
                        if (typeof element === 'object') {
                            Object.assign(data, element);
                        }
                        if (keyOfField.includes('koszt_waluta_') && !costExists) {
                            objId = keyOfField.split('_')?.[2];
                            cost =
                                typeof element === 'object' ? element[keyOfField]?.toString() : '';
                        }
                        if (keyOfField.includes('koszt_invoice_') && !costExists) {
                            const isInvoiced =
                                typeof element === 'object' ? element[keyOfField] : 0;
                            const objId2 = keyOfField.split('_')?.[2];
                            if (objId === objId2 && isInvoiced === 1) {
                                costExists = true;
                            }
                        }
                    }
                }
            }
        }
        console.log(data, 'dataFormed');
        return { data, cost: costExists ? cost : '' };
    }

    private prepareDataForFormDataItem(field: FieldsStateUnionRHF, keyOfField: string) {
        switch (true) {
            case 'data' in field: {
                const oneObj: Required<FieldDateStateRHF> = field as Required<FieldDateStateRHF>;
                return {
                    [keyOfField]: DateManager.prepareDayMonthYearFromDate(oneObj.data)
                };
            }
            case 'tekst' in field: {
                const oneObj: Required<FieldTekstStateRHF> = field as Required<FieldTekstStateRHF>;
                return {
                    [keyOfField]: oneObj.tekst
                };
            }
            case 'checkbox' in field: {
                const oneObj: Required<FieldCheckboxStateRHF> =
                    field as Required<FieldCheckboxStateRHF>;
                return {
                    [keyOfField]: oneObj.checkbox ? 1 : 0
                };
            }
            case 'lista' in field: {
                const oneObj: Required<FieldListStateRHF> = field as Required<FieldListStateRHF>;
                return {
                    [keyOfField]: oneObj.lista.value
                };
            }
            case 'liczba' in field: {
                const oneObj: Required<FieldNumberStateRHF> =
                    field as Required<FieldNumberStateRHF>;
                return {
                    [keyOfField]: oneObj.liczba
                };
            }
            case 'tekst_bez_ograniczen' in field: {
                const oneObj: Required<FieldTekstWithoutRestrictStateRHF> =
                    field as Required<FieldTekstWithoutRestrictStateRHF>;
                return {
                    [keyOfField]: oneObj.tekst_bez_ograniczen
                };
            }
        }
    }

    // related to appendObjectIdentifiersToData

    private appendObjectIdentifiersToData(
        data: { [key: string]: any },
        budgetStateMethodsLevel: BugdetSettlementMethodLevel[]
    ) {
        for (const method of budgetStateMethodsLevel) {
            for (const year of method.years) {
                if (year.months)
                    for (const month of year.months) {
                        this.appendObjectPropertiesToDataFromSettledElement(
                            data,
                            month.settledElements,
                            method.id,
                            month.data_rozp
                        );
                        this.appendObjectPropertiesToDataFromSettledSingleElement(
                            data,
                            month.settledSingleElements,
                            method.id,
                            month.data_rozp
                        );
                        for (const budgetCase of month.cases) {
                            this.appendObjectPropertiesToDataFromSettledElement(
                                data,
                                budgetCase.settledElements,
                                method.id,
                                month.data_rozp
                            );
                        }
                        for (const project of month.projects) {
                            this.appendObjectPropertiesToDataFromSettledElement(
                                data,
                                project.settledElements,
                                method.id,
                                month.data_rozp
                            );
                        }
                        for (const agreement of month.agreements) {
                            this.appendObjectPropertiesToDataFromSettledElement(
                                data,
                                agreement.settledElements,
                                method.id,
                                month.data_rozp
                            );
                        }
                    }
            }
            this.appendObjectPropertiesToDataFromSettledElement(
                data,
                method.settledElements,
                method.id
            );
            this.appendObjectPropertiesToDataFromSettledSingleElement(
                data,
                method.settledSingleElements,
                method.id
            );
        }
    }

    private appendObjectPropertiesToDataFromSettledElement(
        data: { [key: string]: any },
        settledElements: SettledElement[],
        methodId: string,
        month_data_rozp?: string
    ) {
        for (const settledElement of settledElements) {
            const dataKey = this.getObjectIdentifierDataKeyFromSettleObjectCode(
                settledElement.code
            );
            if (!(dataKey in data)) {
                data[dataKey] = [];
            }
            for (const object of settledElement.objects) {
                data[dataKey].push(
                    this.getObjectIdentifierValueFromSettleObjectCode(
                        settledElement.code,
                        object,
                        month_data_rozp
                    )
                );
                this.appendRestOfNeededObjectPropertiesToData(
                    data,
                    object,
                    settledElement.code,
                    methodId,
                    month_data_rozp
                );
            }
        }
        return data;
    }

    private appendObjectPropertiesToDataFromSettledSingleElement(
        data: { [key: string]: any },
        settledSingleElement: SettledSingleElements,
        methodId: string,
        month_data_rozp?: string
    ) {
        let key: keyof SettledSingleElements;
        for (key in settledSingleElement) {
            const dataKey = this.getObjectIdentifierDataKeyFromSettleObjectCode(key);
            if (!(dataKey in data)) {
                data[dataKey] = [];
            }
            data[dataKey].push(
                this.getObjectIdentifierValueFromSettleObjectCode(
                    key,
                    settledSingleElement[key],
                    month_data_rozp
                )
            );
            this.appendRestOfNeededObjectPropertiesToData(
                data,
                settledSingleElement[key],
                key,
                methodId,
                month_data_rozp
            );
        }
        return data;
    }

    /**
     * @returns formdata key for which we will append object ids(like zadania[]: 1542) for certain @param objectType
     */
    private getObjectIdentifierDataKeyFromSettleObjectCode(objectType: SettledObjectsCodes) {
        switch (objectType) {
            case 'ryczalt_miesiac':
                return 'ryczalty[]';
            case 'ryczalt':
                return 'kontrakty_ryczalty_id[]';
            case 'dokumenty_kontrakt':
                return 'dokumenty[]';
            case 'oplatywstepne_za_sprawy':
                return 'oplatywstepne_sprawy[]';
            case 'kosztyzastepstwa':
                return 'kosztzastepstwa[]';
            case 'limit_godzin':
                return 'limity_godzin[]';
            case 'successfees':
                return 'successfee[]';
            case 'koszty_projektow':
                return 'koszty[]';

            default:
                return `${objectType}[]`;
        }
    }

    /**
     * analog to getObjectIdentifierDataKeyFromSettleObjectCode but return id for formdata object identification (like zadania[]: 1542)
     */
    private getObjectIdentifierValueFromSettleObjectCode(
        objectType: SettledObjectsCodes,
        settledSingleElement: SettleObject,
        month_data_rozp?: string
    ) {
        switch (objectType) {
            case 'koszty':
                return `${settledSingleElement.id}_${month_data_rozp}`;

            default:
                return settledSingleElement.id;
        }
    }

    private appendRestOfNeededObjectPropertiesToData(
        data: { [key: string]: any },
        settledSingleElement: SettleObject,
        objectType: SettledObjectsCodes,
        methodId: string,
        month_data_rozp?: string
    ) {
        this.appendObjectIdIdentifierToData(
            data,
            settledSingleElement,
            objectType,
            month_data_rozp
        );
        this.appendSettlementMethodToObjectIdentifierToData(
            data,
            settledSingleElement,
            methodId,
            objectType,
            month_data_rozp
        );

        this.appendSettlementMethodsRatesToData(data);
    }

    private appendSettlementMethodToObjectIdentifierToData(
        data: { [key: string]: any },
        settledSingleElement: SettleObject,
        methodId: string,
        objectType: SettledObjectsCodes,
        month_data_rozp?: string
    ) {
        // to mimic z_kontrakt_137602:525 and so on
        switch (objectType) {
            case 'ryczalt':
                break;
            case 'ryczalt_miesiac':
                data[`ryczalt_kontrakt_${settledSingleElement.id}`] = methodId;
                break;
            // <input type="hidden" name="koszt_kontrakt_{$item.id}_{$miesiac.data_rozp}" value="{$kontrakt.id}" />
            case 'koszty':
                data[`koszt_kontrakt_${settledSingleElement.id}_${month_data_rozp}`] = methodId;
                break;
            // <input type="hidden" name="rozprawa_kontrakt_{$item.id}" value="{$kontrakt.id}" />
            case 'rozprawy':
                data[`rozprawa_kontrakt_${settledSingleElement.id}`] = methodId;
                break;
            // <input type="hidden" name="kontakt_kontrakt_{$item.id}" value="{$kontrakt.id}" />
            case 'kontakty':
                data[`kontakt_kontrakt_${settledSingleElement.id}`] = methodId;
                break;
            // <input type="hidden" name="rozprawa_ryczalt_kontrakt_{$item.id}" value="{$kontrakt.id}" />
            case 'rozprawy_ryczalty':
                data[`rozprawa_ryczalt_kontrakt_${settledSingleElement.id}`] = methodId;
                break;
            // <input type="hidden" name="kontakt_ryczalt_kontrakt_{$item.id}" value="{$kontrakt.id}" />
            case 'kontakty_ryczalty':
                data[`kontakt_ryczalt_kontrakt_${settledSingleElement.id}`] = methodId;
                break;
            // <input type="hidden" name="dokumenty_kontrakt_{$item.id}" value="{$kontrakt.id}" />
            case 'dokumenty':
                data[`dokumenty_kontrakt_${settledSingleElement.id}`] = methodId;
                break;
            // <input type="hidden" name="limit_godzin_kontrakt_{$miesiac.limit_godzin_id}" value="{$kontrakt.id}" />
            case 'limit_godzin':
                data[`limit_godzin_kontrakt_${settledSingleElement.id}`] = methodId;
                break;
            // <input type="hidden" name="fakturowaneelicencja_kontrakt_{$i.id}" value="{$kontrakt.id}" />
            case 'fakturowaneelicencje':
                data[`fakturowaneelicencja_kontrakt_${settledSingleElement.id}`] = methodId;
                break;
            // <input type="hidden" name="successfee_kontrakt_{$item.id}" value="{$kontrakt.id}" />
            case 'successfees':
                data[`successfee_kontrakt_${settledSingleElement.id}`] = methodId;
                break;
            // <input type="hidden" name="kosztzastepstwa_kontrakt_{$item.id}" value="{$kontrakt.id}" />
            case 'kosztyzastepstwa':
                data[`kosztzastepstwa_kontrakt_${settledSingleElement.id}`] = methodId;
                break;
            // <input type="hidden" name="dokumenty_kontrakt_{$item.id}" value="{$kontrakt.id}" />
            case 'dokumenty_kontrakt':
                data[`dokumenty_kontrakt_${settledSingleElement.id}`] = methodId;
                break;
            case 'oplatywstepne_za_sprawy':
                data[`oplatywstepne_sprawy_kontrakt_${settledSingleElement.id}`] = methodId;
                break;
            case 'ryczalty_za_sprawy':
                data[`ryczalty_za_sprawy_kontrakt_${settledSingleElement.id}`] = methodId;
                break;
            case 'koszty_projektow':
                data[`koszt_kontrakt_${settledSingleElement.id}`] = methodId;
                break;
            case 'etapyprojektow':
                data[`e_kontrakt_${settledSingleElement.id}`] = methodId;
                break;
            case 'zadania':
            default:
                data[`z_kontrakt_${settledSingleElement.id}`] = methodId;
                break;
        }
    }

    private appendObjectIdIdentifierToData(
        data: { [key: string]: any },
        settledSingleElement: SettleObject,
        objectType: SettledObjectsCodes,
        month_data_rozp?: string
    ) {
        switch (objectType) {
            case 'koszty':
                data[`koszt_id_${settledSingleElement.id}_${month_data_rozp}`] =
                    settledSingleElement.id;
                break;
            default:
                break;
        }
    }

    private appendSettlementMethodsRatesToData(data: { [key: string]: any }) {
        for (const method of this.budgetState.settlementMethods) {
            data[`kurs_kontraktu_${method.id}`] = method.kurs_waluty;
        }
    }

    // handling additional params

    /**
     * appending invoice-related params to @param data like: kwota_do_faktury, kurs_faktury, waluta_faktury, waluty_do_faktury
     */
    private appendInvoiceParamsToData(data: { [key: string]: any }) {
        data['kwota_do_faktury'] = this.dataState.globalSummary.amount;
        data['kurs_faktury'] = this.budgetState.invoiceRate;
        data['waluta_faktury'] = this.budgetState.invoiceCurrency;
        data['waluty_do_faktury'] = this.calculateCurrencyToInvoiceParam();
    }

    private calculateCurrencyToInvoiceParam() {
        const currenciesElementsToSum: { [key: string]: number } = {};

        // first loop to obstain elements that we sum
        const invoicedElementsKeys: string[] = [];
        this.loopThroughDataStateMain((keyOfField, fieldValue, keyIdSplitted) => {
            if (keyIdSplitted[1] === '1001' && 'checkbox' in fieldValue && fieldValue.checkbox) {
                invoicedElementsKeys.push(
                    this.createInvoicedElementsKey(keyIdSplitted, keyOfField)
                );
            }
        });

        // then we get array of currency and total price for that invoiced elements
        const priceAndCurrencyData: { [key: string]: { currency: string; price: number } } = {};
        for (let i = 0; i < invoicedElementsKeys.length; i++) {
            priceAndCurrencyData[invoicedElementsKeys[i]] = {
                price: 0,
                currency: ''
            };
        }

        this.loopThroughDataStateMain((keyOfField, fieldValue, keyIdSplitted) => {
            const elementKey = this.createInvoicedElementsKey(keyIdSplitted, keyOfField);

            if (!invoicedElementsKeys.includes(elementKey)) {
                return;
            }

            if (keyIdSplitted[1] === '12' && 'liczba' in fieldValue) {
                priceAndCurrencyData[elementKey].price = parseFloat(fieldValue.liczba ?? '0');
            }
            if (keyIdSplitted[1] === '14' && 'tekst' in fieldValue) {
                priceAndCurrencyData[elementKey].currency = this.getCurrencyTypeSymbol(
                    fieldValue.tekst,
                    keyOfField
                );
            }
        });

        // now we calculate the sum of prices for all currencies types
        for (const invoicedElementData of Object.values(priceAndCurrencyData)) {
            if (invoicedElementData.currency in currenciesElementsToSum) {
                currenciesElementsToSum[invoicedElementData.currency] += invoicedElementData.price;
            } else {
                currenciesElementsToSum[invoicedElementData.currency] = invoicedElementData.price;
            }
        }

        // create string with currency type and total price
        let returnCurrencyToInvoiceString = '';
        for (const [currency, price] of Object.entries(currenciesElementsToSum)) {
            returnCurrencyToInvoiceString += `;${currency}_${price}`;
        }

        return returnCurrencyToInvoiceString;
    }

    private getCurrencyTypeSymbol(currencyText: string | null, keyOfField: string) {
        // not in switch to eslint detect that return won't be null
        if (currencyText === null) {
            return 'PLN';
        }

        switch (true) {
            case keyOfField.startsWith('ryczalty_za_sprawy_waluta_'):
                return `${currencyText}_rzs`;
            case keyOfField.startsWith('koszt_waluta_'):
                return `${currencyText}_k`;
        }

        return currencyText;
    }

    private createInvoicedElementsKey(keyIdSplitted: string[], keyOfField: string) {
        const keyEnd = keyIdSplitted.slice(2).join('!');
        const keyStart = keyIdSplitted.slice(0, 1).join('!');
        return keyStart + keyOfField.split('_').slice(-1)[0] + keyEnd;
    }

    private loopThroughDataStateMain(
        callback: (
            keyOfField: string,
            fieldValue: FieldsStateUnionRHF,
            keyIdSplitted: string[]
        ) => void
    ) {
        for (const methodId in this.dataState.main) {
            if (typeof this.dataState.main[methodId] !== 'object') {
                continue;
            }

            for (const keyOfField in this.dataState.main[methodId]) {
                const fieldValue = this.dataState.main[methodId][keyOfField];
                if (typeof fieldValue !== 'object') {
                    continue;
                }

                if (!('keyId' in fieldValue) || !fieldValue.keyId) {
                    continue;
                }

                const keyId = fieldValue.keyId;
                const keyIdSplitted = keyId.split('!');

                callback(keyOfField, fieldValue, keyIdSplitted);
            }
        }
    }
}
