import React from 'react';

import { useAppDispatch } from 'src/store';
import { clearState } from 'src/store/src/massBill';

export function useUnMountMassBill() {
    const dispatch = useAppDispatch();
    React.useEffect(() => {
        return () => {
            dispatch(clearState());
        };
    }, []);
}
