import React, { FC } from 'react';
import ButtonCustom from 'src/components/shared/atoms/button/Button';
import { MassBillActionProps } from './types';
import { EventBus } from 'src/utils/src/shared/EventBus';

export const MassBillAction: FC<MassBillActionProps> = ({ children, data }) => {
    const handleApiAction = async () => {
        EventBus.$emit('massBillAction', data);
    };

    return (
        <ButtonCustom onClick={() => handleApiAction()} withoutStyles={true}>
            {children}
        </ButtonCustom>
    );
};
