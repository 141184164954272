import { BudgetOperationalState } from 'src/hooks/src/budget/useFormBudget';
import { BudgetScreenState } from 'src/store/src/budget/budget/types';
import {
    BudgetHandlerStateGlobalFnExecuteProps,
    BudgetHandlerStateGlobalFnExecuteSharedProps
} from 'src/utils/src/budget/BudgetHandlerStateGlobal';
import { updateKeyReset } from 'src/utils/src/shared/updateKeyReset';

// Class working on only onChange

type BudgetHandlerTickAllInMonthProps = BudgetHandlerStateGlobalFnExecuteSharedProps & {};
export class BudgetHandlerTickAllInMonth {
    budget: BudgetScreenState;
    operationalState: BudgetOperationalState;
    constructor(data: BudgetHandlerTickAllInMonthProps) {
        this.budget = data.budget;
        this.operationalState = data.operationalState;
    }

    execute(data: BudgetHandlerStateGlobalFnExecuteProps) {
        this.updateStates(data);
    }

    private updateStates(data: BudgetHandlerStateGlobalFnExecuteProps) {
        if (data.tickAll === 'tick') {
            for (const key in this.operationalState.fieldsTempFromRHF) {
                if (
                    key.includes('invoice') &&
                    this.operationalState.fieldsTempFromRHF[key]?.keyId?.includes(
                        `${data.methodId}!${data.monthId}`
                    ) &&
                    !this.operationalState.fieldsTempFromRHF[key].isReadOnly
                ) {
                    this.operationalState.fieldsTemp[key] = {
                        ...this.operationalState.fieldsTempFromRHF[key],
                        checkbox: true,
                        keyReset: updateKeyReset(
                            this.operationalState.fieldsTempFromRHF[key].keyReset
                        )
                    };
                    this.operationalState.fieldsTempFromRHF[key] = {
                        ...this.operationalState.fieldsTempFromRHF[key],
                        checkbox: true,
                        keyReset: updateKeyReset(
                            this.operationalState.fieldsTempFromRHF[key].keyReset
                        )
                    };
                }
            }
        } else {
            for (const key in this.operationalState.fieldsTempFromRHF) {
                if (
                    key.includes('invoice') &&
                    this.operationalState.fieldsTempFromRHF[key]?.keyId?.includes(
                        `${data.methodId}!${data.monthId}`
                    )
                ) {
                    this.operationalState.fieldsTemp[key] = {
                        ...this.operationalState.fieldsTempFromRHF[key],
                        checkbox: false,
                        keyReset: updateKeyReset(
                            this.operationalState.fieldsTempFromRHF[key].keyReset
                        )
                    };
                    this.operationalState.fieldsTempFromRHF[key] = {
                        ...this.operationalState.fieldsTempFromRHF[key],
                        checkbox: false,
                        keyReset: updateKeyReset(
                            this.operationalState.fieldsTempFromRHF[key].keyReset
                        )
                    };
                }
            }
        }
    }
}
