import { MassBillWorker } from 'src/api/src/massBill/types';
import { useGetMassBillParams } from 'src/hooks/src/massBill/useGetMassBillParams';
import { useAppDispatch, useTypedSelector } from 'src/store';
import { setUpdateWorker } from 'src/store/src/massBill';

export type UseUpdateStateFunctionsMassBill = {
    worker: MassBillWorker;
    updateWorker: (id: string) => void;
    id_pracownika?: string | null;
};

export const useHandleWorker = (): UseUpdateStateFunctionsMassBill => {
    const worker = useTypedSelector((state) => state.massBill.worker);
    const dispatch = useAppDispatch();
    const { id_pracownika } = useGetMassBillParams();

    function updateWorker(id: string) {
        dispatch(setUpdateWorker(id));
    }

    return {
        updateWorker,
        worker,
        id_pracownika
    };
};
