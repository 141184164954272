import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BaseApiResponse } from 'src/api/types';
import { DEFAULT_SYSTEM_COLOR, SYSTEM_NAMES } from 'src/constants';
import { AppThunk } from 'src/store';
import { SystemConfig, SystemConfigResponse, SystemState, SystemName } from './types';
import { registerLicense, L10n, setCulture, loadCldr } from '@syncfusion/ej2-base';
import gregorian from 'cldr-data/main/pl/ca-gregorian.json';
import numbers from 'cldr-data/main/pl/numbers.json';

export const initialState: SystemState = {
    systemLogo: '',
    systemLogoClient: null,
    systemLogoMini: '',
    systemLogoSign: '',
    systemName: null,
    pageTitle: null,
    systemUrl: null,
    nodeUrl: null,
    color: DEFAULT_SYSTEM_COLOR,
    systemIcon: null,
    initialDataLoaded: false,
    spinnerRun: false,
    assertDataFromApi: false,
    isWithoutGlobalSearch: false,
    systemLock: false
};

const systemSlice = createSlice({
    name: 'system',
    initialState,
    reducers: {
        setSystemConfig: (state, action: PayloadAction<SystemConfig>) => {
            const {
                systemName,
                systemUrl,
                nodeUrl,
                color,
                systemIcon,
                systemLogo,
                systemLogoClient,
                systemLogoMini,
                systemLogoSign,
                assertDataFromApi,
                pageTitle,
                isWithoutGlobalSearch,
                systemLock,
                syncfusionLicenseKey
            } = action.payload;
            state.systemName = systemName;
            state.pageTitle = pageTitle;
            state.systemUrl = systemUrl;
            state.nodeUrl = nodeUrl;
            state.color = color;
            state.systemIcon = systemIcon;
            state.systemLogo = systemLogo;
            state.systemLogoMini = systemLogoMini;
            state.systemLogoClient = systemLogoClient;
            state.systemLogoSign = systemLogoSign;
            state.assertDataFromApi = assertDataFromApi;
            state.isWithoutGlobalSearch = isWithoutGlobalSearch;
            state.syncfusionLicenseKey = syncfusionLicenseKey;
            state.systemLock = systemLock;
        },
        setInitialDataLoaded: (state) => {
            state.initialDataLoaded = true;
        },
        setSpinner: (state, action: PayloadAction<boolean>) => {
            state.spinnerRun = action.payload;
        },
        closeSpinner: (state) => {
            state.spinnerRun = false;
        },
        setPageTitle: (state, action: PayloadAction<string>) => {
            state.pageTitle = action.payload;
        }
    }
});

export const handleSystemConfig = (data: SystemConfigResponse) => {
    console.log('title: handleSystemConfig', data.systemName);
    if (data.syncfusionLicenseKey) {
        // Registering Syncfusion license key
        registerLicense(data.syncfusionLicenseKey);
        // load localization synfusion esj2
        loadCldr(gregorian, numbers);
        setCulture('pl');
        L10n.load({
            pl: {
                gantt: {
                    days: 'dni',
                    startDate: 'Data początkowa',
                    endDate: 'Data końcowa',
                    duration: 'Czas trwania',
                    progress: '% Zaaw.',
                    zoomIn: 'Przybliż',
                    zoomOut: 'Oddal',
                    zoomToFit: 'Dopasuj do okna'
                }
            }
        });
    }
    // validate if system name is detected correctly
    if (!SYSTEM_NAMES.includes(data.systemName)) {
        console.error(`Invalid system_name from server: ${data.systemName}`);
        return setSystemConfig({
            ...data,
            systemName: 'Mecenas.iT' as SystemName,
            pageTitle: 'Mecenas.iT'
        });
    }
    return setSystemConfig({ ...data, pageTitle: data.systemName });
};

export const updatePageTitleWithScreenName =
    (screenName: string): AppThunk =>
    async (dispatch, getState) => {
        const {
            system: { systemName }
        } = getState();
        if (systemName == null) return;

        dispatch(setPageTitle(`${screenName} | ${systemName}`));
    };

// change page title to defaulkt state of displaying systemName
export const resetPageTitle = (): AppThunk => async (dispatch, getState) => {
    const {
        system: { systemName }
    } = getState();

    if (systemName) dispatch(setPageTitle(systemName));
};

/**
 * load system config to system if api response is proper, otherwise throw apropriate error
 */

export const handleSystemConfigApiResponse =
    (apiResponse: BaseApiResponse<SystemConfigResponse>): AppThunk =>
    async (dispatch) => {
        if (apiResponse.data && apiResponse.success) {
            dispatch(handleSystemConfig(apiResponse.data));
        } else {
            throw new Error(apiResponse.message);
        }
    };

export const { setSystemConfig, setInitialDataLoaded, setSpinner, closeSpinner, setPageTitle } =
    systemSlice.actions;

export default systemSlice.reducer;
