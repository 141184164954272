import { useGetMassBillParams } from 'src/hooks/src/massBill/useGetMassBillParams';

export function useGetSetLayoutForMassBill(
    placement: 'client' | 'contract' | 'caseItem' | 'position'
) {
    const { aktualizacja, pokaz_pracownikow, grupowanie_tylko_po_kontraktach } =
        useGetMassBillParams();
    let colspan = 0;
    switch (placement) {
        case 'client':
            colspan = 3;
            break;
        case 'contract':
            colspan = 2;
            break;
        case 'caseItem':
            colspan = 1;
            break;
        case 'position':
            colspan = 2;
            break;
        default:
            break;
    }

    if (aktualizacja) {
        colspan += 1;
    }
    if (pokaz_pracownikow) {
        colspan += 1;
    }
    if (!grupowanie_tylko_po_kontraktach && placement !== 'caseItem') {
        colspan += 1;
    }
    if (grupowanie_tylko_po_kontraktach && placement === 'contract') {
        colspan = colspan - 2;
    }
    return {
        colspan,
        pokaz_pracownikow,
        grupowanie_tylko_po_kontraktach
    };
}
