import io from 'socket.io-client';
import { batch } from 'react-redux';

import store from 'src/store';
import * as actions from '../store/actions/index';
import {
    addAvatarToUser,
    playSound,
    createChatObject,
    findChatInfoByRoomID,
    flashHaveUnreadMessage
} from '../../shared/utils';
import bell from '../assets/bell.mp3';
import bubble from '../assets/bubble.mp3';
import { getRoomID } from '../utils/getStoreRoomID';
import { getCompatibleUrl } from 'src/utils';

/**
 * @category Sockets
 * @namespace SocketEventsListeners
 * @description Funkcje które obsługują eventy przesyłane przez serwer.
 */

/**
 * @category Sockets
 * @namespace Socket
 * @description połączenie z serwerem obsługującym websockets, pozwala na nasłuchiwanie eventów z serwera, i wysyłanie eventów na serwer
 * @example
 *  const socket = io(`${ NODE_URL }:${ NODE_PORT }`);
 */

/**
 * @category Sockets
 * @namespace SocketServerEvents
 * @description Eventy przesyłane przez serwer
 * @see [SocketEventsListeners]{@link SocketEventsListeners}, @see [socket.on]{@link Socket.on}
 */

/**
 * @category Sockets
 * @namespace SocketClientEvents
 * @description Eventy przesyłane na serwer
 * @see [socket.emit]{@link Socket.emit}
 */

/**
 * @category Sockets
 * @event client_synch
 * @param {object} userData
 * @param {string} userData.userID - id użytkownika
 * @param {string} userData.userType - typ użytkownika
 * @param {string} userData.sessionID - id sesji w systemie
 * @memberof SocketClientEvents
 * @description Użytkownik wysyła ten event po wyciągnięciu swoich danych z systemu, event tworzy lub aktualizuje użytkownika na serwerze.
 * @see [reconnect]{@link SocketServerEvents.reconnect}
 */

/**
 * @category Sockets
 * @event client_open_app
 * @memberof SocketClientEvents
 * @description Event wysyłany jest na serwer przy otwieraniu aplikacji. Oznacza dany socket jako aktywny. Dezaktywuje pozostałe sockety.
 */

/**
 * @category Sockets
 * @event client_close_chat
 * @param {object} data
 * @param {number} data.chatID - identyfikator czatu w bazie danych
 * @memberof SocketClientEvents
 * @description Wysyłany po zamknięciu okna istniejącego czatu.
 * @see [reconnect]{@link SocketServerEvents.reconnect}
 */

/**
 * @category Sockets
 * @event client_open_chat
 * @param {object} data
 * @param {string} data.roomID - roomID czatu
 * @param {number} data.windowID - id okna czatu
 * @memberof SocketClientEvents
 * @description Wysyłany po otwarciu okna istniejącego czatu.
 */

/**
 * @category Sockets
 * @event client_create_chat
 * @param {object} data
 * @param {'private'|'group'} data.chatType - typ czatu
 * @param {string|null} data.name - nazwa czatu, tylko w czatach grupowych
 * @param {string[]} data.users - tablica id użytkowników
 * @param {string} data.windowID - id okna
 * @memberof SocketClientEvents
 * @description Wysyłany przed wysłaniem pierwszej wiadomości nieistniejącego jeszcze czatu.
 */

/**
* @category Sockets
* @event client_config_sounds
* @memberof SocketClientEvents
* @description Wysyłany żeby zmienić ustawienia dźwięku. Nie przyjmuje argumentów.
Odwraca stan zastany na serwerze.
*/

/**
* @category Sockets
* @event client_config_activeStatus
* @memberof SocketClientEvents
* @description Wysyłany żeby zmienić ustawienia statusu aktywności. Nie przyjmuje argumentów.
Odwraca stan zastany na serwerze.
*/

/**
* @category Sockets
* @event client_config_busy
* @memberof SocketClientEvents
* @description Wysyłany żeby zmienić ustawienia zajętości. Nie przyjmuje argumentów.
Odwraca stan zastany na serwerze.
*/

/**
 * @category Sockets
 * @event client_text_message
 * @param {object} data
 * @param {number} data.chatID - identyfikator czatu w bazie danych
 * @param {object} data.message
 * @param {'text'} data.message.type typ, obecnie tylko 'text'
 * @param {string} data.message.content text wiadomości
 * @memberof SocketClientEvents
 * @description Wysyła wiadomość tekstową na serwer.
 */

/**
 * @category Sockets
 * @event client_delete_message
 * @param {object} data
 * @param {number} data.chatID - identyfikator czatu w bazie danych
 * @param {string} data.id - id wiadomości
 * @memberof SocketClientEvents
 * @description Wysyła polecenie usunięcia wiadomości.
 */

/**
 * @category Sockets
 * @event client_file
 * @param {object} data
 * @param {number} data.chatID - identyfikator czatu w bazie danych
 * @param {'image'|'file'} data.type typ pliku
 * @param {object} data.file obiekt z plikiem
 * @param {Buffer} data.file.buffer buffer z danymi pliku
 * @param {string} data.file.type mimeType pliku
 * @param {string} data.file.name nazwa pliku
 * @memberof SocketClientEvents
 * @description Wysyła plik na serwer.
 */

/**
 * @category Sockets
 * @event client_load_messages
 * @param {object} data
 * @param {number|string|null} data.messageID - identyfikator pierwszej załadowanej wiadomości lub null(string gdy id wiadomości jest tymczasowe)
 * @param {'image'|'file'} data.type typ pliku
 * @param {object} data.roomID roomID czatu
 * @param {number|null} data.bottomOffset informacja czy i o ile jest przeskrolowane od dołu okno z czatem.
 * @param {string} data.file.type mimeType pliku
 * @param {string} data.file.name nazwa pliku
 * @memberof SocketClientEvents
 * @description Wysyła na serwer żądanie doładowania wiadomości.
 */

/**
 * @category Sockets
 * @event client_delete_group
 * @param {object} data
 * @param {string} data.roomID - roomID czatu
 * @memberof SocketClientEvents
 * @description Wysyłany przy kasowaniu grupy.
 */

/**
 * @category Sockets
 * @event client_remove_self_from_group
 * @param {object} data
 * @param {string} data.roomID - roomID czatu
 * @memberof SocketClientEvents
 * @description Wysyłany przy usunięciu się z grupy.
 */

/**
 * @category Sockets
 * @event client_modify_group
 * @param {object} data
 * @param {string} data.roomID - roomID czatu
 * @param {string|false} data.modifiedName - zmieniona nazwa lub false jeśli jest taka sama
 * @param {string[]|false} data.modifiedUsers - zmieniona lista id użytkowników lub false jeśli została taka sama
 * @memberof SocketClientEvents
 * @description Wysyłany przy modyfikacji grupy.
 */

/**
 * @category Sockets
 * @memberof Socket
 * @function emit
 * @param {string} name - nazwa eventu do wysłania
 * @param {object} [data={}] - dane do przesłania
 * @param {Function} cb - funkcja którą może wywołać serwer po otrzymaniu wiadomości
 * @description wysyła na serwer event o podanej nazwie i danych do przesłania
 * @see [emitSocketEvent]{@link emitSocketEvent}, [emitSocketEvent]{@link emitSocketEvent}
 * @example
 * socket.emit('client_text_message', message, (error, data) => {
 * 		if(error){
 * 			setError(error)
 * 		}
 * });
 */

/**
* @category Sockets
* @memberof Socket
* @function on
* @description pozwala na nasłuchiwanie eventów z serwera.
* @param {string} name - nazwa eventu
* @param {Function} cb - funkcja obsługująca event
* @param {...any} cb.args - argumenty przekazane przez event do callbacka
* @example
* socket.on('logout', () => window.location.href = './index.php');
* @example
* socket.on('server_user_activeStatus', ({ id, value }) => {
		//  console.log('server_user_activeStatus', id, value);
		const state = store.getState();
		if (state.user.users[id]) {
			dispatch(actions.setUserActiveStatus(id, value));
		}
	});
* @see [SocketEventsListeners]{@link SocketEventsListeners}
*/

export let socket;
const bellSound = new Audio(bell);
const bubbleSound = new Audio(bubble);
const { dispatch, getState } = store;
let syncStarted = false;

/**
 * @async
 * @category Sockets
 * @function synchUser
 * @description Funkcja sprawdza w systemie czy użytkownik jest zalogowany, jeśli tak, wysyła event [client_synch]{@link SocketClientEvents.client_synch}
 * Jeśli nie przeładowuje stronę na index.php
 * @returns {void}
 */
export const synchUser = () => {
    syncStarted = true;
    const {
        system: { systemUrl: system_url },
        user: { sessionID }
    } = getState();
    const roomID = getRoomID();
    const userData = { roomID, sessionID, system_url };

    emitSocketEvent('synch', userData, {
        loading: true,
        errorAction: () => {
            syncStarted = false;
            // było tak, ale powodowało to ciągle wyskakujące błędy, gdy serwer mulił.
            // Po załadowaniu każdej strony w systemie, pojawiał się błąd czatu, co utrudniało pracę
            // dispatch(actions.setError({ type: 'Błąd synchronizacji', message: 'Nie udało się zsynchronizować z serwerem', critical: true }));

            // teraz nie rzucam błędem, tylko rozłączam po cichu
            socket.disconnect();
            dispatch(actions.setChatOpen(false));
            dispatch(actions.setDisconnected(true));
        }
    });
};

/**
 * @async
 * @category Sockets
 * @function initSocketEvents
 * @param {string} node_url - url serwera nodejs
 * @param {string} system_url - url systemu i jednocześnie nazwa namespace serwera websockets
 * @param {number} userID - url systemu i jednocześnie nazwa namespace serwera websockets
 * @param {string} userType - url systemu i jednocześnie nazwa namespace serwera websockets
 * @description Funkcja łączy się z serwerem websockets(nodejs), i inicjuje obsługe eventów z tym związany
 * @see [SocketClientEvents]{@link SocketClientEvents}, [SocketServerEvents]{@link SocketServerEvents}
 * @returns {void}
 */
export const initSocketEvents = (node_url, system_url, userID, userType) => {
    /* SAVE variable */

    socket = io(`${node_url}/${system_url}`, { query: `userID=${userID}&userType=${userType}` });
    /* HANDLING CONNECTION */

    /**
     * @category Sockets
     * @event connect
     * @memberof SocketServerEvents
     * @description Event wysyłany jest kiedy nastąpi udane połączenie.
     * @see [on_connect]{@link SocketEventsListeners.on_connect}
     */
    /**
     * @category Sockets
     * @function on_connect
     * @param {object} err Obiekt błędu.
     * @listens connect
     * @memberof SocketEventsListeners
     * @description Wywołuje [synchUser]{@link synchUser}.
     * @see [connect]{@link SocketServerEvents.connect}
     */
    socket.on('connect', () => {
        console.log('connected');
        process.env.NODE_ENV === 'development' &&
            console.log('connected', 'syncStarted:', syncStarted);

        if (!syncStarted) {
            synchUser();
        }
    });

    /**
     * @category Sockets
     * @event reconnect
     * @memberof SocketServerEvents
     * @description Event wysyłany jest kiedy nastąpi udana ponowna próba połączenia.
     * @see [on_reconnect]{@link SocketEventsListeners.on_reconnect}
     */
    /**
	* @category Sockets
	* @function on_reconnect
	* @param {object} err Obiekt błędu.
	* @listens reconnect
	* @memberof SocketEventsListeners
	* @description Funkcja identyfikuje użytkownika w systemie. Jeśli nie został zidentyfikowany, przenosi go na index.php. Po identyfikacji ,
	ustawia stan aplikacji na conneted, anuluje błędy. Wywołuje [client_synch]{@link SocketClientEvents.client_synch}.
	* @see [reconnect]{@link SocketServerEvents.reconnect}
	*/
    socket.on('reconnect', () => {
        process.env.NODE_ENV === 'development' &&
            console.log('reconnected', 'syncStarted', syncStarted);

        batch(() => {
            dispatch(actions.resetAppState());
            dispatch(actions.cancelError());
            dispatch(actions.setDisconnected(false));
        });

        if (!syncStarted) {
            synchUser();
        }
    });

    /**
     * @category Sockets
     * @event disconnect
     * @memberof SocketServerEvents
     * @description Event wysyłany jest kiedy klient traci połączenie z serwerem.
     * @see [on_disconnect]{@link SocketEventsListeners.on_disconnect}
     */
    /**
     * @category Sockets
     * @function on_disconnect
     * @param {string} reason przyczyna rozłączenia.
     * @listens disconnect
     * @memberof SocketEventsListeners
     * @description Funkcja ustawia stan disconnected, resetuje i zamyka czat.
     * @see [disconnect]{@link SocketServerEvents.disconnect}
     */
    socket.on('disconnect', (reason) => {
        process.env.NODE_ENV === 'development' && console.log('disconnect', reason);
        if (reason !== 'transport close') {
            const { sounds, tabActive } = getState().app;

            if (sounds && tabActive) {
                playSound(bubbleSound);
            }

            batch(() => {
                dispatch(actions.resetAppState());
                // nie trigeruje błędu przy wylogowaniu.
                if (reason !== 'io server disconnect') {
                    setTimeout(
                        () =>
                            dispatch(
                                actions.setError({
                                    type: 'Błąd połączenia',
                                    message: 'Stracono połączenie z serwerem'
                                })
                            ),
                        2000
                    );
                }
                dispatch(actions.setChatOpen(false));
                dispatch(actions.setDisconnected(true));
            });
        }
    });

    /**
     * @category Sockets
     * @event connect_error
     * @memberof SocketServerEvents
     * @description Event wysyłany jest kiedy nastąpi błąd połączenia.
     * @see [on_connect_error]{@link SocketEventsListeners.on_connect_error}
     */
    /**
     * @category Sockets
     * @function on_connect_error
     * @param {object} err Obiekt błędu.
     * @listens connect_error
     * @memberof SocketEventsListeners
     * @description Funkcja ustawia stan disconnected, resetuje i zamyka czat.
     * @see [connect_error]{@link SocketServerEvents.connect_error}
     */
    socket.on('connect_error', (err) => {
        process.env.NODE_ENV === 'development' && console.log('connect_error', err.message);
        const { disconnected, sounds, tabActive } = getState().app;
        if (!disconnected) {
            if (sounds && tabActive) {
                playSound(bubbleSound);
            }

            setTimeout(
                () =>
                    dispatch(
                        actions.setError({
                            type: 'Błąd połączenia',
                            message: 'Nie udało się połączyć z serwerem'
                        })
                    ),
                2000
            );
            batch(() => {
                dispatch(actions.resetAppState());
                dispatch(actions.setChatOpen(false));
                dispatch(actions.setDisconnected(true));
            });
        }
    });

    socket.on('error', (err) => {
        console.log('error', err);
        if (err === 'authorization error') {
            batch(() => {
                dispatch(actions.resetAppState());
                dispatch(actions.setChatOpen(false));
                dispatch(actions.setDisconnected(true));
            });
        }
    });

    /* HANDLING CONNECTION  END */

    /**
     * @category Sockets
     * @event logout
     * @memberof SocketServerEvents
     * @description serwer wysyła ten event do wszystkich socketów danej sesji, kiedy otrzymuje informacje o wylogowaniu się użytkownika
     * @see [onLogout]{@link SocketEventsListeners.on_logout}
     */
    /**
     * @category Sockets
     * @function on_logout
     * @listens logout
     * @memberof SocketEventsListeners
     * @description przeładowuje okno na index.php
     * @see [logout]{@link SocketServerEvents.logout}
     */
    socket.on('logout', () => (window.location.href = getCompatibleUrl('/login', undefined)));

    /**
     * @category Sockets
     * @event server_config_sounds
     * @memberof SocketServerEvents
     * @description Event wysyłany po zmianie ustawień dźwięków na serwerze
     * @see [on_server_config_sounds]{@link SocketEventsListeners.on_server_config_sounds}
     */
    /**
     * @category Sockets
     * @function on_server_config_sounds
     * @param {boolean} bool
     * @listens server_config_sounds
     * @memberof SocketEventsListeners
     * @description ustawia odtwarzanie dźwięków w aplikacji
     * @see [server_config_sounds]{@link SocketServerEvents.server_config_sounds}
     */
    socket.on('server_config_sounds', (bool) => dispatch(actions.setSounds(bool)));

    /**
     * @category Sockets
     * @event server_config_activeStatus
     * @memberof SocketServerEvents
     * @description Event wysyłany po zmianie ustawień aktywności na serwerze
     * @see [on_server_config_activeStatus]{@link SocketEventsListeners.on_server_config_activeStatus}
     */
    /**
     * @category Sockets
     * @function on_server_config_activeStatus
     * @param {boolean} bool
     * @listens server_config_activeStatus
     * @memberof SocketEventsListeners
     * @description ustawia status aktywności głównego użytkownika.
     * @see [server_config_activeStatus]{@link SocketServerEvents.server_config_activeStatus}
     */
    socket.on('server_config_activeStatus', (bool) => dispatch(actions.setActiveStatus(bool)));

    /**
     * @category Sockets
     * @event server_config_busy
     * @memberof SocketServerEvents
     * @description Event wysyłany po zmianie ustawień zajętości na serwerze
     * @see [on_server_config_busy]{@link SocketEventsListeners.on_server_config_busy}
     */
    /**
     * @category Sockets
     * @function on_server_config_busy
     * @param {boolean} bool
     * @listens server_config_busy
     * @memberof SocketEventsListeners
     * @description ustawia status zajętości użytkownika.
     * @see [server_config_busy]{@link SocketServerEvents.server_config_busy}
     */
    socket.on('server_config_busy', (bool) => dispatch(actions.setBusy(bool)));

    /**
     * @category Sockets
     * @event server_user_activeStatus
     * @memberof SocketServerEvents
     * @description Event wysyłany po zmianie ustawień aktywności przez użytkownika do innych użytkowników.
     * @see [on_server_user_activeStatus]{@link SocketEventsListeners.on_server_user_activeStatus}
     */
    /**
     * @category Sockets
     * @function on_server_user_activeStatus
     * @param {object} params
     * @param {string} params.id - id użytkownika
     * @param {boolean} params.value - wartość jego statusu
     * @listens server_user_activeStatus
     * @memberof SocketEventsListeners
     * @description ustawia status zajętości konkretnego (nie głównego) użytkownika.
     * @see [server_user_activeStatus]{@link SocketServerEvents.server_user_activeStatus}
     */
    socket.on('server_user_activeStatus', ({ id, value }) => {
        const state = store.getState();
        process.env.NODE_ENV === 'development' &&
            console.log('server_user_activeStatus', 'id:', id, 'value:', value);

        if (state.chatUser.users[id]) {
            dispatch(actions.setUserActiveStatus(id, value));
        }
    });

    /**
     * @category Sockets
     * @event server_synch
     * @memberof SocketServerEvents
     * @description Event wysyłany po zsynchronizowaniu użytkownika na serwerze.
     * @see [on_server_synch]{@link SocketEventsListeners.on_server_synch}
     */
    /**
     * @category Sockets
     * @function on_server_synch
     * @param {object} client - informacje o głównym użytkowniku
     * @param {object} groups - informacje o grupach głównego użytkownika
     * @param {object} users - informacje o użytkownikach widocznych dla głównego użytkownika
     * @param {string[]} notifications - tablica z powiadomieniami
     * @listens server_synch
     * @memberof SocketEventsListeners
     * @description Ustawia dane użytkownika, jego kontakty, grupy, ustawienia.
     * @see [server_synch]{@link SocketServerEvents.server_synch}
     */
    socket.on('server_synch', (client, groups, users, notifications) => {
        syncStarted = false;
        const {
            chatUser: { groups: existingGroups }
        } = getState();
        process.env.NODE_ENV === 'development' && console.log('server_synch', groups);
        // /* Merge temporary groups with server sent groups */
        Object.keys(existingGroups)
            .filter((key) => /temp_/.test(key))
            .forEach((key) => (groups[key] = existingGroups[key]));
        const { user, app } = client;

        Object.entries(users).forEach(([key, user], i) => (users[key] = addAvatarToUser(user, i)));
        batch(() => {
            dispatch(actions.setLoadingState(false));
            dispatch(actions.setChatUser(user));
            dispatch(actions.setGroups(groups));
            dispatch(actions.setUsers(users));
            dispatch(actions.setSounds(app.sounds));
            dispatch(actions.setActiveStatus(app.activeStatus));
            dispatch(actions.setBusy(app.busy));
            dispatch(actions.setDisconnected(false));
            if (notifications) {
                dispatch(actions.setNotifications(notifications));
                dispatch(actions.setNotification(notifications[0]));
            }

            dispatch(actions.recreateChat());
        });
    });

    /**
     * @category Sockets
     * @event server_messages_read
     * @memberof SocketServerEvents
     * @description Event wysyłany po otwarciu czatu na serwerze. Odznacza nieodczytane wiadomości z tego czatu
     * @see [on_server_messages_read]{@link SocketEventsListeners.on_server_messages_read}
     */
    /**
     * @category Sockets
     * @function on_server_messages_read
     * @param {string} roomID - identyfikator czatu
     * @listens server_messages_read
     * @memberof SocketEventsListeners
     * @description Usuwa z nieodczytanych wiadomości wiadomości z danego czatu.
     * @see [server_messages_read]{@link SocketServerEvents.server_messages_read}
     */
    socket.on('server_messages_read', (roomID) => {
        if (process.env.NODE_ENV === 'development') {
            console.log('server_messages_read, roomID', roomID);
            console.log('[app state]:', getState().app);
        }
        dispatch(actions.removeUnreadChat(roomID));
    });

    /**
     * @category Sockets
     * @event server_users
     * @memberof SocketServerEvents
     * @description Event wysyłany gdy zmieniają się użytkownicy dostępni dla głównego uzytkownika
     * @see [on_server_users]{@link SocketEventsListeners.on_server_users}
     */
    /**
     * @category Sockets
     * @function on_server_users
     * @param {object} users - obiekt z użytkownikami
     * @listens server_users
     * @memberof SocketEventsListeners
     * @description Dodaje awatary do użytkowników i ustawia użytkowników w stanie aplikacji.
     * @see [server_users]{@link SocketServerEvents.server_users}
     */
    socket.on('server_users', (users) => {
        process.env.NODE_ENV === 'development' &&
            console.log('[server_users] length', Object.keys(users).length);
        Object.entries(users).forEach(([key, user], i) => (users[key] = addAvatarToUser(user, i)));
        dispatch(actions.setUsers(users));
    });

    /**
     * @category Sockets
     * @event server_groups
     * @memberof SocketServerEvents
     * @description Event wysyłany gdy zmieniają się grupy dostępne dla głównego uzytkownika
     * @see [on_server_groups]{@link SocketEventsListeners.on_server_groups}
     */
    /**
     * @category Sockets
     * @function on_server_groups
     * @param {object} groups - obiekt z grupami
     * @listens server_groups
     * @memberof SocketEventsListeners
     * @description Łączy tymczasowe lokalne grupy z grupami z serwera i ustawia grupy w stanie aplikacji.
     * @see [server_groups]{@link SocketServerEvents.server_groups}
     */
    socket.on('server_groups', (groups) => {
        const {
            chatUser: { groups: existingGroups }
        } = getState();

        process.env.NODE_ENV === 'development' &&
            console.log('[server_groups] length', Object.keys(groups).length);

        /* Merge temporary groups with server sent groups */
        Object.keys(existingGroups)
            .filter((key) => /temp_/.test(key))
            .forEach((key) => (groups[key] = existingGroups[key]));

        dispatch(actions.setGroups(groups));
    });

    /**
     * @category Sockets
     * @event server_delete_group
     * @memberof SocketServerEvents
     * @description Event wysyłany po skasowaniu grupy
     * @see [on_server_delete_group]{@link SocketEventsListeners.on_server_delete_group}
     */
    /**
     * @category Sockets
     * @function on_server_delete_group
     * @param {string} roomID - identyfikator grupy
     * @listens server_delete_group
     * @memberof SocketEventsListeners
     * @description Zamyka okno z czatem grupy, kasuje czat.
     * @see [server_delete_group]{@link SocketServerEvents.server_delete_group}
     */
    socket.on('server_delete_group', (roomID) => {
        process.env.NODE_ENV === 'development' && console.log('[server_delete_group]:', roomID);
        const { tabActive, openWindows } = getState().app;
        /* group windowID is also group roomID */
        const windowID = roomID;

        if (tabActive) {
            batch(() => {
                dispatch(actions.setLoadingState(false));
                dispatch(actions.removeUnreadChat(roomID));
                if (openWindows.includes(windowID)) {
                    dispatch(actions.closeChatWindow(windowID));
                    dispatch(actions.resetChat(roomID));
                }
            });
        }
    });

    /**
     * @category Sockets
     * @event server_close_group
     * @memberof SocketServerEvents
     * @description Event wysyłany po modyfikacji grupy
     * @see [on_server_close_group]{@link SocketEventsListeners.on_server_close_group}
     */
    /**
     * @category Sockets
     * @function on_server_close_group
     * @param {string} roomID - identyfikator grupy
     * @listens server_close_group
     * @memberof SocketEventsListeners
     * @description Zamyka okno z czatem grupy, kasuje czat.
     * @see [server_close_group]{@link SocketServerEvents.server_close_group}
     */
    socket.on('server_close_group', (roomID) => {
        process.env.NODE_ENV === 'development' && console.log('[server_delete_group]:', roomID);
        const { tabActive, openWindows } = getState().app;
        /* group windowID is also group roomID */
        const windowID = roomID;

        if (tabActive && openWindows.includes(windowID)) {
            batch(() => {
                dispatch(actions.closeChatWindow(windowID));
                dispatch(actions.resetChat(roomID));
            });
        }
    });

    /**
     * @category Sockets
     * @event server_notifications
     * @memberof SocketServerEvents
     * @description Event wysyłany po akcji która powoduje wygenerowanie powiadomień systemowych
     * @see [on_server_notifications]{@link SocketEventsListeners.on_server_notifications}
     */
    /**
     * @category Sockets
     * @function on_server_notifications
     * @param {string|string[]} notifications - 1 lub wiele powiadomień
     * @listens server_notifications
     * @memberof SocketEventsListeners
     * @description Ustawia kolejke powiadomień i wyświetla pierwsze z nich.
     * @see [server_notifications]{@link SocketServerEvents.server_notifications}
     */
    socket.on('server_notifications', (notifications) => {
        const { notificationsList, tabActive } = getState().app;

        /* if there is notification instead of an array of notifications */
        if (!Array.isArray(notifications)) {
            notifications = [notifications];
        }

        process.env.NODE_ENV === 'development' &&
            console.log('[server_notifications]:', notifications);

        if (tabActive) {
            batch(() => {
                /* merge new notification with existing list, */
                dispatch(actions.setNotifications(notifications));
                /* set new notification to show if there is no notifications queque */
                if (!notificationsList.length) {
                    dispatch(actions.setNotification(notifications[0]));
                }
            });
        }
    });

    /**
     * @category Sockets
     * @event server_cancel_loading
     * @memberof SocketServerEvents
     * @description Event wysyłany po obsłużeniu eventów które ustawiały stan ładowania czatu lub okien pojedyńczych czatów
     * @see [on_server_cancel_loading]{@link SocketEventsListeners.on_server_cancel_loading}
     */
    /**
     * @category Sockets
     * @function on_server_cancel_loading
     * @param {string} [roomID=null] id czatu
     * @listens server_cancel_loading
     * @memberof SocketEventsListeners
     * @description Wyłącza stan ładowania aplikacji i/lub okna czatu.
     * @see [server_cancel_loading]{@link SocketServerEvents.server_cancel_loading}
     */
    socket.on('server_cancel_loading', (roomID = null) => {
        const { loading } = getState().app;

        process.env.NODE_ENV === 'development' && console.log('[server_cancel_loading]:', roomID);

        batch(() => {
            if (roomID) {
                dispatch(actions.setChatWindowLoading(roomID, false));
            }

            if (loading) {
                dispatch(actions.setLoadingState(false));
            }
        });
    });

    /**
     * @category Sockets
     * @event server_active_chat
     * @memberof SocketServerEvents
     * @description Event wysyłany po otwarciu czatu przez którykolwiek z socketów użytkonika
     * @see [on_server_active_chat]{@link SocketEventsListeners.on_server_active_chat}
     */
    /**
     * @category Sockets
     * @function on_server_active_chat
     * @param {string} roomID id czatu
     * @listens server_active_chat
     * @memberof SocketEventsListeners
     * @description Dodaje id czatu do stanu activeChats.
     * @see [server_active_chat]{@link SocketServerEvents.server_active_chat}
     */
    socket.on('server_active_chat', (roomID) => {
        const isActive = getState().app.activeChats.indexOf(roomID) !== -1;
        process.env.NODE_ENV === 'development' && console.log('[server_active_chat]:', roomID);

        if (!isActive) {
            dispatch(actions.addActiveChat(roomID));
        }
    });

    /**
     * @category Sockets
     * @event server_active_chats
     * @memberof SocketServerEvents
     * @description Event wysyłany przy synchronizacji użytkownika i rozłączaniu któregoś z socketów użytkownika, przesyłą listę otwartych czatów.
     * @see [on_server_active_chats]{@link SocketEventsListeners.on_server_active_chats}
     */
    /**
     * @category Sockets
     * @function on_server_active_chats
     * @param {string[]} roomIDs list z id aktywnych czatów.
     * @listens server_active_chats
     * @memberof SocketEventsListeners
     * @description Ustawia stan activeChats.
     * @see [server_active_chats]{@link SocketServerEvents.server_active_chats}
     */
    socket.on('server_active_chats', (roomIDs) => {
        process.env.NODE_ENV === 'development' && console.log('[server_active_chats]:', roomIDs);
        dispatch(actions.setActiveChats(roomIDs));
    });

    /**
     * @category Sockets
     * @event server_unactive_chat
     * @memberof SocketServerEvents
     * @description Event wysyłany kiedy ostatni socket zamknie dany czat
     * @see [on_server_unactive_chat]{@link SocketEventsListeners.on_server_unactive_chat}
     */
    /**
     * @category Sockets
     * @function on_server_unactive_chat
     * @param {string} roomID id czatu
     * @listens server_unactive_chat
     * @memberof SocketEventsListeners
     * @description Usuwa ud czatu ze stanu activeChats.
     * @see [server_unactive_chat]{@link SocketServerEvents.server_unactive_chat}
     */
    socket.on('server_unactive_chat', (roomID) => {
        process.env.NODE_ENV === 'development' && console.log('[server_unactive_chat]:', roomID);
        dispatch(actions.removeActiveChat(roomID));
    });

    /**
     * @category Sockets
     * @event server_replace_temp_id
     * @memberof SocketServerEvents
     * @description Event wysyłany po stworzeniu czatu grupowego na serwerze
     * @see [on_server_replace_temp_id]{@link SocketEventsListeners.on_server_replace_temp_id}
     */
    /**
     * @category Sockets
     * @function on_server_replace_temp_id
     * @param {string} tempID stare id czatu
     * @param {string} roomID nowe id czatu
     * @listens server_replace_temp_id
     * @memberof SocketEventsListeners
     * @description Zamienia tymczasowe lokalne id czatu grupowego na przysłane z serwera.
     * @see [server_replace_temp_id]{@link SocketServerEvents.server_replace_temp_id}
     */
    socket.on('server_replace_temp_id', (tempID, roomID) => {
        batch(() => {
            dispatch(actions.replaceTempID(tempID, roomID));
            dispatch(actions.setActiveChatWindow(roomID));
        });
    });

    /**
     * @category Sockets
     * @event server_unread_messages
     * @memberof SocketServerEvents
     * @description Event wysyłany po synchronizacji użytkownika
     * @see [on_server_unread_messages]{@link SocketEventsListeners.on_server_unread_messages}
     */
    /**
     * @category Sockets
     * @function on_server_unread_messages
     * @param {object[]} list lista z obiektami nieodczytanych wiadomości dla poszczególnych czatów
     * @listens server_unread_messages
     * @memberof SocketEventsListeners
     * @description Ustawia stan z nieodczytanymi wiadomościami.
     * @see [server_unread_messages]{@link SocketServerEvents.server_unread_messages}
     */
    socket.on('server_unread_messages', (list) => {
        process.env.NODE_ENV === 'development' && console.log('server_unread_messages', list);
        dispatch(actions.setUnreadChats(list, true));
    });

    /**
     * @category Sockets
     * @event server_open_chat
     * @memberof SocketServerEvents
     * @description Event wysyłany po otwarciu lub stworzeniu nowego czatu
     * @see [on_server_open_chat]{@link SocketEventsListeners.on_server_open_chat}
     */

    /**
     * @category Sockets
     * @function on_server_open_chat
     * @param {object} chatInfo informacje o czacie wysłane przez serwer
     * @param {string} windowID identyfikator okna czatu
     * @listens server_open_chat
     * @memberof SocketEventsListeners
     * @description Tworzy nowy czat, przypisuje do niego windowID.
     * @see [server_open_chat]{@link SocketServerEvents.server_open_chat}
     */
    socket.on('server_open_chat', (chatInfo, windowID) => {
        process.env.NODE_ENV === 'development' &&
            console.log('[openchat] chatInfo:', chatInfo, 'windowID:', windowID);
        const chat = createChatObject(chatInfo, windowID);

        dispatch(actions.openChat(chat));
    });

    /**
     * @category Sockets
     * @function on_server_last_read
     * @param {string} roomID roomID czatu
     * @param {object[]} lastReadMessages identyfikator okna czatu
     * @listens server_last_read
     * @memberof SocketEventsListeners
     * @description dodaje do istniejącego czatu informacje o ostatnich przeczytanych wiadomościach użytkowników czatu.
     * @see [server_last_read]{@link SocketServerEvents.server_last_read}
     */
    socket.on('server_last_read', (roomID, lastReadMessages) => {
        process.env.NODE_ENV === 'development' &&
            console.log('[last_read] lastRead:', lastReadMessages);
        const clientID = getRoomID();
        // usuń klienta z tablicy ostatnich wiadomości, nie potrzebujesz tej informacji
        lastReadMessages = lastReadMessages.filter((lr) => lr.roomID !== clientID);
        dispatch(actions.setLastRead(roomID, lastReadMessages));
    });

    /**
     * @category Sockets
     * @function on_server_update_last_read
     * @param {string} chatRoomID - roomID czatu
     * @param {string} roomID - roomID użytkownika
     * @param {number|string} lastReadID id ostatnio przeczytanej wiadomości
     * @listens server_update_last_read
     * @memberof SocketEventsListeners
     * @description Uaktualnia ostatnią przeczytaną wiadomość jednego użytkownika wybranego czatu.
     * @see [server_update_last_read]{@link SocketServerEvents.server_update_last_read}
     */
    socket.on('server_update_last_read', (chatRoomID, roomID, lastReadID) => {
        process.env.NODE_ENV === 'development' &&
            console.log('updateLastRead:', chatRoomID, roomID);
        dispatch(actions.updateLastRead(chatRoomID, roomID, lastReadID));
    });

    /**
     * @category Sockets
     * @event server_load_messages
     * @memberof SocketServerEvents
     * @description Event wysyłany po otwarciu czatu lub doładowaniu wiadomości
     * @see [on_server_load_messages]{@link SocketEventsListeners.on_server_load_messages}
     */
    /**
     * @category Sockets
     * @function on_server_load_messages
     * @param {string} roomID identyfikator czatu
     * @param {object[]} messages wiadomości
     * @param {number|null} index id pierwszej wiadomości lub null kiedy nie ma więcej wiadomości do załadowania.
     * @param {number|null} [bottomOffset=null] informacja o stanie przeskrolowania okna czatu.
     * @listens server_load_messages
     * @memberof SocketEventsListeners
     * @description Dodaje wiadomości do czatu i ustawia bottomOffset.
     * @see [server_load_messages]{@link SocketServerEvents.server_load_messages}
     */
    socket.on('server_load_messages', (roomID, messages, index, bottomOffset = null) => {
        process.env.NODE_ENV === 'development' &&
            console.log('[server_load_messages] messages length', messages.length);

        dispatch(actions.setChatWindowLoading(roomID, false));
        batch(() => {
            bottomOffset && dispatch(actions.setBottomOffset(roomID, bottomOffset));
            dispatch(actions.prependChatsMessages(roomID, messages, index));
        });
    });

    /**
     * @category Sockets
     * @event server_tab_active
     * @memberof SocketServerEvents
     * @description Event wysyłany przy każdej czynności na serwerze, oznacza jako aktywny socket dla którego czynność została wykonana, a pozostałe sockety jako nieaktywne
     * @see [on_server_tab_active]{@link SocketEventsListeners.on_server_tab_active}
     */
    /**
     * @category Sockets
     * @function on_server_tab_active
     * @param {boolean} bool stan activeTab
     * @listens server_tab_active
     * @memberof SocketEventsListeners
     * @description Ustawia stan activeTab aplikacji.
     * @see [server_tab_active]{@link SocketServerEvents.server_tab_active}
     */
    socket.on('server_tab_active', (bool) => {
        process.env.NODE_ENV === 'development' && console.log('server_tab_active', bool);
        const {
            app: { tabActive, chatOpen }
        } = getState();

        if ((tabActive && !bool) || (!tabActive && bool)) {
            dispatch(actions.setTabActive(bool));
        }

        if (!bool && chatOpen) {
            batch(() => {
                dispatch(actions.setChatOpen(false));
                dispatch(actions.resetAppState());
            });
        }
    });

    /**
     * @category Sockets
     * @event server_message
     * @memberof SocketServerEvents
     * @description Event wysyłany jest po wysłaniu i przeprocesowaniu wiadomości
     * @see [on_server_message]{@link SocketEventsListeners.on_server_message}
     */
    /**
     * @category Sockets
     * @function on_server_message
     * @param {string} roomID identyfikator czatu
     * @param {object} message wiadomości
     * @listens server_message
     * @memberof SocketEventsListeners
     * @description Funkcja procesuje przesłaną wiadomość. Otwiera okno czatu, odtwarza dźwięk, oznacza jako przeczytaną lub nieprzeczytaną  itd, w zależności od stanu aplikacji. Wywołuje [client_open_chat]{@link SocketClientEvents.client_open_chat}
     * @see [server_message]{@link SocketServerEvents.server_message}
     */
    socket.on('server_message', (roomID, message) => {
        const {
            app: { busy, chatOpen, activeChats, tabActive, sounds, openWindows, activeWindow },
            chats,
            chatUser: { users, groups }
        } = getState();
        const myRoomID = getRoomID();
        const chatInfo = findChatInfoByRoomID(users, groups, roomID);
        const isMyMessage = message.roomID === myRoomID;
        const windowID = chatInfo.id;
        const chat = chats[roomID];
        const isChatActive = activeChats.indexOf(roomID) !== -1;
        const isWindowActive = windowID === activeWindow;
        const isWindowOpen = openWindows.indexOf(windowID) !== -1;

        if (process.env.NODE_ENV === 'development') {
            console.log('server_message [roomID]', roomID, message);
            console.log(
                'server_message [activeChats]',
                activeChats,
                '[tabActive]:',
                tabActive,
                '[isChatActive]:',
                isChatActive,
                '[isWindowActive]:',
                isWindowActive,
                '[isWindowOpen]:',
                isWindowOpen,
                '[windowID]:',
                windowID
            );
            console.log('server_message [chatInfo]', chatInfo);
        }

        /* update user or group last message */

        dispatch(actions.updateLastMessage(chatInfo, message.created_at));

        /* Display info about unread message in title if document not focused */
        !isMyMessage && flashHaveUnreadMessage();
        /* Play sound */
        if (
            sounds &&
            tabActive &&
            chatOpen &&
            !isMyMessage &&
            (!isWindowActive || chat?.scrolledUp || chat?.minimized || !document.hasFocus())
        ) {
            playSound(bellSound);
        }

        /* Open chat window */
        if (chatOpen && tabActive && !isWindowOpen && !busy) {
            dispatch(actions.openChatWindow(windowID));
        }

        /* Mark unread */
        if (!isChatActive) {
            dispatch(actions.setUnreadChats({ [roomID]: 1 }));
        }

        /* Load messages */
        if (chat && tabActive && chatOpen) {
            dispatch(actions.appendChatsMessage(roomID, message));
        }

        /* open new Chat */
        if (!chat && tabActive && chatOpen && !busy) {
            emitSocketEvent('open_chat', { roomID: chatInfo.roomID, windowID });
        }

        /* add info of a new message to read */
        if (
            !isMyMessage &&
            ((isWindowOpen && !isWindowActive) ||
                (chat && chat.scrolledUp && isWindowOpen && isWindowActive) ||
                (isWindowOpen && chat?.minimized))
        ) {
            dispatch(actions.setNewMessage(roomID, true));
        }

        /* send info that message is read */
        if (!isMyMessage && chat && isWindowOpen && isWindowActive) {
            emitSocketEvent('update_last_read', { roomID: chat.id });
        }
    });

    /**
     * @category Sockets
     * @event server_delete_message
     * @memberof SocketServerEvents
     * @description Event wysyłany jest po skasowaniu wiadomości na serwerze
     * @see [on_server_delete_message]{@link SocketEventsListeners.on_server_delete_message}
     */
    /**
     * @category Sockets
     * @function on_server_delete_message
     * @param {string} roomID identyfikator czatu
     * @param {object} message zmodyfikowana wiadomość
     * @listens server_delete_message
     * @memberof SocketEventsListeners
     * @description Funkcja zastępuje skasowaną wiadomość zmodyfikowaną wiadomością.
     * @see [server_delete_message]{@link SocketServerEvents.server_delete_message}
     */
    socket.on('server_delete_message', (roomID, message) => {
        const { chats } = getState();
        process.env.NODE_ENV === 'development' && console.log('server_delete_message');

        if (chats?.[roomID]?.messages) {
            dispatch(actions.replaceMessage(roomID, message));
        }
    });

    /**
     * @category Sockets
     * @event server_lock_chat
     * @memberof SocketServerEvents
     * @description Event wysyłany jest kiedy trzeba zablokować możliwość działania uzytkownika na konkretnym czacie
     * @see [on_server_lock_chat]{@link SocketEventsListeners.on_server_lock_chat}
     */
    /**
     * @category Sockets
     * @function on_server_lock_chat
     * @param {string} roomID identyfikator czatu
     * @listens server_lock_chat
     * @memberof SocketEventsListeners
     * @description Funkcja ustawia stan ładowania okna czatu.
     * @see [server_lock_chat]{@link SocketServerEvents.server_lock_chat}
     */
    socket.on('server_lock_chat', (roomID) => {
        const { chats } = getState();
        process.env.NODE_ENV === 'development' && console.log('server_lock_chat [roomID]', roomID);

        if (chats[roomID]) {
            dispatch(actions.setChatWindowLoading(roomID, true));
        }
    });

    /**
     * @category Sockets
     * @event server_unlock_chat
     * @memberof SocketServerEvents
     * @description Event wysyłany jest kiedy trzeba odblokować możliwość działania uzytkownika na konkretnym czacie
     * @see [on_server_unlock_chat]{@link SocketEventsListeners.on_server_unlock_chat}
     */
    /**
     * @category Sockets
     * @function on_server_unlock_chat
     * @param {string} roomID identyfikator czatu
     * @listens server_unlock_chat
     * @memberof SocketEventsListeners
     * @description Funkcja anuluje stan ładowania okna czatu.
     * @see [server_unlock_chat]{@link SocketServerEvents.server_unlock_chat}
     */
    socket.on('server_unlock_chat', (roomID) => {
        const { chats } = getState();
        process.env.NODE_ENV === 'development' &&
            console.log('server_unlock_chat [roomID]', roomID);

        if (chats[roomID]) {
            dispatch(actions.setChatWindowLoading(roomID, false));
        }
    });

    /**
     * @category Sockets
     * @event server_replace_messages_id
     * @memberof SocketServerEvents
     * @description Event wysyłany jest kiedy wiadomości trzymane tymczasowo w pamięci serwera zostają zapisane w bazie danych i dostają nowe id.
     * @see [on_server_replace_messages_id]{@link SocketEventsListeners.on_server_replace_messages_id}
     */
    /**
     * @category Sockets
     * @function on_server_replace_messages_id
     * @param {string} roomID identyfikator czatu
     * @param {object[]} idsArr tablica obiektów ze starymi i nowymi id wiadomości.
     * @listens server_replace_messages_id
     * @memberof SocketEventsListeners
     * @description Funkcja podmienia state id wiadomości na nowe.
     * @see [server_replace_messages_id]{@link SocketServerEvents.server_replace_messages_id}
     */
    socket.on('server_replace_messages_id', (roomID, idsArr) => {
        process.env.NODE_ENV === 'development' &&
            console.log('server_replace_messages_id [roomID]', roomID);
        dispatch(actions.replaceMessagesId(roomID, idsArr));
    });

    socket.on('test', () => console.log('test'));
};

/**
 * @async
 * @category Sockets
 * @function emitSocketEvent
 * @see [emit]{@link Socket.emit}
 * @description Funkcja wysyła
 * @param {string} type - nazwa eventu websocket do emisji.
 * @param {object} [data={}] - dane przesłane razem z eventem
 * @param {object} [options] - opcje
 * @param {boolean} [options.loading=true] - czy wysłanie eventu ma spowodować stan ładowania głównego okna systemu
 * @param {Function|null} [options.successAction=null] - funkcja do wykonania po pozytywnej odpowiedzi serwera
 * @param {Function|null} [options.errorAction=null] - funkcja do wykonania po zwróconym przez serwer błędzie
 * @param {Function|null} [options.startAction=null] - funkcja do wykonania przed wysłaniem eventu
 * @example
 * emitSocketEvent('synch', userData, { loading: true });
 * @example
 * 	const data = {
 *		file: {
 *			buffer: file,
 *			type: file.type,
 * 			name,
 *		},
 * 		type,
 *		chatID: chat.chatID
 *	};
 *
 *	emitSocketEvent('file', data, {
 *		successAction: () => setChatWindowLoading(roomID, false),
 *		errorAction
 *  });
 */
export const emitSocketEvent = (
    type,
    data = {},
    options = { loading: false, successAction: null, errorAction: null, startAction: null }
) => {
    const { loading, successAction, errorAction, startAction } = options;
    const event = 'client_' + type;

    if (process.env.NODE_ENV === 'development') {
        console.log(event);
    }

    if (loading) {
        dispatch(actions.setLoadingState(true));
    }

    if (startAction && typeof startAction === 'function') {
        startAction();
    }

    socket.emit(event, data, (error, resp) => {
        if (error) {
            if (errorAction && typeof errorAction === 'function') {
                return errorAction(error);
            }

            return dispatch(actions.setError(error));
        }

        if (loading) {
            dispatch(actions.setLoadingState(false));
        }

        if (successAction && typeof successAction === 'function') {
            successAction(resp);
        }
    });
};

export default socket;
