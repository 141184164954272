import React, { FC } from 'react';
import { Box, Container } from '@mui/material';
import { useTypedSelector } from 'src/store';

const LoginMiddleSectionWrapper: FC = ({ children }) => {
    const systemName = useTypedSelector((state) => state.system.systemName);
    const systemLogoSign = useTypedSelector((state) => state.system.systemLogoSign);

    return (
        <Container
            component="main"
            maxWidth="xs"
            sx={{
                filter: 'drop-shadow(0 0 10px rgb(200, 200, 200))',
                position: 'relative',
                zIndex: 1,
                background: 'white',
                padding: 3
            }}>
            <Box
                sx={{
                    marginTop: 1,
                    marginBottom: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}>
                <img
                    src={systemLogoSign}
                    alt={systemName ?? 'System logo'}
                    style={{
                        width: '5rem',
                        height: 'auto',
                        padding: '0.5rem',
                        objectFit: 'contain'
                    }}
                />
                {children}
            </Box>
        </Container>
    );
};

export default LoginMiddleSectionWrapper;
