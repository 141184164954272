import { useCallback, useMemo } from 'react';
import { getInMethodAdditionalYears } from 'src/api/src/budget/getInMethodAdditionalYears';
import { useSearchParam } from 'src/hooks/src/shared/useSearchParam';
import { useAppDispatch } from 'src/store';
import {
    setSettlementMethodVisibleId,
    setYearsBefore,
    toggleUnfoldingMonth,
    toggleUnfoldingYear,
    setIsLoading,
    setBudgetHandlerStateGlobalKind,
    prepareToUpdateData
} from 'src/store/src/budget';

import { ApiError } from 'src/utils';

export type UseUpdateStateFunctionsBudgetSetTab = (id: string) => void;

export type UseUpdateStateFunctionsBudget = {
    setTab: UseUpdateStateFunctionsBudgetSetTab;
    toggleVisibleMonth: (data: { yearId: string; monthId: string }) => void;
    toggleVisibleYear: (id: string) => void;
    getDataYearsBefore: (methodId: string) => void;
    updateAllBudgetData: () => void;
};

export const useUpdateStateFunctionsBudget = (): UseUpdateStateFunctionsBudget => {
    const obiekt = useSearchParam('obiekt');
    const id = useSearchParam('id');
    const dispatch = useAppDispatch();

    const setTab = useCallback(
        (id: string) => {
            dispatch(setSettlementMethodVisibleId(id));
        },
        [dispatch]
    );

    const toggleVisibleYear = useCallback(
        (id: string) => {
            dispatch(toggleUnfoldingYear(id));
        },
        [dispatch]
    );

    const toggleVisibleMonth = useCallback(
        (data: { yearId: string; monthId: string }) => {
            dispatch(toggleUnfoldingMonth(data));
        },
        [dispatch]
    );

    const getDataYearsBefore = useCallback(
        async (methodId: string) => {
            if (obiekt && id) {
                dispatch(setIsLoading(true));
                const apiResponse = await getInMethodAdditionalYears(obiekt, id, methodId);
                if (!apiResponse.success) {
                    ApiError.handleWithCloseSpinner({ dispatch, err: apiResponse });
                    throw new Error(
                        'fetchAndLoadInitialObjectData: not get InitialObjectScreenData '
                    );
                }
                console.log(apiResponse.data);
                dispatch(setYearsBefore(apiResponse.data));
                dispatch(
                    setBudgetHandlerStateGlobalKind({
                        kind: 'getYearsBefore',
                        methodId
                    })
                );
                dispatch(setIsLoading(false));
            }
        },
        [dispatch, id, obiekt]
    );

    const updateAllBudgetData = useCallback(() => {
        dispatch(prepareToUpdateData());
    }, [dispatch]);

    return {
        setTab,
        toggleVisibleMonth,
        toggleVisibleYear,
        getDataYearsBefore,
        updateAllBudgetData
    };
};
