import React, { useCallback } from 'react';

import { useAppDispatch, useTypedSelector } from 'src/store';
import { MassBillFormState } from 'src/hooks/src/massBill/useFormMassBill';
import { MassBillBehaviorData } from 'src/data/types';
import { MassBillApiFormDataCreator } from 'src/utils/src/massBill/MassBillApiFormDataCreator';
import { setProgress } from 'src/store/src/general';
import { setSpinner } from 'src/store/src/general/system/systemSlice';
import { FileManager } from 'src/utils';
import { setIsMounting } from 'src/store/src/massBill';

type UseSubmitMassBillProps = {
    actionKind: React.MutableRefObject<MassBillBehaviorData | null>;
};

export const useSubmitMassBill = (props: UseSubmitMassBillProps) => {
    const massBill = useTypedSelector((state) => state.massBill);

    const dispatch = useAppDispatch();

    function callback() {
        dispatch(setIsMounting());
    }
    return useCallback(async (dataState: MassBillFormState) => {
        console.log(dataState);
        const { formData } = new MassBillApiFormDataCreator({
            dataState,
            massBill,
            actionKind: props.actionKind
        }).execute();

        await FileManager.handleDownloadFile({
            url: 'api_react/src/massBill/saveMassBillData.php',
            formData,
            dispatch,
            setProgress,
            setSpinner,
            callback
        });
    }, []);
};
