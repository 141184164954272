import React, { FC } from 'react';
import ButtonCustom from 'src/components/shared/atoms/button/Button';
import { BudgetActionProps } from './types';
import { EventBus } from 'src/utils/src/shared/EventBus';

export const BudgetAction: FC<BudgetActionProps> = ({ children, kind }) => {
    const handleApiAction = async () => {
        EventBus.$emit('budgetAction', kind);
    };

    return (
        <ButtonCustom onClick={() => handleApiAction()} withoutStyles={true}>
            {children}
        </ButtonCustom>
    );
};
