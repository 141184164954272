import React from 'react';
import { useTheme } from '@mui/material/styles';
import { useWindowSize } from 'react-use-size';

import { useAppDispatch } from 'src/store';
import { closePopup, setPopup } from 'src/store/src/popup';
import PopupContentConfig from 'src/components/listScreen/organisms/popupContentConfig/PopupContentConfig';
import PopupContentDefault from 'src/components/shared/molecules/popupContentDefault/PopupContentDefault';
import { PopupState, PopupStateNormal } from 'src/store/src/popup/popup/types';
import { GlobalPopupProvider } from 'src/context/globalPopup/popupContext';
import { PopupContentConfirmApi } from 'src/components/shared/molecules/popupContentConfirmation/PopupContentConfirmationApi';
import { PopupContentInitialConfirmApi } from 'src/components/shared/molecules/popupContentConfirmation/PopupContentInitialConfirmationApi';
import { PopupContentConfirmLink } from 'src/components/shared/molecules/popupContentConfirmation/PopupContentConfirmationLink';
import { PopupContentInfo } from 'src/components/shared/molecules/popupContentInfo/PopupContentInfo';
import { PopupContentDynamic } from 'src/components/shared/molecules/popupContentDynamic/PopupContentDynamic';
import PopupContentSearch from 'src/components/listScreen/organisms/popupContentSearch/PopupContentSearch';
import { PopupContentAlert } from 'src/components/shared/molecules/popupContentAlert/PopupContentAlert';
import { PopupContentConfigOrder } from 'src/components/shared/molecules/popupContentConfigOrder/PopupContentConfigOrder';
import PopupContentPreview from 'src/components/listScreen/organisms/popupContentPreview/PopupContentPreview';
import { PopupContentWarning } from 'src/components/shared/molecules/popupContentWarning/PopupContentWarning';
import { PopupContentInfoTwo } from 'src/components/shared/molecules/popupContentInfoTwo/PopupContentInfoTwo';
import { CrossClose } from 'src/components/shared/atoms/crossClose/CrossClose';
import { usePressKey } from 'src/hooks/src/shared/usePressKey';
import styles from './GlobalPopup.module.css';
import global from 'src/styles/globalClass.module.css';
import { PopupContentDynamicExtended } from 'src/components/popupFormExtended/PopupContentDynamicExtended';
import PopupContentChangePassword from '../../molecules/popupContentChangePassword/PopupContentChangePassword';
import { PopupContentConfirmationClose } from 'src/components/shared/molecules/popupContentConfirmation/PopupContentConfirmationClose';
import { PopupContentPrompt } from '../../molecules/popupContentPrompt/PopupContentPrompt';
import { PopupContentProps, PopuptypesMapType } from './types';
import { InsteadOfNever } from 'src/data/types';
import { PopupContentStartupBlocksConfig } from 'src/components/startupscreen/organisms/popupContentBlockConfigVisibility/PopupContentStartupBlocksConfig';
import { EventBus } from 'src/utils/src/shared/EventBus';
import { PopupContentConfirmationTwo } from 'src/components/shared/molecules/popupContentConfirmationTwo/PopupContentConfirmationTwo';

export type { BasePopupContentProps } from './types';

const types: PopuptypesMapType = {
    config: PopupContentConfig,
    initialConfirmationApi: PopupContentInitialConfirmApi,
    confirmationApi: PopupContentConfirmApi,
    confirmationLink: PopupContentConfirmLink,
    confirmationClose: PopupContentConfirmationClose,
    confirmation: PopupContentConfirmationTwo,
    info: PopupContentInfo,
    infoTwo: PopupContentInfoTwo,
    configOrder: PopupContentConfigOrder,
    dynamic: PopupContentDynamic,
    dynamicExtended: PopupContentDynamicExtended,
    default: PopupContentDefault,
    search: PopupContentSearch,
    alert: PopupContentAlert,
    preview: PopupContentPreview,
    warning: PopupContentWarning,
    changePassword: PopupContentChangePassword,
    prompt: PopupContentPrompt,
    startupBlockConfig: PopupContentStartupBlocksConfig
};

const GlobalPopup = ({ popup }: { popup: PopupState }) => {
    const { height: heightWindow, width: widthWindow } = useWindowSize();
    const theme = useTheme();
    const dispatch = useAppDispatch();

    usePressKey(handleESC);

    const getPopupContent = <T extends PopupState['type']>(
        popupType: T
    ): React.FC<
        PopupContentProps<InsteadOfNever<Extract<PopupState, { type: T }>, PopupStateNormal>>
    > => {
        const a = types[popupType];
        return a;
    };
    const PopupContent = getPopupContent(popup.type);
    const isPossibleClose =
        popup.isPossibleClose && popup.type !== 'warning' && popup.type !== 'infoTwo';

    const close = (e: React.MouseEvent<HTMLElement>, cross: boolean) => {
        e.stopPropagation();
        e.preventDefault();
        if (isPossibleClose) {
            if (cross) {
                dispatch(closePopup(popup.key));
            } else {
                if (popup.type === 'dynamicExtended') {
                    dispatch(
                        setPopup({
                            type: 'confirmationClose',
                            popupSuperiorKey: popup.key
                        })
                    );
                } else {
                    dispatch(closePopup(popup.key));
                }
            }
            if (popup.closeCallbackEventId) {
                EventBus.$emit(popup.closeCallbackEventId);
            }
        }
    };

    function handleESC(e: KeyboardEvent) {
        if (isPossibleClose) {
            if (e.keyCode === 27) {
                dispatch(closePopup(popup.key));
                if (popup.closeCallbackEventId) {
                    EventBus.$emit(popup.closeCallbackEventId);
                }
            }
        }
    }

    React.useEffect(() => {
        function handleMouse(e: MouseEvent) {
            if (e.button === 3 || e.button === 4) {
                e.preventDefault();
            }
        }
        window.addEventListener('mouseup', handleMouse);
        return function cleanupListener() {
            window.removeEventListener('mouseup', handleMouse);
        };
    }, []);
    return (
        <div
            className={`${styles.wrapper} ${global.fontsizenormal}`}
            onMouseDown={(e) => close(e, false)}>
            <div
                style={{
                    border: `1px solid ${theme.palette.primary.main}`,
                    background: theme.palette.background.default
                }}
                className={styles.wrapperInner}
                onMouseDown={(e) => {
                    e.stopPropagation();
                }}>
                <GlobalPopupProvider popupState={popup}>
                    <PopupContent
                        popup={popup}
                        widthAvailable={widthWindow - 30}
                        heightAvailable={heightWindow - 30}
                    />
                </GlobalPopupProvider>
                {isPossibleClose && (
                    <div className={styles.boxClose} onClick={(e) => close(e, true)}>
                        <CrossClose
                            style={{
                                height: '10px',
                                right: '10px',
                                top: '10px'
                            }}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default GlobalPopup;
