import { MassBillFieldKeyParts } from 'src/hooks/src/massBill/useFormMassBill';

export function createKeyFieldMassBill({
    name,
    id,
    pole_pozycji,
    pole_ceny,
    pole_daty_wystawienia,
    pole_daty_sprzedazy
}: {
    name: string;
} & MassBillFieldKeyParts) {
    let newName = name;
    if (newName?.includes('{$it.id}') && id) {
        newName = newName?.replace('{$it.id}', id);
    }
    if (name?.includes('{$it.pole_pozycji}') && pole_pozycji) {
        newName = newName?.replace('{$it.pole_pozycji}', pole_pozycji);
    }
    if (newName?.includes('{$it.pole_ceny') && pole_ceny) {
        newName = newName?.replace('{$it.pole_ceny}', pole_ceny);
    }
    if (newName?.includes('{$it.pole_daty_wystawienia}') && pole_daty_wystawienia) {
        newName = newName?.replace('{$it.pole_daty_wystawienia}', pole_daty_wystawienia);
    }
    if (newName?.includes('{$it.pole_daty_sprzedazy}') && pole_daty_sprzedazy) {
        newName = newName?.replace('{$it.pole_daty_sprzedazy}', pole_daty_sprzedazy);
    }
    return newName;
}
