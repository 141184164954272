import React, { useEffect } from 'react';
import { updatePageTitleWithScreenName } from 'src/store/src/general';
import { useHandleSystemLock, useUpdateUserInfo } from 'src/hooks';
import LoginView from '../../templates/loginView/LoginView';
import { getState } from 'src/store';
import { useNavigate } from 'react-router-dom';
import { navigateWithTranslate } from 'src/utils';

export const LoginScreen = () => {
    updatePageTitleWithScreenName('Logowanie');
    useHandleSystemLock();

    const navigate = useNavigate();
    const updateUserInfo = useUpdateUserInfo();

    // check if user is logged, when is logged navigate to proper system for logged users
    useEffect(() => {
        const checkUserLogin = async () => {
            await updateUserInfo();
            if (getState().user.isLogged) {
                // clear focus from autofocused form input
                (document.activeElement as HTMLElement).blur();

                navigateWithTranslate(navigate, '/start');
            } else if (getState().user.loginStage2fa) {
                navigateWithTranslate(navigate, '/2fa');
            }
        };

        checkUserLogin();
    }, [updateUserInfo, navigate]);

    return <LoginView />;
};
