import React, { FC, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import UtilitiesBarOption from 'src/components/listScreen/molecules/utilityBarOption/UtilityBarOption';
import { DeleteSearchOptionCallbackType, UtilitiesBarProps } from './types';
import { useSearchParam } from 'src/hooks';
import { useAppDispatch, useTypedSelector } from 'src/store';
import { deleteObject } from 'src/api/src/shared/deleteObject';
import styles from './UtilitiesBar.module.css';
import {
    fetchAndLoadUtilitiesBarActions,
    setActions
} from 'src/store/src/utilitiesBar/utilitiesBar/utilitiesbarSlice';
import { UtilitiesBarOptionsInThreeDots } from 'src/components/listScreen/organisms/utilitiesBarOptionsInThreeDots/UtilitiesBarOptionsInThreeDots';
import { SystemAction } from 'src/data/types';

/**
 * Describes main menu, handle all mechanics related to menu data
 */

const UtilitiesBar: FC<UtilitiesBarProps> = ({ pageType, listInternalAction }) => {
    const screen = useSearchParam('obiekt');
    const objectId = useSearchParam('id');
    const actions = useTypedSelector((state) => state.utilitiesbar.actions);
    const loading = useTypedSelector((state) => state.utilitiesbar.loading);

    const dispatch = useAppDispatch();
    // load dynamic options for screen asyncronously
    const optionToRenders = React.useMemo(() => {
        return [...listInternalAction, ...actions];
    }, [listInternalAction, actions]);

    const optiomToRendersDiveded = React.useMemo(() => {
        const notThreeDots: SystemAction[] = [];
        const inThreeDots: SystemAction[] = [];
        optionToRenders.forEach((item) => {
            if (item.isInThreeDots) {
                inThreeDots.push(item);
            } else notThreeDots.push(item);
        });
        return { notThreeDots, inThreeDots };
    }, [optionToRenders]);
    useEffect(() => {
        const loadDynamicOptions = async () => {
            if (screen && pageType !== 'ekran_obiektu') {
                dispatch(fetchAndLoadUtilitiesBarActions(screen, pageType, objectId ?? undefined));
            } else {
                dispatch(setActions([]));
            }
        };

        loadDynamicOptions();
        // options state nie może być w dependencies bo powoduje nie potrzebne podwójne zapytanie do api o opcje
        // options state is not by in dependencies because is call two request to api (one neddless)
    }, [screen, pageType, dispatch, objectId]);

    const deleteSearchOption: DeleteSearchOptionCallbackType = async (
        _e: React.BaseSyntheticEvent,
        optionId: number
    ) => {
        _e.stopPropagation();

        if (!screen) {
            throw new Error(
                'UtilitiesBar: Try to delete search option without screen("obiekt") search param'
            );
        }

        // try delete option in api
        const apiResponse = await deleteObject('zapisane_wyszukania', { objectIds: [optionId] });

        //if errors occured, restore start value
        if (apiResponse.success) {
            dispatch(fetchAndLoadUtilitiesBarActions(screen, pageType, objectId ?? undefined));
        }
    };

    if (loading) return null;

    return (
        <div className={styles.wrapper}>
            {optiomToRendersDiveded.notThreeDots.map((item) => {
                return (
                    <UtilitiesBarOption
                        key={item.code != '' ? item.code : item.name}
                        action={item}
                        deleteSearchOptionCallback={deleteSearchOption}
                        pageType={pageType}
                        typeDisplay="vertical"
                    />
                );
            })}
            {optiomToRendersDiveded.inThreeDots.length ? (
                <UtilitiesBarOptionsInThreeDots
                    actions={optiomToRendersDiveded.inThreeDots}
                    deleteSearchOptionCallback={deleteSearchOption}
                    pageType={pageType}
                />
            ) : null}
        </div>
    );
};

export default UtilitiesBar;
