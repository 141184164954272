import { FieldPriceBasicTypeValue } from 'src/data/fieldsFormType';
import {
    MassBillGetValuesFn,
    MassBillFormStateFields,
    MassBillSetValueFn,
    MassBillFieldTypes
} from 'src/hooks/src/massBill/useFormMassBill';

type SetValueMassBillPropsRHF = {
    getValues: MassBillGetValuesFn;
    setValue: MassBillSetValueFn;
};

export type SetValueFnMassBillPropsRHF = {
    keyField: string;
};

type SetValuePriceFnMassBillPropsRHF = {
    fieldType: MassBillFieldTypes;
    value: FieldPriceBasicTypeValue;
};

type SetValueStringFnMassBillPropsRHF = {
    fieldType: MassBillFieldTypes;
    value: string;
};

type SetValueDateFnMassBillPropsRHF = {
    fieldType: MassBillFieldTypes;
    value: Date | null;
};

type SetValueCheckboxFnBudgetPropsRHF = {
    fieldType: MassBillFieldTypes;
    value: boolean;
};

export class MassBillSetValueHandlerRHF {
    getValues: MassBillGetValuesFn;
    setValue: MassBillSetValueFn;
    field: MassBillFormStateFields;
    keyField: string;
    constructor(data: SetValueMassBillPropsRHF) {
        this.getValues = data.getValues;
        this.setValue = data.setValue;
        this.field = {};
        this.keyField = '.';
    }

    run({ keyField }: SetValueFnMassBillPropsRHF) {
        this.field = this.getValues(keyField);
        this.keyField = keyField;
        return this;
    }

    setString({ fieldType, value }: SetValueStringFnMassBillPropsRHF) {
        switch (fieldType) {
            case 'tekst_bez_ograniczen': {
                this.setValue(`${this.keyField}.tekst_bez_ograniczen`, value);
                break;
            }
            case 'tekst': {
                this.setValue(`${this.keyField}.tekst`, value);
                break;
            }
        }
    }
    setPrice({ fieldType, value }: SetValuePriceFnMassBillPropsRHF) {
        switch (fieldType) {
            case 'cena': {
                this.setValue(`${this.keyField}.cena`, value);
                break;
            }
        }
    }
    setDate({ fieldType, value }: SetValueDateFnMassBillPropsRHF) {
        switch (fieldType) {
            case 'data': {
                this.setValue(`${this.keyField}.data`, value);
                break;
            }
        }
    }

    setBoolean({ fieldType, value }: SetValueCheckboxFnBudgetPropsRHF) {
        switch (fieldType) {
            case 'checkbox': {
                this.setValue(`${this.keyField}.checkbox`, value);
                break;
            }
        }
    }
}
