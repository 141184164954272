import React, { FC } from 'react';
import { useRunAllHooksOnEachRenderReactPage } from 'src/hooks';

const MassBillView = React.lazy(
    () =>
        import(
            /* webpackChunkName: "MassBillView" */ 'src/components/massBill/templates/massBillView/MassBillView'
        )
);

export const MassBillMain: FC = () => {
    useRunAllHooksOnEachRenderReactPage('Masowe fakturowanie');

    return (
        <div>
            <React.Suspense fallback>
                <MassBillView />
            </React.Suspense>
        </div>
    );
};
