import React, { useCallback, useEffect } from 'react';
import { getQrCodeData } from 'src/api/src/qrCodeScreen/getQrCodeData';
import { saveQrCodeSecret } from 'src/api/src/qrCodeScreen/saveQrCodeSecret';
import { QrCodeScreenData } from 'src/api/src/qrCodeScreen/types';
import { useSearchParam } from 'src/hooks';
import QrCodeView from '../../templates/qrCodeView/QrCodeView';
import { useNavigate } from 'react-router-dom';
import { setSpinner } from 'src/store/src/general/system/systemSlice';
import { useAppDispatch } from 'src/store';

const QrCodeScreen = () => {
    const [qrCodeScreenState, setQrCodeScreenState] = React.useState<QrCodeScreenData | null>(null);

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const userID = useSearchParam('id_uzytkownika');
    const userType = useSearchParam('typ_uzytkownika');

    // load data for screen
    useEffect(() => {
        const loadData = async () => {
            console.log('getQrCodeData', userID, userType);
            const res = await getQrCodeData(userID ?? '', userType ?? '');
            if (!res.success) {
                navigate(`/message?tytul=${res.message}`, { replace: true });
                return;
            }
            setQrCodeScreenState(res.data);
        };
        loadData();
    }, []);

    const saveCallback = useCallback(async () => {
        if (!qrCodeScreenState || !userID || !userType) {
            navigate('/message?tytul=brak wartości parametrów id_uzytkownika lub typ_uzytkownika', {
                replace: true
            });
            return;
        }

        dispatch(setSpinner(true));
        const response = await saveQrCodeSecret(userID, userType, qrCodeScreenState.secret);
        if (!response.success) {
            dispatch(setSpinner(false));
            navigate(`/message?tytul=${response.message}`, { replace: true });
            return;
        }

        dispatch(setSpinner(false));

        navigate(`/obiekt?id=${userID}&obiekt=${userType}`, { replace: true });
        window.location.reload();
    }, [qrCodeScreenState, userID, userType, navigate, dispatch]);

    return (
        <QrCodeView base64QrCode={qrCodeScreenState?.base64QrCode} saveCallback={saveCallback} />
    );
};

export default QrCodeScreen;
