import React, { useCallback } from 'react';
import { getInBudgetPopupFormExtendedData } from 'src/api/src/budget/getInBudgetPopupFormExtendedData';

import { BudgetFormStateRHF } from 'src/hooks/src/budget/useFormBudget';
import { useOpenPopupFormExtended, useRunConfirmationPopup, useSearchParam } from 'src/hooks';
import { dispatch, getState } from 'src/store';
import { FileManager, createUrlWithParams, generateToken } from 'src/utils';
import { BudgetBehaviorDataKind } from 'src/data/types';
import { BudgetApiFormDataCreator } from 'src/utils/src/budget/BudgetApiFormDataCreator';
import { createBudgetAction } from 'src/utils/src/budget/createBudgetAction';
import { setSpinner } from 'src/store/src/general/system/systemSlice';
import { prepareToUpdateData } from 'src/store/src/budget';
import { setProgress } from 'src/store/src/general';
import { PopupManager } from 'src/store/src/popup/popup/PopupManager';

type UseSubmitBudgetProps = {
    actionKind: React.MutableRefObject<BudgetBehaviorDataKind | null>;
};

export const useSubmitBudget = (props: UseSubmitBudgetProps) => {
    const openPopupformExtended = useOpenPopupFormExtended();
    const confirm = useRunConfirmationPopup();
    const obiekt = useSearchParam('obiekt');
    const id = useSearchParam('id');

    return useCallback(
        async (dataState: BudgetFormStateRHF) => {
            console.log(dataState);
            const paramsStr = createParamsStr(props);
            const { formData, cost } = new BudgetApiFormDataCreator(
                getState().budget,
                dataState
            ).createApiData();

            let continueRunnig: boolean | null = true;

            if (
                getState().budget.regenerateInvoiceFileReminder &&
                props.actionKind.current === 'zapiszzmiany'
            ) {
                await new PopupManager({ dispatch, action: null }).awaitedSetPopupInfoTwo({
                    title: 'Uwaga',
                    message:
                        'Przypominamy o konieczności ponownego wygenerowania druku danego obiektu księgowego po jego modyfikacji',
                    closeCallbackEventId: 'prompt-budget'
                });
            }

            if (props.actionKind.current === 'notyksiegowe') {
                continueRunnig = await confirm({
                    title: 'Potwierdzenie',
                    message: generateMessage(cost)
                });
            }
            if (continueRunnig) {
                const url = createUrlWithParams({
                    url: 'api_react/src/budget/doBudgetOperations.php',
                    arrParams: [
                        ['obiekt', obiekt ?? ''],
                        ['id', id ?? ''],
                        ['waluta_kosztow', cost ?? 'PLN']
                    ],
                    strParams: paramsStr
                });

                switch (props.actionKind.current) {
                    case 'raport':
                        await FileManager.handleDownloadFile({
                            url,
                            formData,
                            dispatch,
                            setProgress,
                            setSpinner
                        });
                        break;
                    case 'przygotujfaktury':
                    case 'faktury':
                    case 'notyksiegowe':
                    case 'proformy':
                    case 'paragony':
                    case 'notawewnetrzna':
                        {
                            dispatch(setSpinner(true));
                            const actionData = await getInBudgetPopupFormExtendedData(
                                url,
                                formData
                            );
                            if (actionData.success) {
                                const actionNew = createBudgetAction({
                                    params: actionData.data.params,
                                    actionKind: props.actionKind
                                });
                                const closeCallbackEventId = `popupformExtend-${generateToken()}`;
                                openPopupformExtended(actionNew, closeCallbackEventId);
                            }
                        }
                        break;
                    case 'nierozliczaj':
                    case 'zapiszzmiany':
                        {
                            dispatch(setSpinner(true));
                            const actionData = await getInBudgetPopupFormExtendedData(
                                url,
                                formData
                            );
                            dispatch(setSpinner(false));
                            if (actionData.success) {
                                dispatch(prepareToUpdateData());
                            }
                        }
                        break;
                    default:
                        dispatch(setSpinner(false));
                        break;
                }
            }
        },
        [dispatch, obiekt, id]
    );
};

function generateMessage(text?: unknown) {
    const txtToReplace = typeof text === 'string' ? text : '';
    return 'Uwaga do noty księgowej przenoszą się jedynie koszty! Jeżeli wybrano koszty w różnych walutach to przeniosą się jedynie koszty w walucie WALUTA (waluta pierwszego wybranego kosztu)'.replace(
        'WALUTA',
        txtToReplace
    );
}

function createParamsStr(data: UseSubmitBudgetProps) {
    const paramsWindow = new URLSearchParams(window.location.search);
    let params = '';
    switch (data.actionKind.current) {
        case 'faktury':
            params = 'faktura=FW';
            break;
        case 'raport':
            params = 'raport=1';
            break;
        case 'nierozliczaj':
            params = 'nierozliczaj=1';
            break;
        case 'przygotujfaktury':
            params = 'faktura=F';
            break;
        case 'paragony':
            params = 'faktura=P';
            break;
        case 'proformy':
            params = 'faktura=PRO';
            break;
        case 'notyksiegowe':
            params = 'faktura=N';
            break;
        case 'notawewnetrzna':
            params = 'faktura=W';
            break;
        default:
            params = '';
            break;
    }
    if (paramsWindow.get('id_faktury')) {
        if (params) {
            params += `&id_faktury=${paramsWindow.get('id_faktury')}`;
        } else {
            params = `id_faktury=${paramsWindow.get('id_faktury')}`;
        }
    }
    return params;
}
