import React, { useState } from 'react';
import {
    Button,
    TextField,
    Box,
    Alert,
    OutlinedInput,
    InputLabel,
    FormControl
} from '@mui/material';
import { loginUser } from 'src/api/src/loginScreen/loginUser';
import { useNavigate } from 'react-router-dom';
import { useSearchParam, useUpdateUserInfo } from 'src/hooks';
import { navigateWithTranslate } from 'src/utils';
import PasswordEndAdornmentIcon from 'src/components/popupFormExtended/dynamicField/shared/passwordEndAdornmentIcon/PasswordEndAdornmentIcon';

export const LoginForm = () => {
    const navigate = useNavigate();
    const messageText = useSearchParam('message');
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [errorText, setErrorText] = useState<string | null>(messageText);
    const [showPassword, setShowPassword] = React.useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const updateUserInfo = useUpdateUserInfo();

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        setLoading(true);
        e.preventDefault();

        const data = await loginUser(login, password);

        if (!data.success) {
            setErrorText(data.message);
            setPassword('');
            setLoading(false);
            return;
        }

        if (data.data.navigateTo2fa) {
            navigateWithTranslate(navigate, '/2fa');
            return;
        }

        await updateUserInfo();
        navigateWithTranslate(navigate, '/start');
    };

    const commonTextFieldProps = { style: { fontSize: 14 } };

    return (
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            {errorText && (
                <Alert severity="error" sx={{ mt: 2, width: '100%' }}>
                    {errorText}
                </Alert>
            )}
            <TextField
                margin="normal"
                required
                fullWidth
                id="login"
                label="Login"
                name="login"
                autoComplete="login"
                autoFocus
                inputProps={commonTextFieldProps}
                InputLabelProps={commonTextFieldProps}
                value={login}
                onChange={(e) => setLogin(e.target.value)}
            />
            <FormControl required fullWidth variant="outlined" margin="normal">
                <InputLabel htmlFor="password" {...commonTextFieldProps}>
                    Hasło
                </InputLabel>
                <OutlinedInput
                    name="password"
                    label="Hasło"
                    id="password"
                    value={password}
                    inputProps={commonTextFieldProps}
                    onChange={(e) => setPassword(e.target.value)}
                    type={showPassword ? 'text' : 'password'}
                    endAdornment={
                        <PasswordEndAdornmentIcon
                            handleClick={handleClickShowPassword}
                            showPassword={showPassword}
                        />
                    }
                />
            </FormControl>
            <Button
                type="submit"
                fullWidth
                variant="contained"
                disabled={loading}
                sx={{ mt: 3, mb: 2 }}>
                Zaloguj się
            </Button>
        </Box>
    );
};
