import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ApiBudgetData } from 'src/api/src/budget/types';
import { CalcFnProps } from 'src/hooks/src/budget/useFormBudget';
import {
    BudgetScreenState,
    BugdetMonthLevel,
    BudgetHandlerStateGlobalKind
} from 'src/store/src/budget/budget/types';
import {
    BudgetHandlerStateGlobal,
    BudgetHandlerStateGlobalFnExecuteCallPlacement,
    BudgetHandlerStateGlobalProps
} from 'src/utils/src/budget/BudgetHandlerStateGlobal';
import { AppThunk } from '../../../index';

const initData: BudgetScreenState = {
    settlementMethods: [],
    settledObjectsStructures: {
        zadania: {
            id: '',
            name: '',
            fields: []
        },
        kontakty: {
            id: '',
            name: '',
            fields: []
        },
        kontakty_ryczalty: {
            id: '',
            name: '',
            fields: []
        },
        rozprawy: {
            id: '',
            name: '',
            fields: []
        },
        rozprawy_ryczalty: {
            id: '',
            name: '',
            fields: []
        },
        koszty: {
            id: '',
            name: '',
            fields: []
        },
        successfees: {
            id: '',
            name: '',
            fields: []
        },
        kosztyzastepstwa: {
            id: '',
            name: '',
            fields: []
        },
        oplatywstepne_za_sprawy: {
            id: '',
            name: '',
            fields: []
        },
        ryczalty_za_sprawy: {
            id: '',
            name: '',
            fields: []
        },
        dokumenty: {
            id: '',
            name: '',
            fields: []
        },
        dokumenty_kontrakt: {
            id: '',
            name: '',
            fields: []
        },
        ryczalt_miesiac: {
            id: '',
            name: '',
            fields: []
        },
        ryczalt: {
            id: '',
            name: '',
            fields: []
        },
        limit_godzin: {
            id: '',
            name: '',
            fields: []
        },
        fakturowaneelicencje: {
            id: '',
            name: '',
            fields: []
        },
        etapyprojektow: {
            id: '',
            name: '',
            fields: []
        },
        koszty_projektow: {
            id: '',
            name: '',
            fields: []
        }
    },
    internalNotesExists: false,
    employees: {},
    employeesRateCategories: {},
    invoiceRate: '1',
    invoiceCurrency: 'PLN',
    isLoading: true,
    isMounting: true,
    isFirstLoad: true,
    isLoadedBeforeYears: false,
    settlementMethodVisibleId: '',
    settlementMethodDescriptionTitle: 'Opis ze sposobu rozliczeń',
    showTimeApproved: false,
    paramsAddObj: {},
    budgetHandlerStateGlobalKind: {
        kind: 'default'
    },
    regenerateInvoiceFileReminder: false
};

export const initialState: BudgetScreenState = {
    ...initData
};

const budgetSlice = createSlice({
    name: 'budget',
    initialState,
    reducers: {
        setBudgetHandlerStateGlobalKind: (
            state: BudgetScreenState,
            action: PayloadAction<BudgetHandlerStateGlobalKind>
        ) => {
            state.budgetHandlerStateGlobalKind = action.payload;
        },
        setInitBudgetData: (state: BudgetScreenState, action: PayloadAction<ApiBudgetData>) => {
            const { settlementMethodVisibleId } = changeApiData(action.payload, state);
            state.employeesRateCategories = action.payload.employeesRateCategories;
            state.employees = action.payload.employees;
            state.settledObjectsStructures = action.payload.settledObjectsStructures;
            state.settlementMethods = action.payload.settlementMethods;
            state.invoiceRate = action.payload.invoiceRate;
            state.invoiceCurrency = action.payload.invoiceCurrency;
            state.showTimeApproved = action.payload.showTimeApproved;
            state.isLoading = false;
            state.isMounting = false;
            state.isFirstLoad = false;
            state.settlementMethodVisibleId = settlementMethodVisibleId;
            state.paramsAddObj = action.payload.paramsAddObj;
            state.internalNotesExists = action.payload.internalNotesExists;
            state.regenerateInvoiceFileReminder = action.payload.regenerateInvoiceFileReminder;
        },
        updateBudgetData: (state: BudgetScreenState, action: PayloadAction<ApiBudgetData>) => {
            state.employeesRateCategories = action.payload.employeesRateCategories;
            state.employees = action.payload.employees;
            state.settledObjectsStructures = action.payload.settledObjectsStructures;
            state.settlementMethods = action.payload.settlementMethods;
            state.invoiceRate = action.payload.invoiceRate;
            state.invoiceCurrency = action.payload.invoiceCurrency;
            state.showTimeApproved = action.payload.showTimeApproved;
            state.isLoading = false;
            state.paramsAddObj = action.payload.paramsAddObj;
            state.internalNotesExists = action.payload.internalNotesExists;
            state.regenerateInvoiceFileReminder = action.payload.regenerateInvoiceFileReminder;
        },
        setIsLoading: (state: BudgetScreenState, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setYearsBefore: (state: BudgetScreenState, action: PayloadAction<BugdetMonthLevel[]>) => {
            if (Array.isArray(action.payload)) {
                state.settlementMethods = state.settlementMethods.map((method) => {
                    if (method.id === state.settlementMethodVisibleId) {
                        return {
                            ...method,
                            years: method.years.map((year) => {
                                if (year.name?.includes('lata poprzednie')) {
                                    return {
                                        ...year,
                                        months: action.payload
                                    };
                                } else return year;
                            })
                        };
                    }
                    return method;
                });
                state.isLoadedBeforeYears = true;
            }
        },
        setSettlementMethodVisibleId: (state: BudgetScreenState, action: PayloadAction<string>) => {
            state.settlementMethodVisibleId = action.payload;
        },
        toggleUnfoldingYear: (state: BudgetScreenState, action: PayloadAction<string>) => {
            state.settlementMethods = state.settlementMethods.map((method) => {
                if (method.id === state.settlementMethodVisibleId) {
                    return {
                        ...method,
                        years: method.years.map((year) => {
                            if (year.id === action.payload) {
                                return {
                                    ...year,
                                    folded: !year.folded
                                };
                            }
                            return year;
                        })
                    };
                }
                return method;
            });
        },
        toggleUnfoldingMonth: (
            state: BudgetScreenState,
            action: PayloadAction<{ yearId: string; monthId: string }>
        ) => {
            state.settlementMethods = state.settlementMethods.map((method) => {
                if (method.id === state.settlementMethodVisibleId) {
                    return {
                        ...method,
                        years: method.years.map((year) => {
                            if (year.id === action.payload.yearId) {
                                return {
                                    ...year,
                                    months: year?.months?.map((month) => {
                                        if (month.id === action.payload.monthId) {
                                            return {
                                                ...month,
                                                folded: !month.folded
                                            };
                                        }
                                        return month;
                                    })
                                };
                            }
                            return year;
                        })
                    };
                }
                return method;
            });
        },

        prepareToUpdateData: (state: BudgetScreenState) => {
            state.isLoading = true;
            state.isMounting = true;
        },

        prepareToMount: (state: BudgetScreenState) => {
            state.isLoading = true;
            state.isMounting = true;
            state.isFirstLoad = true;
            state.isLoadedBeforeYears = false;
        }
    }
});

export const {
    setBudgetHandlerStateGlobalKind,
    setInitBudgetData,
    setIsLoading,
    setSettlementMethodVisibleId,
    toggleUnfoldingYear,
    setYearsBefore,
    toggleUnfoldingMonth,
    updateBudgetData,
    prepareToMount,
    prepareToUpdateData
} = budgetSlice.actions;

export default budgetSlice.reducer;

function changeApiData(data: ApiBudgetData, state: BudgetScreenState) {
    return {
        settlementMethodVisibleId: setInitSettlementMethodVisibleId(data, state)
    };
}

function setInitSettlementMethodVisibleId(data: ApiBudgetData, state: BudgetScreenState) {
    if (state.isFirstLoad) {
        return data.settlementMethodVisibleId ?? data?.settlementMethods?.[0]?.id;
    }
    return state.settlementMethodVisibleId;
}

/* thunks */

export const doFormBudgetCalc =
    ({
        getValues,
        reset,
        setValue,
        data
    }: Omit<BudgetHandlerStateGlobalProps, 'budget' | 'dispatch'> & {
        data: CalcFnProps;
    }): AppThunk =>
    async (dispatch, getState) =>
        new BudgetHandlerStateGlobal({
            getValues,
            reset,
            budget: getState().budget,
            setValue,
            dispatch
        }).execute(handlePipelineData(data));

function handlePipelineData(data: CalcFnProps) {
    const callPlacement = data.callPlacement
        ? data.callPlacement
        : manageCallPlacement(data.keyField);
    return {
        ...data,
        callPlacement
    };
}

function manageCallPlacement(keyField: string): BudgetHandlerStateGlobalFnExecuteCallPlacement {
    if (keyField.includes('pracowniknafakture')) {
        return 'workerOnBill';
    } else return 'default';
}
