import { useEffect, useState, useRef, RefObject } from 'react';

export function useIsShowedOnce(ref: RefObject<Element>) {
    const observerRef = useRef<IntersectionObserver | null>(null);

    const [isShowed, setIsShowed] = useState(false);

    useEffect(() => {
        observerRef.current = new IntersectionObserver(([entry]) => {
            if (entry.isIntersecting) {
                setIsShowed(true);
            }
        });
    }, []);

    useEffect(() => {
        if (ref.current) {
            observerRef?.current?.observe(ref.current);
        }

        return () => {
            observerRef?.current?.disconnect();
        };
    }, [ref]);

    return isShowed;
}
