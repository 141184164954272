import { useTypedSelector } from 'src/store';

export const useGetMassBillParams = () => {
    const params = useTypedSelector((state) => state.massBill.params);

    return {
        ...params,
        grupowanie: params?.grupowanie === '1',
        pokaz_pracownikow: params?.pokaz_pracownikow === '1',
        grupowanie_tylko_po_kontraktach: params?.grupowanie_tylko_po_kontraktach === '1',
        aktualizacja: params?.aktualizacja === '1'
    };
};
