import { useTheme } from '@mui/material/styles';
import { useGetMassBillParams } from 'src/hooks/src/massBill/useGetMassBillParams';
import { MassBillLayout } from 'src/store/src/massBill/massBill/types';

export type UseRowColorInMassBillProps = MassBillLayout;

export function useRowColorInMassBill({ placement, itemNumber }: UseRowColorInMassBillProps) {
    const theme = useTheme();
    const { grupowanie_tylko_po_kontraktach } = useGetMassBillParams();
    let backgroundColor = theme.palette.gray.first;

    switch (placement) {
        case 'client':
        case 'caseItem':
            backgroundColor = theme.palette.white.main;
            break;
        case 'contract':
            backgroundColor = theme.palette.gray.first;
            break;
        case 'position':
            backgroundColor = grupowanie_tylko_po_kontraktach
                ? theme.palette.white.main
                : theme.palette.gray.first;
            break;
        case 'positionNotGroup':
            if (typeof itemNumber === 'number') {
                backgroundColor =
                    itemNumber % 2 === 0 ? theme.palette.gray.first : theme.palette.white.main;
            }
            break;
    }

    return backgroundColor;
}
