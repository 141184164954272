import { BudgetOperationalState, ObjectToCalcLimitItem } from 'src/hooks/src/budget/useFormBudget';
import {
    BugdetMonthLevel,
    BugdetSettlementMethodLevel,
    SettleObject,
    SettledObjectsCodes
} from 'src/store/src/budget/budget/types';
import { BudgetSetterOperationalState } from 'src/utils/src/budget/BudgetSetterOperationalState';
import { BudgetSetterStateGlobalProps } from 'src/utils/src/budget/BudgetSetterStateGlobal';
import { CreateKeySumSharedProps } from 'src/utils/src/budget/createKeySumBudget';
import { checkTimestampIsInMonth } from 'src/utils/src/shared/checkTimestampIsInMonth';

type BudgetHandlerObjectsToCalcLimitStakeProps = BudgetSetterStateGlobalProps & {
    operationalState: BudgetOperationalState;
};

type ExecuteFnProps = CreateKeySumSharedProps & {
    object: SettleObject;
    method: BugdetSettlementMethodLevel;
    code: SettledObjectsCodes;
    month?: BugdetMonthLevel;
};

const possibleObjects: SettledObjectsCodes[] = ['zadania', 'kontakty', 'rozprawy'];

export class BudgetHandlerObjectsToCalcLimitStake extends BudgetSetterOperationalState {
    constructor({ budget, operationalState }: BudgetHandlerObjectsToCalcLimitStakeProps) {
        super({ budget, operationalState });
    }

    execute(data: ExecuteFnProps) {
        if (possibleObjects.includes(data.code)) {
            this.setToOSStructure(data.code);
            this.setToOSFieldsStructure({
                settled: data.object.settled,
                code: data.code,
                settledInThisSettlingObject:
                    data.object.settled && data.object.settledInThisSettlingObject
            });
            let specialstake = false;
            const obj: ObjectToCalcLimitItem = {
                objectId: data.object.id,
                hours: 0,
                timestamp: 0,
                stake: 0
            };

            this.operationalState.fieldsStructure.forEach((field) => {
                if (!field.onlyText) {
                    if (field.id === '4') {
                        obj.timestamp = this.getValueNumberFromOS({
                            name: field.name,
                            objectId: data.object.id,
                            methodId: data.method.id
                        });
                    } else if (field.id === '7') {
                        obj.hours = this.getValueNumberFromOS({
                            name: field.name,
                            objectId: data.object.id,
                            methodId: data.method.id
                        });
                    } else if (field.id === '9') {
                        if (field.specialRateOptionCode) {
                            specialstake = this.getValueBooleanFromOS({
                                name: field.specialRateOptionCode,
                                objectId: data.object.id,
                                methodId: data.method.id
                            });
                        }

                        obj.stake = this.getValueNumberFromOS({
                            name: field.name,
                            objectId: data.object.id,
                            methodId: data.method.id
                        });
                    } else if (field.id === '10') {
                        const exchangeRate = this.getValueNumberFromOS({
                            name: field.name,
                            objectId: data.object.id,
                            methodId: data.method.id
                        });
                        obj.stake = obj.stake * (exchangeRate === 0 ? 1 : exchangeRate);
                    }
                }
            });
            if (!specialstake) {
                for (const methodId in this.operationalState.templateMonthLimit) {
                    if (methodId === data.method.id) {
                        for (const key in this.operationalState.templateMonthLimit[methodId]) {
                            const timestampStartMonth = Number(key);
                            if (checkTimestampIsInMonth(timestampStartMonth, obj.timestamp)) {
                                if (
                                    !this.operationalState.objectsToCalcLimit[timestampStartMonth]
                                ) {
                                    this.operationalState.objectsToCalcLimit[timestampStartMonth] =
                                        [];
                                }
                                this.operationalState.objectsToCalcLimit[timestampStartMonth].push(
                                    obj
                                );
                            }
                        }
                    }
                }
            }
        }
    }
}
