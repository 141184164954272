import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { UnfoldedState, PermissionFormProps } from './types';
import { SubmitHandler, useForm } from 'react-hook-form';
import { PermissionsScreenManager, decodeDbEncoding } from 'src/utils';
import PermissionFunctionColumn from '../permissionFunctionColumn/PermissionFunctionColumn';
import { updatePermissions } from 'src/api/src/permissionsScreen/updatePermissions';
import { setSpinner } from 'src/store/src/general/system/systemSlice';
import { useAppDispatch } from 'src/store';
import { setPopupInfoTwo } from 'src/store/src/popup';
import { PermissionFormState } from 'src/utils/src/permissionsScreen/types';
import { EventBus } from 'src/utils/src/shared/EventBus';

const PermissionsForm: FC<PermissionFormProps> = ({ permissionScreenState }) => {
    const dispatch = useAppDispatch();
    const { handleSubmit, control } = useForm({
        defaultValues: useMemo(
            () => PermissionsScreenManager.createInitialFormState(permissionScreenState),
            []
        )
    });

    const [unfoldedState, setUnfoldedState] = useState<UnfoldedState>(
        PermissionsScreenManager.createInitialFoldingState(permissionScreenState)
    );

    const toggleFolding = useCallback((key: string) => {
        setUnfoldedState((prevState: UnfoldedState) => ({
            ...prevState,
            [key]: !prevState[key]
        }));
    }, []);

    const onSubmit: SubmitHandler<PermissionFormState> = useCallback(
        async (data) => {
            dispatch(setSpinner(true));

            const res = await updatePermissions(data);
            if (!res.success) {
                dispatch(
                    setPopupInfoTwo({
                        title: 'Błąd zapisywania zmian',
                        message: res.message
                    })
                );
            }

            dispatch(setSpinner(false));
        },
        [dispatch]
    );

    useEffect(() => {
        const key = 'submitPermissionsForm';
        EventBus.$on(key, () => {
            handleSubmit(onSubmit)();
        });
        return () => EventBus.$off(key);
    }, [onSubmit]);

    return (
        <table>
            <thead>
                <tr>
                    {permissionScreenState.functions.map((func) => (
                        <th key={func.id} style={{ textAlign: 'left', padding: '0.3rem' }}>
                            {decodeDbEncoding(func.name)}
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody>
                <tr>
                    {permissionScreenState.functions.map((func) => (
                        <td key={func.id}>
                            <PermissionFunctionColumn
                                key={func.id}
                                func={func}
                                control={control}
                                unfoldedState={unfoldedState}
                                toggleFolding={toggleFolding}
                            />
                        </td>
                    ))}
                </tr>
            </tbody>
        </table>
    );
};

export default PermissionsForm;
