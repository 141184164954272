import { AppDispatch } from 'src/store';

import {
    BudgetGetValuesFn,
    BudgetResetFn,
    BudgetSetValueFn,
    BudgetOperationalState,
    CalcFnProps
} from 'src/hooks/src/budget/useFormBudget';
import { BudgetScreenState, BugdetSettlementMethodLevel } from 'src/store/src/budget/budget/types';
import { BudgetHandlerCalcGlobalSummaryDataPrepareAll } from 'src/utils/src/budget/BudgetHandlerCalcGlobalSummaryDataPrepareAll';
import { BudgetHandlerCalcTotalBruttoNetto } from 'src/utils/src/budget/BudgetHandlerCalcTotalBruttoNetto';
import { BudgetHandlerTickAllInMonth } from 'src/utils/src/budget/BudgetHandlerTickAllInMonth';
import { BudgetHandlerStateGlobalHelper } from 'src/utils/src/budget/BudgetHandlerStateGlobalHelper';
import { BudgetHandlerStateMethod } from 'src/utils/src/budget/BudgetHandlerStateMethod';
import { BudgetHandlerCalcNewStakeWorker } from 'src/utils/src/budget/BudgetHandlerCalcNewStakeWorker';
import { setBudgetHandlerStateGlobalKind } from 'src/store/src/budget';
import { BudgetHandlerCalcGlobalSummaryMain } from 'src/utils/src/budget/BudgetHandlerCalcGlobalSummaryMain';
import { BudgetHandlerTemplateMonthLimit } from 'src/utils/src/budget/BudgetHandlerTemplateMonthLimit';
import { BudgetHandlerStateManageKeyMemo } from 'src/utils/src/budget/BudgetHandlerStateManageKeyMemo';

// Class working on only onChange

export type BudgetHandlerStateGlobalProps = {
    getValues: BudgetGetValuesFn;
    setValue: BudgetSetValueFn;
    budget: BudgetScreenState;
    reset: BudgetResetFn;
    operationalState?: BudgetOperationalState;
    dispatch: AppDispatch;
};

export type BudgetHandlerStateGlobalFnExecuteSharedProps = {
    budget: BudgetScreenState;
    operationalState: BudgetOperationalState;
};

export type BudgetHandlerStateGlobalFnExecuteCallPlacement =
    | 'workerOnBill'
    | 'tickAll'
    | 'specialStake'
    | 'default'
    | 'tickOne'
    | 'date';

export type BudgetHandlerStateGlobalFnExecuteProps = CalcFnProps & {};

export class BudgetHandlerStateGlobal extends BudgetHandlerStateGlobalHelper {
    reset: BudgetResetFn;
    method?: BugdetSettlementMethodLevel;
    setValue: BudgetSetValueFn;
    dispatch: AppDispatch;
    constructor({
        dispatch,
        getValues,
        setValue,
        budget,
        reset,
        operationalState = {
            callPlacement: 'budgetHandlerStateGlobal',
            fieldsTempFromRHF: {},
            fieldsTemp: {},
            sumTemp: {},
            limitTemp: {},
            limitTempToCalc: {},
            fieldsStructure: [],
            objectsToCalcLimit: {},
            templateMonthLimit: {},
            globalSummary: { hours: 0, hoursSpecial: 0, amount: 0 },
            globalSummaryData: {},
            globalSummaryDataLimit: {},
            globalSummaryDataLimitToCalc: {},
            globalSummaryDataObject: {}
        }
    }: BudgetHandlerStateGlobalProps) {
        super({ getValues, budget, operationalState });
        this.setValue = setValue;
        this.reset = reset;
        this.dispatch = dispatch;
    }

    executeFromChangedBudget() {
        switch (this.budget.budgetHandlerStateGlobalKind?.kind) {
            case 'getYearsBefore':
                this.runTemplateMonthLimit();
                this.assignAllStateFormRHF({
                    methodId: this.budget.budgetHandlerStateGlobalKind.methodId
                });
                this.setMethod({ methodId: this.budget.budgetHandlerStateGlobalKind.methodId });
                this.addFieldsTempFromRHFToFieldsTemp();
                this.runMethod();
                this.update({ methodId: this.budget.budgetHandlerStateGlobalKind.methodId });
                this.dispatch(
                    setBudgetHandlerStateGlobalKind({
                        kind: 'default'
                    })
                );
                break;
            default:
                break;
        }
    }

    execute(data: BudgetHandlerStateGlobalFnExecuteProps) {
        this.runTemplateMonthLimit();
        this.assignAllStateFormRHF(data);
        this.setMethod(data);
        this.findMonthWhereWasChanged(data);
        this.updateMonthKey(data);

        switch (data.callPlacement) {
            case 'date':
                if (this.method?.rozliczanielimitgodzin) {
                    this.updateObjectKey(data);
                    this.addFieldsTempFromRHFToFieldsTemp();
                    this.runMethod();
                    this.calcGlobalSummaryMany(data);
                    this.calcGlobalSummaryMain();
                    this.update(data);
                }
                break;
            case 'workerOnBill':
                if (this.method?.rozliczaniewgkategoriistawkigodzinowej) {
                    this.updateObjectKey(data);
                    this.addFieldsTempFromRHFToFieldsTemp();
                    this.calcStakeWorker(data);
                    this.runMethod();
                    this.calcGlobalSummaryMany(data);
                    this.calcGlobalSummaryMain();
                    this.update(data);
                }
                break;
            case 'tickAll':
                this.addFieldsTempFromRHFToFieldsTemp();
                this.updateObjectsKey(data);
                this.tickAllInStateMedium(data);
                this.calcGlobalSummaryMany(data);
                this.calcGlobalSummaryMain();
                this.update(data);
                break;
            case 'tickOne':
            case 'specialStake':
                this.updateObjectKey(data);
                this.addFieldsTempFromRHFToFieldsTemp();
                this.runMethod();
                this.calcGlobalSummaryOne(data);
                this.calcGlobalSummaryMain();
                this.update(data);
                break;
            default:
                this.updateObjectKey(data);
                this.addFieldsTempFromRHFToFieldsTemp();
                this.calcBruttoNetto(data);
                this.runMethod();
                this.calcGlobalSummaryOne(data);
                this.calcGlobalSummaryMain();
                this.update(data);
                break;
        }
    }

    private runTemplateMonthLimit() {
        new BudgetHandlerTemplateMonthLimit({
            budget: this.budget,
            operationalState: this.operationalState
        }).execute();
    }

    private calcStakeWorker(data: BudgetHandlerStateGlobalFnExecuteProps) {
        new BudgetHandlerCalcNewStakeWorker({
            budget: this.budget,
            operationalState: this.operationalState,
            method: this.method
        }).execute(data);
    }

    private tickAllInStateMedium(data: BudgetHandlerStateGlobalFnExecuteProps) {
        new BudgetHandlerTickAllInMonth({
            budget: this.budget,
            operationalState: this.operationalState
        }).execute(data);
    }

    private setMethod({ methodId }: { methodId: string }) {
        this.method = this.budget.settlementMethods.find((method) => method.id === methodId);
    }

    private calcBruttoNetto(data: BudgetHandlerStateGlobalFnExecuteProps) {
        if (data.recalcOnChange) {
            new BudgetHandlerCalcTotalBruttoNetto({
                budget: this.budget,
                operationalState: this.operationalState
            }).execute(data);
        }
    }

    private calcGlobalSummaryOne(data: BudgetHandlerStateGlobalFnExecuteProps) {
        new BudgetHandlerCalcGlobalSummaryDataPrepareAll({
            budget: this.budget,
            operationalState: this.operationalState,
            method: this.method
        }).executeOne(data);
    }

    private calcGlobalSummaryMany(data: BudgetHandlerStateGlobalFnExecuteProps) {
        new BudgetHandlerCalcGlobalSummaryDataPrepareAll({
            budget: this.budget,
            operationalState: this.operationalState,
            method: this.method
        }).executeMany(data);
    }

    private calcGlobalSummaryMain() {
        new BudgetHandlerCalcGlobalSummaryMain({
            budget: this.budget,
            operationalState: this.operationalState
        }).execute();
    }

    private runMethod() {
        if (this.method) {
            new BudgetHandlerStateMethod({
                budget: this.budget,
                operationalState: this.operationalState
            }).runMethod(this.method);
        }
    }

    private updateMonthKey(data: BudgetHandlerStateGlobalFnExecuteProps) {
        if (data.monthId) {
            new BudgetHandlerStateManageKeyMemo({
                budget: this.budget,
                operationalState: this.operationalState
            }).updateKey([data.monthId]);
        }
    }

    private updateObjectKey(data: BudgetHandlerStateGlobalFnExecuteProps) {
        if (data.monthId && data.objectId && data.settleObjectCode) {
            new BudgetHandlerStateManageKeyMemo({
                budget: this.budget,
                operationalState: this.operationalState
            }).updateKey([data.monthId, data.settleObjectCode, data.objectId]);
        }
    }

    private updateObjectsKey(data: BudgetHandlerStateGlobalFnExecuteProps) {
        if (data.monthId) {
            new BudgetHandlerStateManageKeyMemo({
                budget: this.budget,
                operationalState: this.operationalState
            }).updateKeys(data.monthId);
        }
    }

    private update({ methodId }: { methodId: string }) {
        const stateArr = Object.keys(this.operationalState.fieldsTemp);
        if (stateArr.length > 0) {
            this.reset((prev) => ({
                ...prev,
                main: {
                    ...prev.main,
                    [methodId]: {
                        ...prev.main[methodId],
                        ...this.operationalState.fieldsTemp,
                        ...this.operationalState.limitTemp,
                        ...this.operationalState.sumTemp
                    }
                },
                globalSummaryData: this.operationalState.globalSummaryData,
                globalSummary: this.operationalState.globalSummary
            }));
        }
    }
}
