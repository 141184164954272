import {
    MassBillCalcFnProps,
    MassBillGetValuesFn,
    MassBillOperationalState,
    MassBillResetFn
} from 'src/hooks/src/massBill/useFormMassBill';
import { MassBillState } from 'src/store/src/massBill/massBill/types';
import { MassBillGetterOperationalState } from 'src/utils/src/massBill/MassBillGetterOperationalState';
import { MassBillHandlerField } from 'src/utils/src/massBill/MassBillHandlerField';

export type MassBillHandlerStateGlobalProps = {
    getValues: MassBillGetValuesFn;
    massBill: MassBillState;
    operationalState?: MassBillOperationalState;
    reset: MassBillResetFn;
};

export class MassBillHandlerStateGlobal extends MassBillGetterOperationalState {
    reset: MassBillResetFn;
    getValues: MassBillGetValuesFn;
    constructor({
        massBill,
        getValues,
        reset,
        operationalState = {
            fieldTemp: {}
        }
    }: MassBillHandlerStateGlobalProps) {
        super({ massBill, operationalState });
        this.getValues = getValues;
        this.reset = reset;
    }

    getValueFromRHF() {
        this.operationalState.fieldTemp = {
            ...this.operationalState.fieldTemp,
            ...this.getValues()
        };
    }

    execute(data: MassBillCalcFnProps) {
        switch (data.callPlacement) {
            case 'tick':
                this.getValueFromRHF();
                this.setSigning(data);
                this.update();
                break;
            case 'dateGlobal':
                this.getValueFromRHF();
                this.setDate(data);
                this.update();
                break;
            default:
                break;
        }
    }

    private setSigning(data: MassBillCalcFnProps) {
        new MassBillHandlerField({
            operationalState: this.operationalState,
            massBill: this.massBill
        }).setSigning(data);
    }

    private setDate(data: MassBillCalcFnProps) {
        new MassBillHandlerField({
            operationalState: this.operationalState,
            massBill: this.massBill
        }).setDate(data);
    }

    private update() {
        const stateArr = Object.keys(this.operationalState.fieldTemp);
        if (stateArr.length > 0) {
            this.reset((prev) => ({
                ...prev,
                ...this.operationalState.fieldTemp
            }));
        }
    }
}
