import {
    clientField,
    contractField,
    groupField,
    globalField
} from 'src/constants/massBill/massBillFields';
import { MassBillField, MassBillOperationalState } from 'src/hooks/src/massBill/useFormMassBill';
import { MassBillState } from 'src/store/src/massBill/massBill/types';
import { createKeyFieldMassBill } from 'src/utils/src/massBill/createKeyFieldMassBill';
import { createKeyIdMassBill } from 'src/utils/src/massBill/createKeyIdMassBill';
import { DateManager } from 'src/utils/src/shared/DateManager';

export type MassBillSetterOtherFieldProps = {
    massBill: MassBillState;
    operationalState: MassBillOperationalState;
};
export class MassBillSetterOtherField {
    massBill: MassBillState;
    defaultIsChoosen: boolean;
    defaultIsTogether: boolean;
    operationalState: MassBillOperationalState;
    constructor({ massBill, operationalState }: MassBillSetterOtherFieldProps) {
        this.massBill = massBill;
        this.operationalState = operationalState;
        this.defaultIsChoosen = massBill.params.domyslnie_wybrane === '1';
        this.defaultIsTogether = massBill.params.domyslnie_razem === '1';
    }

    execute() {
        globalField.forEach((field) => {
            const name = createKeyFieldMassBill({ name: field.name, id: String(field.id) });
            this.createField({ field, name });
        });

        this.massBill?.client?.forEach((clientItem) => {
            clientField.forEach((field) => {
                const name = createKeyFieldMassBill({ name: field.name, id: clientItem.id });
                this.createField({ field, clientId: clientItem.id, name });
            });
            clientItem.kontrakty.forEach((contractItem) => {
                contractField.forEach((field) => {
                    if (
                        !field.isOnlyGroupContract ||
                        this.massBill.params.grupowanie_tylko_po_kontraktach === '1'
                    ) {
                        const name = createKeyFieldMassBill({
                            name: field.name,
                            id: contractItem.id
                        });
                        this.createField({
                            field,
                            clientId: clientItem.id,
                            contractId: contractItem.id,
                            name
                        });
                    }
                });
                contractItem.grupy.forEach((groupItem) => {
                    groupField.forEach((field) => {
                        if (
                            !field.isOnlyGroupContract ||
                            this.massBill.params.grupowanie_tylko_po_kontraktach === '0' ||
                            !this.massBill.params.grupowanie_tylko_po_kontraktach
                        ) {
                            const name = createKeyFieldMassBill({
                                name: field.name,
                                id: groupItem.id
                            });

                            this.createField({
                                field,
                                clientId: clientItem.id,
                                contractId: contractItem.id,
                                groupId: groupItem.id,
                                name
                            });
                        }
                    });
                });
            });
        });
    }

    private createField({
        field,
        clientId,
        contractId,
        groupId,
        name
    }: {
        name: string;
        field: MassBillField;
        clientId?: string;
        contractId?: string;
        groupId?: string;
    }) {
        const keyId = createKeyIdMassBill({
            fieldId: field.id,
            clientId,
            contractId,
            groupId
        });

        switch (field.id) {
            case 10.5:
                this.operationalState.fieldTemp[name] = {
                    checkbox: this.defaultIsTogether,
                    keyId,
                    keyReset: 1
                };
                break;
            case 12:
                this.operationalState.fieldTemp[name] = {
                    checkbox: this.defaultIsChoosen,
                    keyId,
                    keyReset: 1
                };
                break;
            case 13:
                this.operationalState.fieldTemp[name] = {
                    data: DateManager.setDateFromStrDDMMYYYY(
                        this.massBill.dateSet.domyslna_wartosc
                    ),
                    keyId,
                    keyReset: 1
                };
                break;
            case 14:
                this.operationalState.fieldTemp[name] = {
                    data: DateManager.setDateFromStrDDMMYYYY(
                        this.massBill.dateSale.domyslna_wartosc
                    ),
                    keyId,
                    keyReset: 1
                };
                break;
            default:
                break;
        }
    }
}
