import { BudgetOperationalState } from 'src/hooks/src/budget/useFormBudget';
import {
    BudgetGlobalSummaryDataItem,
    BudgetGlobalSummaryDataLimit,
    BudgetScreenState,
    SettledObjectsCodes
} from 'src/store/src/budget/budget/types';
import { BudgetHandlerStateGlobalFnExecuteSharedProps } from 'src/utils/src/budget/BudgetHandlerStateGlobal';
import { checkTimestampIsInMonth } from 'src/utils/src/shared/checkTimestampIsInMonth';
import { NumberManager } from 'src/utils/src/shared/NumberManager';

type BudgetHandlerCalcGlobalSummaryMainProps = BudgetHandlerStateGlobalFnExecuteSharedProps & {};

const typesOfCodesInvolvedToLimit: SettledObjectsCodes[] = ['kontakty', 'rozprawy', 'zadania'];

export class BudgetHandlerCalcGlobalSummaryMain {
    budget: BudgetScreenState;
    operationalState: BudgetOperationalState;
    isCapfee: boolean;
    isLimit: boolean;
    limit: BudgetGlobalSummaryDataLimit;
    capfeeRetrieved: number;
    constructor(data: BudgetHandlerCalcGlobalSummaryMainProps) {
        this.budget = data.budget;
        this.operationalState = data.operationalState;
        this.isCapfee = false;
        this.isLimit = false;
        this.limit = {};
        this.capfeeRetrieved = 0;
    }

    execute() {
        this.sum();
    }

    private sum() {
        for (const methodId in this.operationalState.globalSummaryData) {
            this.isCapfee = this.operationalState.globalSummaryData[methodId].isCapfee;
            this.isLimit = this.operationalState.globalSummaryData[methodId].isLimit;
            this.limit = this.operationalState.globalSummaryData[methodId].limit;
            this.capfeeRetrieved = this.operationalState.globalSummaryData[methodId]?.capfee;

            for (const objectKey in this.operationalState.globalSummaryData[methodId].object) {
                this.calc({
                    ...this.operationalState.globalSummaryData[methodId].object[objectKey]
                });
            }
        }
    }

    private calc(data: BudgetGlobalSummaryDataItem) {
        switch (data.type) {
            case 'kontakty':
            case 'rozprawy':
            case 'zadania': {
                this.calcAll({
                    ...data
                });
                break;
            }
            case 'kosztyzastepstwa':
            case 'ryczalty_za_sprawy':
            case 'oplatywstepne_za_sprawy':
            case 'dokumenty':
            case 'dokumenty_kontrakt':
            case 'ryczalt':
            case 'ryczalt_miesiac':
            case 'successfees':
            case 'koszty':
            case 'rozprawy_ryczalty':
            case 'fakturowaneelicencje':
            case 'etapyprojektow':
            case 'koszty_projektow':
            case 'kontakty_ryczalty': {
                this.calcOnlyAmount({
                    ...data
                });
                break;
            }
            default:
                break;
        }
    }

    private calcOnlyAmount(data: BudgetGlobalSummaryDataItem) {
        if (data.isInvoiced) {
            this.addAmount(data);
        }
    }

    private calcAll(data: BudgetGlobalSummaryDataItem) {
        if (data.isInvoiced) {
            if (data.specialStake) {
                this.addHoursSpecial(data);
            } else {
                this.addHours(data);
            }
            this.addAmount(data);
        }
    }

    private addAmount(data: BudgetGlobalSummaryDataItem) {
        let amount = data.valuePriceTotal * data.valueRateExchange;

        if (this.isLimit && !data.specialStake && typesOfCodesInvolvedToLimit.includes(data.type)) {
            for (const methodId in this.operationalState.templateMonthLimit) {
                if (methodId === data.methodId) {
                    for (const key in this.operationalState.templateMonthLimit[methodId]) {
                        const timestampStartMonth = Number(key);
                        if (checkTimestampIsInMonth(timestampStartMonth, data.date)) {
                            const keyLimit =
                                this.operationalState.templateMonthLimit?.[methodId]?.[key]?.[0];
                            if (this.operationalState.globalSummaryDataLimitToCalc[keyLimit]) {
                                const tempAmount =
                                    amount +
                                    this.operationalState.globalSummaryDataLimitToCalc[keyLimit]
                                        ?.valuePriceTotal *
                                        this.operationalState.globalSummaryDataLimitToCalc[keyLimit]
                                            ?.valueRateExchange;
                                if (tempAmount > 0) {
                                    amount = tempAmount;
                                    this.operationalState.globalSummaryDataLimitToCalc[keyLimit] = {
                                        ...this.operationalState.globalSummaryDataLimitToCalc[
                                            keyLimit
                                        ],
                                        valuePriceTotal: 0
                                    };
                                } else {
                                    amount = 0;
                                    this.operationalState.globalSummaryDataLimitToCalc[keyLimit] = {
                                        ...this.operationalState.globalSummaryDataLimitToCalc[
                                            keyLimit
                                        ],
                                        valuePriceTotal: tempAmount
                                    };
                                }
                            }
                        }
                    }
                }
            }
        }
        if (this.isCapfee) {
            const difference = this.capfeeRetrieved - amount;
            if (difference > 0) {
                this.operationalState.globalSummary.amount =
                    this.operationalState.globalSummary.amount +
                    this.recalcBaseOnRateExchange(data, amount);
                this.capfeeRetrieved = difference;
            } else {
                this.operationalState.globalSummary.amount =
                    this.operationalState.globalSummary.amount +
                    this.recalcBaseOnRateExchange(data, this.capfeeRetrieved);
                this.capfeeRetrieved = 0;
            }
        } else {
            this.operationalState.globalSummary.amount =
                this.operationalState.globalSummary.amount +
                this.recalcBaseOnRateExchange(data, amount);
        }
    }

    private addHours(data: BudgetGlobalSummaryDataItem) {
        this.operationalState.globalSummary.hours = NumberManager.round(
            this.operationalState.globalSummary.hours + data.valueHour,
            2
        );
    }

    private addHoursSpecial(data: BudgetGlobalSummaryDataItem) {
        this.operationalState.globalSummary.hoursSpecial = NumberManager.round(
            this.operationalState.globalSummary.hoursSpecial + data.valueHour,
            2
        );
    }

    private recalcBaseOnRateExchange(data: BudgetGlobalSummaryDataItem, amount: number) {
        const rate = Number(this.budget.invoiceRate);
        let newValue = amount;

        if (Number(this.budget.invoiceRate) >= data.valueRateExchange) {
            newValue = newValue / rate;
        } else if (Number(this.budget.invoiceRate) < data.valueRateExchange) {
            newValue = newValue * rate;
        }
        return newValue;
    }
}
