import { BudgetOperationalState, ObjectToCalcLimitItem } from 'src/hooks/src/budget/useFormBudget';
import {
    BugdetMonthLevel,
    BugdetSettlementMethodLevel,
    SettleObject,
    SettledObjectsCodes
} from 'src/store/src/budget/budget/types';
import { BudgetSetterOperationalState } from 'src/utils/src/budget/BudgetSetterOperationalState';
import { BudgetSetterStateGlobalProps } from 'src/utils/src/budget/BudgetSetterStateGlobal';
import { CreateKeySumSharedProps } from 'src/utils/src/budget/createKeySumBudget';
import { createTimestampByAddThreeZero } from 'src/utils/src/budget/createTimestampByAddThreeZero';
import { DateManager } from 'src/utils/src/shared/DateManager';

type BudgetSetterObjectsToCalcLimitStakeProps = BudgetSetterStateGlobalProps & {
    operationalState: BudgetOperationalState;
};

type ExecuteFnProps = CreateKeySumSharedProps & {
    object: SettleObject;
    method: BugdetSettlementMethodLevel;
    code: SettledObjectsCodes;
    month?: BugdetMonthLevel;
};

const possibleObjects: SettledObjectsCodes[] = ['zadania', 'kontakty', 'rozprawy'];

export class BudgetSetterObjectsToCalcLimitStake extends BudgetSetterOperationalState {
    constructor({ budget, operationalState }: BudgetSetterObjectsToCalcLimitStakeProps) {
        super({ budget, operationalState });
    }

    execute(data: ExecuteFnProps) {
        if (possibleObjects.includes(data.code)) {
            this.setToOSStructure(data.code);
            this.setToOSFieldsStructure({
                settled: data.object.settled,
                code: data.code,
                settledInThisSettlingObject:
                    data.object.settled && data.object.settledInThisSettlingObject
            });
            const timestampStartMonth = createTimestampByAddThreeZero(data.month?.id ?? '');
            const obj: ObjectToCalcLimitItem = {
                objectId: data.object.id,
                hours: 0,
                timestamp: 0,
                stake: 0
            };
            this.operationalState.fieldsStructure.forEach((field) => {
                if (field.id === '4') {
                    obj.timestamp = DateManager.getTimestampFromDateStr(
                        data.object.values[field.code]
                    );
                } else if (field.id === '7') {
                    obj.hours = Number(data.object.values[field.code]);
                } else if (field.id === '9') {
                    const exchangeRate = Number(data.object.values['kurs'] ?? 1);
                    obj.stake = Number(data.object.values[field.code]) * exchangeRate;
                }
            });
            if (!this.operationalState.objectsToCalcLimit[timestampStartMonth]) {
                this.operationalState.objectsToCalcLimit[timestampStartMonth] = [];
            }
            if (
                data.object.values['stawkaspecjalna'] &&
                data.object.values['stawkaspecjalna'] === 'f'
            ) {
                this.operationalState.objectsToCalcLimit[timestampStartMonth].push(obj);
            }
        }
    }
}
