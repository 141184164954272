import { BugdetMonthLevel } from 'src/store/src/budget/budget/types';
import { BaseApiResponse } from '../../types';
import { simpleQueryAPIData } from '../simpleQueryAPIData';

export async function getInMethodAdditionalYears(
    obiekt: string,
    id: string,
    methodId: string
): Promise<BaseApiResponse<BugdetMonthLevel[]>> {
    const params = new URLSearchParams([
        ['id', id],
        ['settlementMethodId', methodId]
    ]);
    return simpleQueryAPIData(
        `api_react/src/budget/getPrecedingYearsMonths.php?${params.toString()}`,
        'GET'
    );
}
