import { budgetStableFields } from 'src/constants/budget/budgetStableFields';
import { BudgetFieldsStateUnion, BudgetOperationalState } from 'src/hooks/src/budget/useFormBudget';
import {
    BugdetFieldStructure,
    BugdetMonthLevel,
    BugdetSettlementMethodLevel,
    SettledObjectsCodes
} from 'src/store/src/budget/budget/types';
import { BudgetSetterOperationalState } from 'src/utils/src/budget/BudgetSetterOperationalState';
import { BudgetSetterStateGlobalProps } from 'src/utils/src/budget/BudgetSetterStateGlobal';

import { calcTotalsInBudget } from 'src/utils/src/budget/calcTotalsInBudget';
import { createKeyFieldBudget } from 'src/utils/src/budget/createKeyFieldBudget';
import {
    CreateKeySumSharedProps,
    createKeySumBudget
} from 'src/utils/src/budget/createKeySumBudget';
import { createTimestampByAddThreeZero } from 'src/utils/src/budget/createTimestampByAddThreeZero';
import { setInitValueInBudget } from 'src/utils/src/budget/setInitValueInBudget';

// Class working on only onMount

type PrepareDefaultValueForLimitHourFnProps = CreateKeySumSharedProps & {
    fields: BugdetFieldStructure[];
    field: BugdetFieldStructure;
    code: SettledObjectsCodes;
    method: BugdetSettlementMethodLevel;
    month?: BugdetMonthLevel;
};

type RunHoursLimitProps = CreateKeySumSharedProps & {
    method: BugdetSettlementMethodLevel;
    code: SettledObjectsCodes;
    month?: BugdetMonthLevel;
};

type BudgetSetterStateLimitProps = BudgetSetterStateGlobalProps & {
    operationalState: BudgetOperationalState;
};
export class BudgetSetterStateLimit extends BudgetSetterOperationalState {
    constructor({ budget, operationalState }: BudgetSetterStateLimitProps) {
        super({ budget, operationalState });
    }

    runHoursLimit(data: RunHoursLimitProps) {
        const fields = [
            ...(this.budget.settledObjectsStructures['limit_godzin']?.fields ?? []),
            budgetStableFields['limit_godzin']
        ];

        if (data.method?.rozliczaniewgkategoriistawkigodzinowej) {
            const tempValues: { [key: string]: any } = {
                ...data.month?.hoursLimit
            };
            fields?.forEach((field) => {
                this.prepareDefaultValueForLimitHourExtend({
                    ...data,
                    field,
                    fields,
                    tempValues
                });
            });
        } else {
            fields?.forEach((field) => {
                this.prepareDefaultValueForLimitHourNormal({
                    ...data,
                    field,
                    fields
                });
            });
        }
    }

    private prepareDefaultValueForLimitHourNormal(data: PrepareDefaultValueForLimitHourFnProps) {
        const { field, code, method, month, fields } = data;
        if (month?.hoursLimit) {
            if (!field?.onlyText) {
                const { keyField } = createKeyFieldBudget({
                    name: field?.name,
                    objectId: month.hoursLimit.id,
                    monthDate: month?.data_rozp,
                    methodId: method.id
                });
                if (keyField && method?.id) {
                    const { keySum } = createKeySumBudget({
                        ...data,
                        fieldId: field.id,
                        methodId: method.id,
                        monthId: month?.id,
                        code
                    });

                    let fieldState: BudgetFieldsStateUnion = {};
                    if (
                        field.column === 'cena brutto {waluta}' ||
                        field.column === 'cena netto {waluta}'
                    ) {
                        fieldState = {
                            ...calcTotalsInBudget({
                                fields,
                                values: month?.hoursLimit,
                                bugdetFormVisibleFieldStructure: field,
                                code
                            }),
                            keyReset: 1,
                            keyId: keySum
                        };
                    } else {
                        fieldState = {
                            ...setInitValueInBudget({
                                values: month?.hoursLimit,
                                bugdetFormVisibleFieldStructure: field,
                                employees: this.budget.employees
                            }),
                            keyReset: 1,
                            keyId: keySum
                        };
                    }
                    this.operationalState.limitTempToCalc[keyField] = fieldState;
                    this.operationalState.limitTemp[keyField] = fieldState;
                }
            }
        }
    }

    private prepareDefaultValueForLimitHourExtend(
        data: PrepareDefaultValueForLimitHourFnProps & {
            tempValues: { [key: string]: any };
        }
    ) {
        const { field, code, method, month, fields } = data;
        if (month?.hoursLimit) {
            if (!field?.onlyText) {
                const { keyField } = createKeyFieldBudget({
                    name: field?.name,
                    objectId: month.hoursLimit.id,
                    monthDate: month?.data_rozp,
                    methodId: method.id
                });
                if (keyField && method?.id) {
                    const { keySum } = createKeySumBudget({
                        ...data,
                        fieldId: field.id,
                        methodId: method.id,
                        monthId: month?.id,
                        code
                    });

                    let fieldState: BudgetFieldsStateUnion = {};

                    if (field.id === '9') {
                        fieldState = {
                            liczba: String(
                                this.setStake({ tempValues: data.tempValues, monthId: month.id })
                            ),
                            keyReset: 1,
                            keyId: keySum
                        };
                    } else if (
                        field.column === 'cena brutto {waluta}' ||
                        field.column === 'cena netto {waluta}'
                    ) {
                        fieldState = {
                            ...calcTotalsInBudget({
                                fields,
                                values: data.tempValues,
                                bugdetFormVisibleFieldStructure: field,
                                code
                            }),
                            keyReset: 1,
                            keyId: keySum
                        };
                    } else {
                        fieldState = {
                            ...setInitValueInBudget({
                                values: data.tempValues,
                                bugdetFormVisibleFieldStructure: field,
                                employees: this.budget.employees
                            }),
                            keyReset: 1,
                            keyId: keySum
                        };
                    }
                    this.operationalState.limitTempToCalc[keyField] = fieldState;
                    this.operationalState.limitTemp[keyField] = fieldState;
                }
            }
        }
    }

    private setStake({
        tempValues,
        monthId
    }: {
        tempValues: { [key: string]: any };
        monthId: string;
    }) {
        const timestampStartMonth = createTimestampByAddThreeZero(monthId);
        const maxHours = Number(tempValues?.limit_godzin_ilosc ?? 0);
        let hours = Number(tempValues?.limit_godzin_ilosc ?? 0);
        let stake = 0;
        let getHours = 0;
        this.operationalState.objectsToCalcLimit[timestampStartMonth]?.forEach((object) => {
            if (hours > 0) {
                if (hours > object.hours) {
                    stake = stake + object.stake * object.hours;
                    getHours = getHours + object.hours;
                } else {
                    getHours = maxHours;
                    stake = stake + object.stake * hours;
                }

                hours = hours - object.hours;
            }
        });
        const finalStake = stake / getHours;
        if (isNaN(finalStake)) {
            return 0;
        }
        tempValues.limit_godzin = finalStake * -1;
        return finalStake;
    }
}
