import { MassBillOperationalState } from 'src/hooks/src/massBill/useFormMassBill';
import { MassBillState } from 'src/store/src/massBill/massBill/types';

export type MassBillGetterOperationalStateProps = {
    massBill: MassBillState;
    operationalState: MassBillOperationalState;
};

export class MassBillGetterOperationalState {
    massBill: MassBillState;
    operationalState: MassBillOperationalState;
    constructor({ massBill, operationalState }: MassBillGetterOperationalStateProps) {
        this.massBill = massBill;
        this.operationalState = operationalState;
    }

    protected getFieldStateFromOSByKeyField(keyField: string) {
        return this.operationalState.fieldTemp[keyField];
    }
}
