import React from 'react';
import { updatePageTitleWithScreenName } from 'src/store/src/general';
import { useHandleSystemLock } from 'src/hooks';
import SecondFAView from '../../templates/secondFAView/SecondFAView';

export const SecondFAScreen = () => {
    updatePageTitleWithScreenName('2FA');
    useHandleSystemLock();

    return <SecondFAView />;
};
