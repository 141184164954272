import { useCallback } from 'react';
import {
    Control,
    useForm,
    UseFormGetValues,
    UseFormHandleSubmit,
    UseFormRegister,
    UseFormReset,
    UseFormSetValue,
    UseFormWatch
} from 'react-hook-form';
import { MassBillPosition } from 'src/api/src/massBill/types';
import { FieldsStateRHF, FieldsStateUnionRHF } from 'src/data/fieldsReactHookForm';
import { useAppDispatch, useTypedSelector } from 'src/store';
import { doMassBillCalc } from 'src/store/src/massBill/sharedAcions';
import {
    GetValuesFnMassBillPropsRHF,
    MassBillGetValuesHandlerRHF
} from 'src/utils/src/massBill/MassBillGetValuesHandlerRHF';
import { MassBillSetterStateGlobal } from 'src/utils/src/massBill/MassBillSetterStateGlobal';
import {
    MassBillSetValueHandlerRHF,
    SetValueFnMassBillPropsRHF
} from 'src/utils/src/massBill/MassBillSetValueHandlerRHF';

export type MassBillFormState = FieldsStateRHF;
export type MassBillFormStateFields = FieldsStateUnionRHF;
export type MassBillGetValuesFn = UseFormGetValues<MassBillFormState>;
export type MassBillSetValueFn = UseFormSetValue<MassBillFormState>;
export type MassBillResetFn = UseFormReset<MassBillFormState>;
export type MassBillRegisterFn = UseFormRegister<MassBillFormState>;
export type MassBillHandleSubmitFn = UseFormHandleSubmit<MassBillFormState>;

export type MassBillFieldKeyParts = {
    id?: string;
    pole_pozycji?: string;
    pole_ceny?: string;
    pole_daty_wystawienia?: string;
    pole_daty_sprzedazy?: string;
};

export type MassBillFieldTypes = 'checkbox' | 'tekst' | 'tekst_bez_ograniczen' | 'data' | 'cena';

export type MassBillField = {
    id: number;
    type: MassBillFieldTypes;
    name: string;
    code?: keyof MassBillPosition;
    hidden: boolean;
    callPlacement?: MassBillHandlerStateGlobalFnExecuteCallPlacement;
    isActual?: boolean;
    isSign?: boolean;
    isOnlyGroupContract?: boolean;
};

export type MassBillHandlerStateGlobalFnExecuteCallPlacement = 'tick' | 'dateGlobal' | 'default';

export type MassBillCalcFnProps = {
    keyField: string;
    callPlacement?: MassBillHandlerStateGlobalFnExecuteCallPlacement;
};

export type MassBillCalcFn = (data: MassBillCalcFnProps) => void;

export type MassBillOperationalState = {
    fieldTemp: MassBillFormState;
};

export type UseFormMassBillReturnProps = {
    getValuesBudgetRHF: (data: GetValuesFnMassBillPropsRHF) => MassBillGetValuesHandlerRHF;
    setValueBudgetRHF: (data: SetValueFnMassBillPropsRHF) => MassBillSetValueHandlerRHF;
    calc: MassBillCalcFn;
    handleSubmit: MassBillHandleSubmitFn;
    control: Control<FieldsStateRHF, any>;
    watch: UseFormWatch<FieldsStateRHF>;
};

export function useFormMassBill() {
    const massBill = useTypedSelector((state) => state.massBill);
    const dispatch = useAppDispatch();

    const { setValue, handleSubmit, getValues, reset, control, watch } = useForm<MassBillFormState>(
        {
            defaultValues: new MassBillSetterStateGlobal({ massBill }).execute()
        }
    );
    const getValuesBudgetRHF = useCallback(
        (data: GetValuesFnMassBillPropsRHF) =>
            new MassBillGetValuesHandlerRHF({ getValues }).run(data),
        [getValues]
    );
    const setValueBudgetRHF = useCallback(
        (data: SetValueFnMassBillPropsRHF) =>
            new MassBillSetValueHandlerRHF({ getValues, setValue }).run(data),
        [getValues, setValue]
    );

    const calc = useCallback(
        (data: MassBillCalcFnProps) => dispatch(doMassBillCalc(getValues, reset, data)),
        [getValues, reset, dispatch]
    );

    return {
        getValuesBudgetRHF,
        setValueBudgetRHF,
        calc,
        handleSubmit,
        control,
        watch
    };
}
