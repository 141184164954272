import React, { FC } from 'react';
import { QueryLinkFnManager } from 'src/components/shared/atoms/queryLink/QueryLinkFnManager';
import { Box } from '@mui/material';
import { useTypedSelector } from 'src/store';
import { useNavigate } from 'react-router-dom';
import { TransformationManager } from 'src/store/src/listScreen/transformations/TransformationManager';
import { ApiActionBehavior, SystemAction } from 'src/data/types';
import { RowDataId } from 'src/store/src/listScreen/tableData/types';
import { SourceOfTrigger } from 'src/data/sourceOfTrigger';
import { Additional } from 'src/store/src/object/object/types';
import { TemplateMainManager } from 'src/utils/src/shared/TemplateMainManager';

type Props = {
    rowId: RowDataId | null;
    sourceOfTrigger: SourceOfTrigger;
    additional: Additional;
    action: SystemAction;
};

export const LegacyCompatibleLinkCreatedOnClick: FC<Props> = ({
    rowId,
    children,
    additional,
    sourceOfTrigger,
    action
}) => {
    const navigate = useNavigate();
    const transformationState = useTypedSelector((state) => state.listTransformations);
    const rowData = useTypedSelector((state) => state.listScreenTableData.rowData);
    const objectSlice = useTypedSelector((state) => state.object);
    const treeData = useTypedSelector((state) => state.listTreeTableData);

    const handleOnClick = (
        e: React.MouseEvent<HTMLDivElement, MouseEvent>,
        type: 'click' | 'contextMenu'
    ) => {
        const { url, params, locationStateFlag } = new TemplateMainManager({
            rowData,
            rowId,
            transformationState,
            actionBehavior: action.behaviour as ApiActionBehavior,
            action,
            objectSlice,
            sourceOfTrigger,
            additional,
            treeData
        }).getData();
        // temp fix for budget
        let queryParams = new URLSearchParams(params);
        if (action.name === 'Wystaw fakturę/notę') {
            queryParams = new URLSearchParams(params + '&obiekt=kontrakty');
        }
        // temp fix for budget

        const locationState = new TransformationManager({
            transformationState
        }).getDataLocationState(locationStateFlag);

        if (type === 'contextMenu') {
            new QueryLinkFnManager({
                url,
                navigate,
                queryParams,
                locationState: locationState && locationState
            }).runOnContextMenu(e);
        } else {
            new QueryLinkFnManager({
                url,
                navigate,
                queryParams,
                newWindow: action.behaviour?.data?.newWindow,
                locationState: locationState && locationState
            }).runOnClick(e);
        }
    };

    return (
        <Box
            onClick={(e) => handleOnClick(e, 'click')}
            onContextMenu={(e) => handleOnClick(e, 'contextMenu')}>
            {children}
        </Box>
    );
};
