import { budgetStableCodesForTotalCalc } from 'src/constants/budget/budgetStableCodesForTotalCalc';
import { BugdetObjectStructure } from 'src/store/src/budget/budget/types';
import {
    BudgetHandlerStateGlobalFnExecuteProps,
    BudgetHandlerStateGlobalFnExecuteSharedProps
} from 'src/utils/src/budget/BudgetHandlerStateGlobal';
import { BudgetSetterOperationalState } from 'src/utils/src/budget/BudgetSetterOperationalState';
import { getVat } from 'src/utils/src/shared/getVat';
import { BudgetCodesForTotalCalc } from './types';

// Class working on only onChange

type BudgetHandlerCalcTotalBruttoNettoProps = BudgetHandlerStateGlobalFnExecuteSharedProps & {};

export class BudgetHandlerCalcTotalBruttoNetto extends BudgetSetterOperationalState {
    codes: BudgetCodesForTotalCalc;
    structure?: BugdetObjectStructure;
    constructor(props: BudgetHandlerCalcTotalBruttoNettoProps) {
        super(props);
        this.codes = {
            liczba: '',
            cena: '',
            vat: '',
            kurs: ''
        };
    }

    execute(data: BudgetHandlerStateGlobalFnExecuteProps) {
        if (data.recalcOnChange) {
            this.setCodesAndStructure(data);
            this.calc(data);
        }
    }

    private setCodesAndStructure(data: BudgetHandlerStateGlobalFnExecuteProps) {
        this.codes = budgetStableCodesForTotalCalc[data.settleObjectCode];
    }

    private calc(data: BudgetHandlerStateGlobalFnExecuteProps) {
        const fieldTotal = this.getFieldFromBugdetObjectStructure({
            settleObjectCode: data.settleObjectCode,
            column: 'cena netto {waluta}'
        });
        const fieldTotalBrutto = this.getFieldFromBugdetObjectStructure({
            settleObjectCode: data.settleObjectCode,
            column: 'cena brutto {waluta}'
        });
        if (fieldTotal && fieldTotalBrutto) {
            const numberUnit = this.getValueNumberFromStatePrimary({
                ...data,
                settleObjectCode: data.settleObjectCode,
                code: this.codes.liczba
            })
                ? this.getValueNumberFromStatePrimary({
                      ...data,
                      settleObjectCode: data.settleObjectCode,
                      code: this.codes.liczba
                  })
                : 1;
            const price = this.getValueNumberFromStatePrimary({
                ...data,
                settleObjectCode: data.settleObjectCode,
                code: this.codes.cena
            });
            const rate = !this.codes.kurs
                ? 1
                : this.getValueNumberFromStatePrimary({
                      ...data,
                      settleObjectCode: data.settleObjectCode,
                      code: this.codes.kurs
                  });
            const vat = !this.codes.vat
                ? 1
                : getVat(
                      this.getValueNumberFromStatePrimary({
                          ...data,
                          settleObjectCode: data.settleObjectCode,
                          code: this.codes.vat
                      })
                  );
            const valueTotal = numberUnit * price * rate;
            const valueTotalBrutto = valueTotal * vat;
            this.updateOSBothTempStateField({
                ...data,
                name: fieldTotal.name,
                newState: {
                    liczba: String(valueTotal)
                },
                updateReset: true
            });

            this.updateOSBothTempStateField({
                ...data,
                name: fieldTotalBrutto.name,
                newState: {
                    liczba: String(valueTotalBrutto)
                },
                updateReset: true
            });
        }
    }
}
